let Settings = {
    presentation: {
        patientsHideData: []
    }
}

const toString = (obj) => {
    return JSON.stringify(obj);
}

const toOBJ = (str) => {
    return JSON.parse(str);
}

export function setSettings (settings) {
    localStorage.setItem('Settings', toString(settings))
}

export function getSettings () {
    let settings = localStorage.getItem('Settings');
    if (!settings) {
        setSettings(Settings);
        return Settings
    } else {
        return toOBJ(settings);
    }
}

export function setPatientsHideDataSettings(id) {
    let settings = getSettings();
    let patientsHideData = settings.presentation.patientsHideData;
    const index = patientsHideData.indexOf(id);
    if (index === -1) {
        patientsHideData.push(id);
    }
    setSettings({
        ...settings,
        presentation: {
            patientsHideData: patientsHideData
        }
    })
}

export function onChangePatientsHideDataSettings(id) {
    let settings = getSettings();
    let patientsHideData = settings.presentation.patientsHideData;
    const index = patientsHideData.indexOf(id);
    if (index !== -1) {
        patientsHideData.splice(index, 1);
    } else {
        patientsHideData.push(id);
    }
    setSettings({
        ...settings,
        presentation: {
            patientsHideData: patientsHideData
        }
    })
}

export function resetPatientsHideDataSettings() {
    let settings = getSettings();
    setSettings({
        ...settings,
        presentation: {
            patientsHideData: []
        }
    })
}

