import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import styled from "styled-components";
import Button from "GUI_MAIN/Elements/Button";
import { StyledCheckbox } from "GUI_MAIN/Elements/StyledCheckbox";
import StyledReactSelect from "GUI_MAIN/Elements/StyledReactSelect";
import { BirthDateInput } from "GUI_MAIN/Elements/ResourceControls/BirthDateInput";
import { Translator } from "eo-locale";
import { locales } from "Locale";
import { HeaderStore } from "MODEL_STORE/DataSource/Stores/HeaderStore";


const NotificationFiltersWrapper = styled.div`
    position: fixed;
    top: 52px;
    right: 530px;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.whiteColor};
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const Row = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: ${props => props.marginBottom || "0"};
`;

@observer
export class NotificationFilters extends React.PureComponent {

    /**
     * необходимые свойства
     */
    static propTypes = {
        actions: PropTypes.object.isRequired,
        filters: PropTypes.object.isRequired,
    }

    /**
     * отрисовка компонента
     */
    render() {
        const {
            actions,
            filters
        } = this.props;
        const translator = new Translator(HeaderStore.locale, locales);
        return (
            <NotificationFiltersWrapper>
                <Row marginBottom={"14px"}>
                    <StyledReactSelect
                        filterByLabelOnly
                        mainWidth={"100%"}
                        value={filters.typeValue}
                        onChange={filters.setType}
                        placeholder={translator.translate('SS904062')}
                        options={filters.typeOptions}
                        mainHeight="smallHeightSelect"
                    />
                </Row>
                <Row marginBottom={"20px"}>
                    <BirthDateInput
                        inputHeight="32px"
                        date={filters.date}
                        placeholder={translator.translate('SS904063')}
                        minDate={filters.minDate}
                        maxDate={filters.maxDate}
                        onChange={filters.setDate}
                    />
                </Row>
                <Row marginBottom={"24px"}>
                    <StyledCheckbox
                        id={"unread"}
                        label={translator.translate('SS904064')}
                        value={filters.unread}
                        onChange={filters.toggleUnread}
                    />
                </Row>
                <Row>
                    <Button
                        minWidthAuto
                        width={"99px"}
                        type={"outlined"}
                        label={translator.translate('SS904065')}
                        padding={"9px 15px"}
                        margin={"0 19px 0 0"}
                        onClick={actions.resetFilters}
                    />
                    <Button
                        minWidthAuto
                        width={"112px"}
                        label={translator.translate('SS904066')}
                        padding={"10px 15px"}
                        onClick={actions.applyFilters}
                    />
                </Row>
            </NotificationFiltersWrapper>
        )
    }
}
