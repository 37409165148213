import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class ZoomIcon extends PureComponent{
    static propTypes = {
        color: PropTypes.string,
    }
    static defaultProps = {
        color: "#FFFFFF"
    }
    render(){
        const {color} = this.props
        return(
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    d="M10.8308 16.3402C7.79745 16.3402 5.33874 13.8807 5.33874 10.8422C5.33874 7.80378 7.79745 5.34424 10.8308 5.34424C13.8642 5.34424 16.3197 7.80705 16.3197 10.8422C16.3197 13.8774 13.861 16.3402 10.8308 16.3402ZM19.8037 18.8913L16.1042 15.1857C17.0739 14.005 17.6584 12.4906 17.6584 10.8422C17.6584 7.06461 14.6022 4 10.8276 4C7.05298 4 4 7.06461 4 10.8422C4 14.6199 7.05625 17.6845 10.8308 17.6845C12.4765 17.6845 13.985 17.1023 15.1638 16.1309L18.8633 19.8366C19.0331 20.0099 19.5523 20.0949 19.8069 19.8366C20.0649 19.5749 20.0649 19.153 19.8037 18.8913Z"
                    fill={color}
                />
            </svg>
        )
    }
}