import React from "react";
import PropTypes from "prop-types";
import styledMap from "styled-map";
import { observer } from "mobx-react";
import styled from "styled-components";

/**
 * 
 */
const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

/**
 * 
 */
const Row = styled.div`
    display: flex;
    margin-bottom: ${props => props.marginBottom || '0'};
`;

/**
 * 
 */
const Label = styled.div`
    font-size: ${styledMap`
        big: ${props => props.theme.veryBigFontSize};
        default: ${props => props.theme.mediumFontSize};
    `};
    font-family: ${styledMap`
        semibold: ${props => props.theme.OpenSansSemiBold};
        default: ${props => props.theme.OpenSansRegular};
    `};
    color: ${styledMap`
        dark: ${props => props.theme.darkMainTextColor};
        default: ${props => props.theme.lightenMainTextColor};
    `};
`;

/**
 * https://jira.mmdx.ru/browse/FCSPOK-321
 * информация о пациенте
*/
@observer
export class PatientInformation extends React.PureComponent {

    /**
     * необходимые свойства
     */
    static propTypes = {
        patient: PropTypes.object.isRequired
    }

    render() {
        return (
            <Column>
                <Row marginBottom={"5px"}>
                    <Label big semibold dark>
                        { this.props.patient.name.fullName }
                    </Label>
                </Row>
                <Row marginBottom={"24px"}>
                    <Label>
                        { this.props.patient.gender.name }
                    </Label>
                    <Label>
                        ,&nbsp;
                    </Label>
                    <Label>
                        { this.props.patient.birthday.age }
                    </Label>
                    <Label>
                        ,&nbsp;
                    </Label>
                    <Label>
                        { this.props.patient.birthday.display }
                    </Label>
                    <Label>
                        ,&nbsp;
                    </Label>
                    <Label>
                        ОМС { this.props.patient.omsPolicy.number }
                    </Label>
                </Row>
            </Column>
        );
    }
}
