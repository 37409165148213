import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import FullscreenOutlinedIcon from '@material-ui/icons/FullscreenOutlined';

import { TiffViewer } from './TiffViewer';
import { ImageViewer } from './ImageViewer';
import { DicomViewer } from './DicomViewer';
import { PdfViewer } from './PdfViewer';
import { DocViewer } from './DocViewer';
import { XlsViewer } from './XlsViewer';
import { ZipViewer } from './ZipViewer';
import { RarViewer } from './RarViewer';
import { EmptyPane } from './EmptyPane';
import { FileNotFoundPane } from './FileNotFoundPane';

import { BorderButtons } from './components';
import { BorderButton } from './components';
import {BadFilePane} from "./BadFilePane"
import Preloader from "GUI_MAIN/Elements/Preloader";

import { useTranslator } from 'eo-locale';
import { HeaderStore } from 'MODEL_STORE/DataSource/Stores/HeaderStore';
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

const Element = styled.div`
    overflow: ${ props => props.withScrollbar ? 'auto' : 'hidden' };
    position: ${ props => props.toTOP ? 'initial' : 'relative' };
    position: ${ props => props.fullScreen ? 'fixed' : 'relative' };

    left: 0;
    top: ${ props => props.fullScreen ? '65px' : '0' };
    right: 0;
    bottom: 0;
    z-index: 1;
    flex: 100% 1 1;
    margin: 0 8px;
    height: ${props => props.isFullScreen ? 'calc(100% - 65px)' : '100%'};
    display: flex;
    align-items: center;
    justify-content: center;
    ${props => !props.fullScreen && `border: 1px #777 solid;`};
    background-color: ${props => props.fullScreen ? `rgba(0, 0, 0, 0.87)` : `rgba(0, 0, 0, 0.4)`};

    > img {
        max-width: 100%;
        max-height: 100%;
    }
`;

const NO_FULLSCREEN_CONTENTTYPES = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

function Viewer(props) {

    const [panelButtons, setPanelButtons] = useState([]);
    const [isFullScreen, setFullScreen] = useState(false);
    const [viewer, setViewer] = useState(<Preloader preloaderColor={"#ffffff"}/>);
    let isLoading = false;

    const translator = useTranslator(HeaderStore.locale)

    useEffect(
        () => setPanelButtons([]),
        [ props.file.contentType ]
    );

    useEffect(() => {
    let isCancelled = false;
    isLoading = true;
    renderFileContent(props.file).then((element) => {
        if (!isCancelled) {
          setViewer(element);
        }
    });

    return () => {
      // isLoading = false;
      isCancelled = true;
    };
  }, []);




    /**
     *
     */
    const renderFileContent = (fileData) => {
        if ( ! fileData ) {
            return <EmptyPane minimize={props.minimize} />;
        }

        const {
            url,
            contentType,
            items
        } = fileData;

        if ( contentType === 'application/fhir+json' ) {
            // вообще тут тоже неплохо бю проверять наличие файла,
            // так понимаю, fetch(url) чуть ниже нужен именно для этого
            return new Promise((r) => r(<DicomViewer url={url} />));
        }

        return fetch(url).then(() => {

            if ( contentType === 'application/pdf') {
                return <PdfViewer isFullScreen={isFullScreen} url={url + `#toolbar=${props.showPDFtools ? '1': '0'}`} />;
            }

            if (
                contentType === 'application/msword'
                ||
                contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ) {
                return <DocViewer url={url} />;
            }

            if (
                contentType === 'application/vnd.ms-excel'
                ||
                contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ) {
                return <XlsViewer url={url} />;
            }

            if ( contentType === 'image/tiff' ) {
                return <TiffViewer url={url}/>
            }

            if ( contentType === 'application/x-zip-compressed' ) {
                return <ZipViewer url={url}/>
            }

            if ( contentType === 'application/zip' ) {
                return <RarViewer url={url}/>
            }

            if (url) {
                return <ImageViewer url={url}/>;
            }
            return <BadFilePane />;

        }).catch(() => {
            return <FileNotFoundPane close={props.close}/>;
        })

    }

    const isPDF = props.file.contentType == 'application/pdf';

    const showIconTrash = typeof props.iconTrash == 'boolean' ? props.iconTrash : true;

    // иконка полноэкранного просмотра:
    // по умолчанию отсутствует
    // если просят - есть
    // но если у документа нет просмотра (doc|xls) - выключается
    var showIconFullScreen = false;
    if (typeof props.iconFullScreen == 'boolean' && props.iconFullScreen) {
        showIconFullScreen = true;
    }
    if (NO_FULLSCREEN_CONTENTTYPES.includes(props.file.contentType)) {
        showIconFullScreen = false;
    }

    var showScrollbars = false;
    // if (props.file.contentType == 'application/pdf') {
    //     showScrollbars = true;
    // }
    let fileData = props.file
    const {
        url,
        contentType,
        items
    } = fileData;

    return (
        <Element withScrollbar={showScrollbars} fullScreen={isFullScreen} toTOP={props.length === 1}>
            <BorderButtons withScrollbar={showScrollbars} toTOP={props.length === 1} leftSide={props.length > 1}>
                {
                    showIconFullScreen && !isFullScreen ? null : panelButtons
                }

                {
                    props.changeOrderLeft
                    &&
                    <BorderButton key={props.elementKey} onClick={props.changeOrderLeft}>
                        <ArrowBackIcon />
                    </BorderButton>
                }

                {
                    props.changeOrderRight
                    &&
                    <BorderButton onClick={props.changeOrderRight}>
                        <ArrowForwardIcon />
                    </BorderButton>
                }

                {
                    showIconFullScreen
                    &&
                    <BorderButton
                        onClick={ e => setFullScreen(!isFullScreen)}
                        title={isFullScreen ? translator.translate('SS9037020') : translator.translate('SS9036710')} fullScreen={isFullScreen}
                        isToggleButton
                    >
                        {
                            isFullScreen
                                ? <CloseOutlinedIcon />
                                : <FullscreenOutlinedIcon />
                        }

                    </BorderButton>
                }

                {
                    showIconTrash || props.canRemove
                    &&
                    <BorderButton
                        onClick={ e => props.deleteElement(props.index) }
                        title={translator.translate('SS9039660')}
                    >
                        <DeleteOutlinedIcon />
                    </BorderButton>
                }
            </BorderButtons>
            {
              viewer
            }
        </Element>

    );
}

Viewer.propTypes = {
    iconTrash: PropTypes.bool,
}

export { Viewer }
