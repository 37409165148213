import { observable } from "mobx";
import {PagePaginationStore} from "MODEL_STORE/DataSource/Stores/PagePaginationStore";

const DEFAULT_PAGE = 1;
const DEFAULT_PAGES = 1;
const DEFAULT_COUNT = 15;

export class PaginationFilter {

    /**
     * номер текущей страницы
     */
    @observable page = DEFAULT_PAGE;

    /**
     * количество страниц
     */
    @observable pages = DEFAULT_PAGES;

    /**
     * количество загружаемых ресурсов
     */
    @observable count = DEFAULT_COUNT;

    // Код раздела сайта, для которого применяется пагинация
    pagePaginationStore;

    constructor(LOCAL_STORE_FILTER_KEY) {
        this.pagePaginationStore = new PagePaginationStore(LOCAL_STORE_FILTER_KEY);
        this.count = this.pagePaginationStore.getPageCount();
    }

    /**
     * сброс фильтра
     */
    reset = () => {
        this.page = DEFAULT_PAGE;
        this.pages = DEFAULT_PAGES;
        this.count = DEFAULT_COUNT;
    }

    /**
     * установка текущей страницы
     */
    setPage = (page) => {
        this.page = page >= DEFAULT_PAGE && page <= this.pages ? page : DEFAULT_PAGE;
    }

    /**
     * установка кол-ва страниц
     */
    setTotal = (total) => {
        this.pages = (this.count > 0 && total > 0) ? Math.ceil(total / this.count) : DEFAULT_PAGES;
    }

    /**
     * установка количества загружаемых ресурсов
     */
    setCount = (count) => {
        this.count = count;
        this.pagePaginationStore.setPageCount(this.count);
    }

}
