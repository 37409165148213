//кастомные стили для тултипов
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

export const overPos = (
    { left, top },
    currentEvent, currentTarget, node) => {
    if(node.classList && !node.classList.contains('place-left')) {
        const d = document.documentElement;
        if(d.clientWidth - currentTarget.getBoundingClientRect().right < node.clientWidth / 2) {
            left = currentTarget.getBoundingClientRect().right - node.clientWidth
        } else {
            left = Math.min(d.clientWidth - node.clientWidth, left);
        }
        if(d.clientHeight - currentTarget.getBoundingClientRect().bottom < node.clientHeight) {
            top = currentTarget.getBoundingClientRect().top - node.clientHeight - 10
        } else {
            top = Math.min(d.clientHeight - node.clientHeight, top);
        }
        left = Math.max(0, left);
        top = Math.max(0, top);
        return { top, left }
    } else {
        return {top, left}
    }
}

export const overPosFloat = (
  { left, top },
  currentEvent, currentTarget, node) => {
    const d = document.documentElement,
      targetClientRect = currentTarget.getBoundingClientRect();
    if(d.clientWidth - currentEvent.x < node.clientWidth/2) {
        left = d.clientWidth - node.clientWidth - 20
    } else {
        left = Math.min(d.clientWidth - node.clientWidth, left);
    }
    if(d.clientHeight - targetClientRect.bottom < node.clientHeight) {
        top = targetClientRect.top - node.clientHeight - 10
    } else {
        top = Math.min(d.clientHeight - node.clientHeight, top);
    }
    left = Math.max(0, left);
    top = Math.max(0, top);
    return { top, left }
}

export const StyledReactTooltip = styled(ReactTooltip)`
  &.light{ //тултип со светлым фоном
    opacity: 1 !important;
    background-color: ${props => props.theme.tooltipBG} !important;
    max-width: 240px;
    font-family: ${(props) => props.theme.OpenSansRegular};
    color: ${props => props.theme.whiteColor} !important;
    font-size: ${props => props.theme.normalFontSize};
    padding: 6px 10px;
    white-space: normal;
    word-break: break-word;

    &:after, &:before{
      opacity: 0;
    }
    
    p{
      margin: 0;
    }
  }

  &.shadow{
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1) !important;
  }
  &.shadow-tooltip{
    box-shadow: 0px 3px 8px -2px rgba(0,0,0,0.05) !important;
  }

  &.light-simple{ 
    color: ${props => props.theme.whiteColor} !important;
  }

  &.light-blue{
    background-color: #F2FAFD !important;
  }

  &.more-diagnosis-tooltip {
    font-size: ${props => props.theme.normalFontSize} !important;
  }

  &.full-diagnosis{
    max-width: 500px;
    font-size: ${props => props.theme.normalFontSize} !important;
  }

  &.max-width-400{
    max-width: 400px;
  }
  &.tooltip-menu{
    z-index: 990009;
  }

  &.width_care-plan{
    width: 290px;
   // max-height: 210px;
  }

  &.width_no-place{
    width: 280px;
  }

  &.all-diagnosis-fixed-width{
    width: 420px;
    pointer-events: auto !important;

    &:hover {
     visibility: visible !important;
     opacity: 1 !important;
    }
  }
  
  &.day-tooltip{
    pointer-events: auto !important;
    background-color: ${props => props.theme.whiteColor} !important;
    color: ${props => props.theme.darkMainTextColor} !important;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
`;
