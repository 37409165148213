import React, {PureComponent} from 'react';
import PropTypes from "prop-types";

class SpinnerIcon extends PureComponent {

    static propTypes={
        color: PropTypes.string,
    }

    static defaultProps={
        color: '#ffffff',
    }

    render() {
        const {color} = this.props;
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M8.13502 13C10.8964 13 13.135 10.7614 13.135 8C13.135 5.23858 10.8964 3 8.13502 3C6.4992 3 5.04686 3.78555 4.13465 5L2 2.86535C3.46751 1.1138 5.67119 0 8.13502 0C12.5533 0 16.135 3.58172 16.135 8C16.135 12.4183 12.5533 16 8.13502 16C5.67119 16 3.46751 14.8862 2 13.1346L4.13465 11C5.04686 12.2144 6.4992 13 8.13502 13Z" fill={color}/>
            </svg>
        );
    }
}

SpinnerIcon.propTypes = {};

export default SpinnerIcon;