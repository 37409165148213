import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class RarIcon extends PureComponent{

    static propTypes = {
        stroke: PropTypes.string,
    }

    static defaultProps = {
        stroke: '#F0F1F3',
    }

    render(){
        const {stroke} = this.props;
        return(
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M3 2.05C3 1.4701 3.44772 1 4 1H15C15.2652 1 15.5196 1.11062 15.7071 1.30754L20.7071 6.55754C20.8946 6.75445 21 7.02152 21 7.3V20.95C21 21.5299 20.5523 22 20 22H4C3.44772 22 3 21.5299 3 20.95V2.05ZM5 3.1V19.9H19V8.35H15C14.4477 8.35 14 7.8799 14 7.3V3.1H5ZM16 4.58492L17.5858 6.25H16V4.58492Z" fill="#121232"/>
          <path d="M6.36621 12.7344H8.04492C8.37305 12.7344 8.6582 12.7832 8.90039 12.8809C9.14258 12.9785 9.3291 13.123 9.45996 13.3145C9.59277 13.5059 9.65918 13.7422 9.65918 14.0234C9.65918 14.2676 9.62109 14.4717 9.54492 14.6357C9.46875 14.7998 9.3623 14.9355 9.22559 15.043C9.09082 15.1484 8.93359 15.2354 8.75391 15.3037L8.41699 15.4941H7.00781L7.00195 14.7002H8.04492C8.17578 14.7002 8.28418 14.6768 8.37012 14.6299C8.45605 14.583 8.52051 14.5166 8.56348 14.4307C8.6084 14.3428 8.63086 14.2383 8.63086 14.1172C8.63086 13.9941 8.6084 13.8887 8.56348 13.8008C8.51855 13.7129 8.45215 13.6455 8.36426 13.5986C8.27832 13.5518 8.17188 13.5283 8.04492 13.5283H7.39453V17H6.36621V12.7344ZM8.70117 17L7.75781 15.1133L8.84766 15.1074L9.80273 16.9561V17H8.70117ZM12.0879 13.6309L11.0479 17H9.94336L11.5107 12.7344H12.2109L12.0879 13.6309ZM12.9492 17L11.9062 13.6309L11.7715 12.7344H12.4805L14.0566 17H12.9492ZM12.9082 15.4092V16.2031H10.7197V15.4092H12.9082ZM14.3701 12.7344H16.0488C16.377 12.7344 16.6621 12.7832 16.9043 12.8809C17.1465 12.9785 17.333 13.123 17.4639 13.3145C17.5967 13.5059 17.6631 13.7422 17.6631 14.0234C17.6631 14.2676 17.625 14.4717 17.5488 14.6357C17.4727 14.7998 17.3662 14.9355 17.2295 15.043C17.0947 15.1484 16.9375 15.2354 16.7578 15.3037L16.4209 15.4941H15.0117L15.0059 14.7002H16.0488C16.1797 14.7002 16.2881 14.6768 16.374 14.6299C16.46 14.583 16.5244 14.5166 16.5674 14.4307C16.6123 14.3428 16.6348 14.2383 16.6348 14.1172C16.6348 13.9941 16.6123 13.8887 16.5674 13.8008C16.5225 13.7129 16.4561 13.6455 16.3682 13.5986C16.2822 13.5518 16.1758 13.5283 16.0488 13.5283H15.3984V17H14.3701V12.7344ZM16.7051 17L15.7617 15.1133L16.8516 15.1074L17.8066 16.9561V17H16.7051Z" fill="#E325E7"/>
          </svg>
        )
    }
}
