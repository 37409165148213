import React from 'react';
import styled from 'styled-components';



const Message = styled.div`
    display: block;
    color: white;
    text-align: center;
`;
const MessageText = styled.div`
    margin-bottom: 32px;
`;



import { StdButton } from './components';

export function EmptyPane(props) {


    return (
        <Message>
            <MessageText>
                <span>
                    Файл не выбран. Сверните область просмотра и выберите еще один документ
                </span>
            </MessageText>
            <StdButton onClick={props.minimize}>
                Свернуть!
            </StdButton>
        </Message>
    );


}