import React from 'react';
import styled from 'styled-components';

/**
 * панель с кнопками
 */
const BorderButtonsContainer = styled.div`
    position: absolute;
    right: ${ props => props.withScrollbar ? props.leftSide ? 'unset' : '16px' : 0 };
    left: ${ props => props.toTOP ? 0 : props.leftSide ? 0 : 'initial' };
    min-height: 48px;
    width: ${ props => props.leftSide ? "48px" : "100%"};
    display: flex;
    z-index: ${ props => props.toTOP ? 10: 9};
    //justify-content: ${ props => props.center || props.toTOP ? 'center' : 'flex-end' };
    justify-content: center;
    background-color: #1B75BB;
    top: ${ props => props.toTOP ? "-1px" : "0"};
    flex-direction: ${props => props.leftSide ? "column" : "row"};
}
`;

/**
 * иногда нужно, чтобы клики на панели не работали
 */
export const BorderButtons = (props) => {
    return (
        <BorderButtonsContainer {...props} onClick={ e => e.stopPropagation() }>
            { props.children }
        </BorderButtonsContainer>
    );
}

export const BorderControl = styled.div`
    height: 48px;
    transition: background-color 0.3s;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${ props => props.active ? "#25282B" : "transparent"};
    vertical-align: top;
    font-size: 28px;

    &:hover {
        background-color: rgba(220, 220, 220, ${ props => props.active ? '0.48' : '0.32'});
    }

    &[disabled] svg {
        fill: rgba(255, 255, 255, 0.54);
    }

    &:nth-child(n+2) {
   //     margin-left: 4px;
    }
`;

export const BorderButton = styled(BorderControl)`
    flex: 48px 0 0;
    width: 48px;
    cursor: pointer;
    position: relative;
    ${ props => props.isToggleButton && "position: absolute; right: 0;"};
`;

export const BorderButtonSpacer = styled.div`
    flex: 100% 10 10;
`;


export const StdButton = styled.div`
    display: inline-block;
    padding: 4px 16px;
    background-color: #555;
    color: #ddd;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    user-select: none;
    transition: background-color 0.3s;

    &:hover {
        background-color: #777
    }

    &:active {
        background-color: #999
    }
`;

