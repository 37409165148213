// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMW0dkNMMMMMMMMMMMMMMMMXxdKWMMMMMM
// MMMMMMMWO'  ;kNMMMMMMMMMMMMXd'  ;KMMMMMM
// MMMMMMMMNk;   ;kNMMMMMMMMXd'  .cOWMMMMMM
// MMMMMMMMMMNk,  .;kNMMMMXd'  .:OWMMMMMMMM
// MMMMMMMMMMMMNx,  .;kXKx,  .:OWMMMMMMMMMM
// MMMMMMMMMMMMMMNx,   ..  .:OWMMMMMMMMMMMM
// MMMMMMMMMMMMMMMM0;      cXMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMNx,  ...  .:OWMMMMMMMMMMMM
// MMMMMMMMMMMMNx,  .;kXKx,  .:OWMMMMMMMMMM
// MMMMMMMMMMNk,  .;kNMMMMXd'  .:OWMMMMMMMM
// MMMMMMMMNk;   ;kNMMMMMMMMXd'  .c0WMMMMMM
// MMMMMMMWO'  ;kNMMMMMMMMMMMMXd'  ;KMMMMMM
// MMMMMMMMW0dkNMMMMMMMMMMMMMMMMXxdKWMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM


import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class CloseCross extends PureComponent{

    static propTypes = {
        fill: PropTypes.string,
    }

    static defaultProps = {
        fill: '#ffffff',
    }

    render(){
        const {fill} = this.props

        return(
            <svg width="14" height="14" viewBox="0 0 14 14" fill={fill}>
                <path d="M14 1.50091L12.59 0.0909119L7 5.68091L1.41 0.0909119L0 1.50091L5.59 7.09091L0 12.6809L1.41 14.0909L7 8.50091L12.59 14.0909L14 12.6809L8.41 7.09091L14 1.50091Z"/>
            </svg>
        )
    }
}

