import { observable, computed, autorun, action } from 'mobx';

class ContextualMenuStoreClass {

    /**
     *
     */
    @observable
    componentNumber = 0
    @observable
    isOpen = false
    @observable
    coords = [0, 0]
    @observable
    contentComponent = null
    @observable
    target = null
    @observable
    side = 'left' //с какой стороны от иконки открывать меню (top, left, right, bottom)
    @observable
    limit = 0
    @observable
    closeByClickOutSide = true
    @observable
    pointerBackground = false
    @observable
    offset = [0, 0] // [x, y] считается в зависимости от положения
    @observable
    iconMenuEvent = false //для корректной работы меню в схлопнутом состоянии отодвигает
    onClickOutside


    @action
    showContextualMenu(isOpen, event, contentComponent, side, limit, closeByClickOutSide, iconMenuEvent, pointerBackground, onClickOutside, offset){
        this.componentNumber = this.componentNumber + 1
        this.isOpen = isOpen
        this.contentComponent = contentComponent
        this.coords = [event.clientX, event.clientY]
        this.target = event.currentTarget
        this.side = side
        this.limit = limit
        this.closeByClickOutSide = closeByClickOutSide
        this.iconMenuEvent = iconMenuEvent
        this.pointerBackground = pointerBackground
        this.onClickOutside = onClickOutside
        if (offset) {
          this.offset = offset
        }
    }

    @action
    hideContextualMenu(){
        this.isOpen = false
    }
}

const ContextualMenuStore = new ContextualMenuStoreClass();

export { ContextualMenuStore };
