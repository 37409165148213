import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const setTextColor = (type, theme) => {
    switch (type) {
        case 'error':
            return theme.mainRedColor;
        case 'warning':
            return theme.warningOrangeColor;
        default:
            return theme.nodataTextColor;
    }
};

const setTextPosition = (textPosition) => {
    if (textPosition === 'left') {
        return 'flex-start';
    }

    return 'center';
};

const setFontSize = (theme, fontSize) => {
    switch (fontSize) {
        case "small":
            return theme.normalFontSize;
        default:
            return theme.bigFontSize
    }
};

const NoDataMessageWrapper = styled.div`
  display: flex;
  justify-content: ${props => setTextPosition(props.textPosition)};
  align-items: ${props => props.alignItems ? props.alignItems : "center"};
  width: 100%;
  color: ${props => setTextColor(props.type, props.theme)};
  font-family: ${props => props.theme.OpenSansRegular};
  font-size: ${props => setFontSize(props.theme, props.fontSize)};
  padding: ${props => props.padding};
  margin-top: ${props => props.marginTop};
  ${props => props.bgColor && `background-color: ${props.bgColor}`};
  ${props => props.mainHeight && `height: ${props.mainHeight}`};
`;

class NoDataMessage extends PureComponent {
    static propTypes = {
        message: PropTypes.string.isRequired || PropTypes.object.isRequired,
        type: PropTypes.string,
        padding: PropTypes.string,
        textPosition: PropTypes.oneOf(['left', 'center']),
        customMarginTop: PropTypes.string,
        bgColor: PropTypes.string,
        fontSize: PropTypes.string,
    };

    static defaultProps = {
        message: 'Нет данных',
        type: 'default',
        padding: '24px',
        textPosition: 'center',
        customMarginTop: '0',
        fontSize: 'big',
    };

    render() {
        const {
            message,
            type,
            padding,
            textPosition,
            customMarginTop,
            bgColor,
            alignItems,
            mainHeight,
            fontSize
        } = this.props;

        return (
            <NoDataMessageWrapper
              type={type}
              padding={padding}
              textPosition={textPosition}
              marginTop={customMarginTop}
              bgColor={bgColor}
              alignItems={alignItems}
              mainHeight={mainHeight}
              fontSize={fontSize}
            >
                {message}
            </NoDataMessageWrapper>
        );
    }
}

NoDataMessage.propTypes = {};

export default NoDataMessage;
