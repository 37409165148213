import React from 'react';
import styled from 'styled-components';
import IconFileDocx from './IconFileDocx';
import {EOLocale} from "eo-locale";


const Container = styled.div`
    background-color: white;
    border: 0px white solid;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;
const DownloadBox = styled.a`
    font-family: ${props => props.theme.OpenSansRegular};
    font-size: 11px;
`
const DownloadLink = styled.div`
    margin-top: 12px;
`

export function DocViewer({ url }) {


    return (
        <Container>
            <DownloadBox href={url} target="_blank">
                <IconFileDocx />
                <DownloadLink>
                    <EOLocale.Text id={'SS9036720'}/>
                </DownloadLink>
            </DownloadBox>
        </Container>
    );


}
