import _get from "lodash/get";
import { observable } from "mobx";

const GENDERS = {
    male: "Мужской",
    female: "Женский",
    other: "Другое",
    unknown: "Неизвестно",
}

export class GenderFilter {

    /**
     * пол пациента (male, female, etc...)
     */
    @observable value = "";

    /**
     * сброс фильтра
     */
    reset = () => {
        this.value = '';
        this.setLocalStorage();
    }

    /**
     * выбранное значение пола пациента для React-Select
     */
    get option() {
        return this.options.find(option => option.value == this.value) || "";
    }

    /**
     * возможные значения пола пациента для React-Select
     */
    get options() {
        return Object.keys(GENDERS).map(key => ({
            value: key,
            label: GENDERS[key]
        }));
    }

    /**
     * установка значения пола пациента
     */
    setOption = (selected) => {
        this.value = _get(selected, "value", "");
        this.setLocalStorage();
    }

    initLocalStorage = (localStoreFieldsKey, paramKey) => {
        this.localStoreFieldsKey = localStoreFieldsKey;
        this.paramKey = paramKey;
    }

    setLocalStorage = () => {
        if (this.localStoreFieldsKey) {
            let filters = localStorage.getItem(this.localStoreFieldsKey);
            if (filters) {
                filters = JSON.parse(filters);
            } else {
                filters = {};
            }
            filters[this.paramKey] = this.value;
            localStorage.setItem(this.localStoreFieldsKey, JSON.stringify(filters));
        }
    }
}
