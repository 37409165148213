import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class MagnifyingGlass extends PureComponent {
    static propTypes = {
        fill: PropTypes.string,
    }
    static defaultProps = {
        fill: "#000000"
    }

    render() {
        const {fill} = this.props;

        return (
            <svg fill={fill} width="24" height="24" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
                <path d="M226.82129,221.17236,180.955,175.306a88.10138,88.10138,0,1,0-5.657,5.65649L221.165,226.82959a3.99992,3.99992,0,0,0,5.65625-5.65723ZM35.999,116a80,80,0,1,1,80,80A80.09041,80.09041,0,0,1,35.999,116Z"/>
            </svg>
        );
    }
}

MagnifyingGlass.propTypes = {};

export default MagnifyingGlass;
