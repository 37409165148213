import React from "react";
import moment from "moment";
import {locales} from "Locale";
import { observable } from "mobx";
import { EOLocale, Translator } from "eo-locale";
import { HeaderStore } from "MODEL_STORE/DataSource/Stores/HeaderStore";


const translator = new Translator(HeaderStore.locale, locales);
/**
 * типы нотификаций (для ReactSelect)
 */
export const NOTIFICATION_TYPES = [{
    value: "",
    label: translator.translate('SS9035818'),
}, {
    label: translator.translate('SS9035819'),
    type: "task",
    value: [
        "mm-treatment-prescribed", // Цикл лечения переведён в статус "Назначен"
        "mm-results-not-completed", // Оповещение о необходимости заполнить вкладку "Итоги лечения"
        "mm-status-not-completed", // Оповещение о необходимости заполнить вкладку "Статус лечения"
        "tb-request-rejected", // Заявка была переведена в статус "Отклонена"
        "tb-request-not-completed", // Оповещение о необходимости заполнить созданную заявку
        "tb-documents-added-by-patient", // Оповещение о добавленных пациентом документах
        "tb-patient-condition-worsened", // Внесение пациентом записи в дневник здоровья
        "tb-request-waiting-for-approval", // Заявка переведена в статус "На согласовании"
        "tb-record-not-completed", // Оповещение о необходимости заполнить протокол ОК
        "tb-record-published", // Протокол ОК был переведен в статус "Опубликован"
        "tb-request-commented", // В заявке был оставлен комментарий
    ].join(",")
}, {
    label: translator.translate('SS9035820'),
    type: "notification",
    value: [
        "mm-treatment-started", // Цикл лечения переведён в статус "В работе"
        "mm-treatment-not-started", // Цикл лечения не переведён в статус "В работе"
        "mm-medication-not-taken", // Указан статус введения препарата "Не введён"/"Не принят"
        "mm-treatment-paused", // Указан статус введения препарата "Приостановлен"
        "tb-member-invited", // Пользователь приглашен на консилиум как участник
        "tb-member-invited-vks", // Пользователь приглашен на консилиум как участник
        "tb-request-approved", // Заявка была переведена в статус "Согласована"
        "tb-event-reminder", // Оповещение о приближающемся консилиуме (за 1 день)
        "tb-event-cancelled", // Оповещение об отмененном консилиуме (удаление консилиума)
        "tb-event-rescheduled", // Оповещение о перенесенном консилиуме
        "tb-event-rescheduled-vks", // Оповещение о перенесенном консилиуме
        "tb-event-rescheduled-period", // Оповещение о перенесенном консилиуме
    ].join(",")
}];

/**
 * фильтры для нотификаций
 */
export class Filters {

    /**
     * тип нотификаций
     * (task/notification)
     */
    @observable type = "";

    /**
     * дата нотификаций
     */
    @observable date = "";

    /**
     * только непрочитанные?
     */
    @observable unread = false;

    /**
     * минимальная дата создания нотификации
     */
    get minDate() {
        return moment().subtract(14, "days").toDate();
    }

    /**
     * максимальная дата создания нотификации
     */
    get maxDate() {
        return moment().toDate();
    }

    /**
     * типы нотификаций (для ReactSelect)
     */
    get typeOptions() {
        return NOTIFICATION_TYPES;
    }

    /**
     * значение типа (для ReactSelect)
     */
    get typeValue() {
        return NOTIFICATION_TYPES.find(type => type.value == this.type) || "";
    }

    /**
     * установка типа
     */
    setType = (selectedOption) => {
        this.type = selectedOption.value;
    }

    /**
     * установка даты
     */
    setDate = (value) => {
        this.date = value;
    }

    /**
     * переключение флага 'только непрочитанные'
     */
    toggleUnread = () => {
        this.unread = ! this.unread;
    }

    /**
     * сброс фильтров
     */
    reset = () => {
        this.type = "";
        this.date = "";
        this.unread = false;
    }

}
