import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from 'react-router-dom';


class HistoryConnectorSaver extends React.Component {
    constructor(props) {
        props.historyConnector(props.history);
    }

    render() {
        return null;
    }
}


export class HistoryConnector extends React.Component {

    static propTypes = {
        historyConnector: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Switch>
                <Route
                    children={props => <HistoryConnectorSaver {...props} historyConnector={this.props.historyConnector} />}
                />
            </Switch>
        );
    }
}
