import React from "react";
import styled from "styled-components";
import EgzisLogoIcon from "../../icons/EgzisLogoIcon";

const MaintenancePage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #F9FCFF;
`;

const MaintenanceHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  box-shadow: 0 2px 5px 0 #F7F7FB;
  background-color: ${props => props.theme.whiteColor};
`;

const LogoWrapper = styled.div`
  width: 65px;
  height: 65px;
  margin-right: 15px;
`;

const LogoText = styled.div`
  font-family: ${props => props.theme.OpenSansBold};
  font-size: ${props => props.theme.eighteenFontSize};
  color: #7B7D8A;
`;

const Panel = styled.div`
  width: 100%;
  background-color: ${props => props.theme.whiteColor};
  border-top: 1px solid #F0F1F3;
`;

const PanelContent = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 1133px;
`;

const MaintenanceFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 92px;
  padding-top: 30px;
  line-height: 16px;
`;

const MaintenanceFooterCol = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${props => props.theme.OpenSansRegular};
  font-size: ${props => props.theme.normalFontSize};
  color: #333333;
`;

const Row = styled.div`
  a{
    padding: 0 4px;
    color: #1D63B6;
  }

  a:first-child{
    font-family: ${props => props.theme.OpenSansSemiBold};
  }
`;
/**
 *
 */
const ContentWrapper = styled.div`
  display: flex;
  flex: 1 1;
  justify-content: center;
  width: 100%;
`;

/**
 * страница с логотипом, гербом и подвалом с контактами техподдержки,
 * как вот в задаче https://jira.mmdx.ru/browse/FCSPOK-834
 */
export class OfficialWrapper extends React.Component {

    render() {
        return (
            <MaintenancePage>
                <MaintenanceHeader>
                    <PanelContent>
                        <LogoWrapper>
                            <EgzisLogoIcon/>
                        </LogoWrapper>
                        <LogoText>
                            ЕГИСЗ
                        </LogoText>
                    </PanelContent>
                </MaintenanceHeader>
                <ContentWrapper>
                    {this.props.children}
                </ContentWrapper>
                <Panel>
                    <PanelContent>
                        <MaintenanceFooter>
                            <MaintenanceFooterCol>
                                <Row>
                                    Служба поддержки 8-800-301-15-59 (по России)
                                </Row>
                                <Row>
                                    Обращение в тех.поддержку
                                    <a href={'mailto:egisz@stp-egisz.ru'}>egisz@stp-egisz.ru</a>
                                    |
                                    <a href={'https://support.egisz.rosminzdrav.ru/'} target="_blank">ЕГИСЗ.РФ</a>
                                </Row>
                            </MaintenanceFooterCol>
                            <MaintenanceFooterCol>
                                ©2016-2024 Министерство здравоохранения Российской Федерации
                            </MaintenanceFooterCol>
                        </MaintenanceFooter>
                    </PanelContent>
                </Panel>
            </MaintenancePage>
        );
    }
}
