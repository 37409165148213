import React from 'react';
import styled from 'styled-components';

const PdfIframe = styled.iframe`
    background-color: white;
    border: 0px white solid;
    position: absolute;
    left: 0;
    top: 48px;
    width: 100%;
    height: ${props => props.isFullScreen ? 'calc(100% - 113px)' : 'calc(100% - 50px)'};
`;

export function PdfViewer({ url, isFullScreen }) {
    return (
        <PdfIframe src={url} isFullScreen={isFullScreen}/>
    );


}
