import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import {controlStyles, controlStylesActive, controlStylesHover, labelControlStyles} from "../../../styles/mixins";
import RequiredFieldIcon from "../RequiredFieldIcon";
import SelectClear from "GUI_MAIN/icons/SelectClear";
import { Translator } from 'eo-locale';
import {locales} from "../../../Locale";
import {HeaderStore} from "MODEL_STORE/DataSource/Stores/HeaderStore";
import {inject, observer} from "mobx-react";
import {lang_ru} from "../../../Locale/languages/ru";
import Eye from "GUI_MAIN/icons/Eye";
import _isEmpty from "lodash/isEmpty";
import {tooltipChecker} from "../../Utils/tooltipChecker";
import ArrowFilled from "GUI_MAIN/icons/ArrowFilled";

const InputWrapper = styled.div`
    width: ${(props) => props.mainWidth || '100%'};
    margin: ${(props) => props.mainMargin || '0'};
    pointer-events: ${props => props.disabled && 'none'};
    ${ props => props.mainFlexShrink && `flex-shrink: ${ props.mainFlexShrink }` };

    input::-webkit-input-placeholder {
      padding-left: 4px;
    }

    input::placeholder{
      padding-left: 3px;
    }
`;

const InputLabel = styled.div`
    ${props => labelControlStyles(props, props.isError, props.isEmpty)};
    display: block;
`;

const InputInner = styled.div`
    position: relative;
`;

const setPadding = (paddingInput, isClearable, value, type) => {
    if(paddingInput) return paddingInput;
    if(isClearable && type === "number") return "0 52px 0 10px";
    if(type === "number") return "0 25px 0 10px";
    if(isClearable) {
        if(!value) return '0px 10px';

        return '0px 30px 0px 10px';
    }

    return '0px 10px';
};

const toCommaReplacer = val => {
    try {
        return val.toString().replace(/\./gi, ',');
    } catch (e) {
        return val;
    }
};

const StyledInput = styled.input`
    width: ${(props) => props.inputWidth || '100%'};
    height: 32px;
    ${props => controlStyles(props, {disabled: props.disabled, isError: props.isError})};
    padding: ${props => setPadding(props.paddingInput, props.isClearable, props.commaReplace ? toCommaReplacer(props.value) : props.value, props.type)};
    //font-size: ${(props) => props.fontSize || props.theme.mediumFontSize};
    outline: none;
    transition: ${(props) => props.theme.mainTransition};
    text-align: ${(props) => (props.centerText ? 'center' : 'left')};
    ${props => props.inputBorderRadius && `border-radius: ${props.inputBorderRadius};`};
    text-overflow: ellipsis;

    &:hover{
      ${props => controlStylesHover(props, props.disabled)};
    }

    &:focus, &:active {
      ${props => controlStylesActive(props, props.disabled)};
    }

    // убирает красную обводку в Mozilla при автозаполнениии
    &:invalid {
      box-shadow: ${(props) => props.disableInvalid ? 'none' : 'initial'};
    }
    &:-moz-submit-invalid {
      box-shadow: ${(props) => props.disableInvalid ? 'none' : 'initial'};
    }
    &:-moz-ui-invalid {
      box-shadow: ${(props) => props.disableInvalid ? 'none' : 'initial'};

    ::-webkit-input-placeholder {
      font-size: ${(props) => props.theme.mediumFontSize};
      ${(props) => (props.paddingInput ? props.paddingInput : '10px 12px')};
      color: ${props => props.theme.controlPlaceholderColor};
    }
    ::-moz-placeholder          {
      font-size: ${(props) => props.theme.mediumFontSize};
      ${(props) => (props.paddingInput ? props.paddingInput : '10px 12px')};
      color: ${props => props.theme.controlPlaceholderColor};
    } /* Firefox 19+ */
    :-moz-placeholder           {
      font-size: ${(props) => props.theme.mediumFontSize};
      ${(props) => (props.paddingInput ? props.paddingInput : '10px 12px')};
      color: ${props => props.theme.controlPlaceholderColor};
    } /* Firefox 18- */
    :-ms-input-placeholder      {
      font-size: ${(props) => props.theme.mediumFontSize};
      ${(props) => (props.paddingInput ? props.paddingInput : '10px 12px')};
      color: ${props => props.theme.controlPlaceholderColor};
    }

    ::-webkit-input-placeholder {
      font-size: ${(props) => props.theme.mediumFontSize};
      ${(props) => (props.paddingInput ? props.paddingInput : '10px 12px')};
      color: ${props => props.theme.controlPlaceholderColor};
    }
    ::-moz-placeholder          {
      font-size: ${(props) => props.theme.mediumFontSize};
      ${(props) => (props.paddingInput ? props.paddingInput : '10px 12px')};
      color: ${props => props.theme.controlPlaceholderColor};
    } /* Firefox 19+ */
    :-moz-placeholder           {
      font-size: ${(props) => props.theme.mediumFontSize};
      ${(props) => (props.paddingInput ? props.paddingInput : '10px 12px')};
      color: ${props => props.theme.controlPlaceholderColor};
    } /* Firefox 18- */
    :-ms-input-placeholder      {
      font-size: ${(props) => props.theme.mediumFontSize};
      ${(props) => (props.paddingInput ? props.paddingInput : '10px 12px')};
      color: ${props => props.theme.controlPlaceholderColor};
    }
`;

const IconWrapper = styled.div`
    height: 100%;
    top: 0;
    right: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg path {
        transition: fill 150ms;
    }

    &:hover {
        svg path {
            fill: hsl(0,0%,60%);
        }
    }
`;

const EyeIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 20px;
  height: 20px;

  &:hover {
    svg path {
      fill: ${props => props.theme.mainDarkBlueColor};
    }
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 8px;
  height: 100%;
  top: 0;
`;

const ChangeNumberWrapper = styled.div`
  width: 16px;
  height: 100%;
`;

const ChangeNumber = styled.div`
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${props => !props.disabled ? "pointer" : "auto"};
    ${props => props.type === "down" && "transform: rotate(180deg)"};
    ${props => props.disabled && "opacity: .5; pointer-events: none"};

    svg path {
        transition: fill 150ms;
    }

    &:hover {
        svg path {
            ${props => !props.disabled && "fill: hsl(0,0%,60%)"};
        }
    }
`;

/**
 * Пресеты с регулярками для ограничения вводимых значений
 * атрибут valueRegex у компонента Input
 */

export const valueRegexps = {
    positiveFloatDigits: new RegExp(/^\d*[.,]?\d*$/i),
};

@inject('HeaderStore')
@withTheme
@observer
class Input extends PureComponent {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number]
        ),
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
        maxLength: PropTypes.number,
        type: PropTypes.string,
        isError: PropTypes.any,
        touched: PropTypes.bool,
        error: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        flexBasis: PropTypes.string,
        width: PropTypes.string,
        onKeyPress: PropTypes.func,
        transparent: PropTypes.bool,
        mainWidth: PropTypes.string,
        mainMargin: PropTypes.string,
        inputWidth: PropTypes.string,
        mainHeight: PropTypes.string,
        theme: PropTypes.object,
        mask: PropTypes.string,
        id: PropTypes.string,
        autoComplete: PropTypes.string,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        onClickWrapper: PropTypes.func,
        paddingInput: PropTypes.string,
        inputPlaceholder: PropTypes.string,
        disabledBG: PropTypes.string,
        disabledBorder: PropTypes.bool,
        disableInvalid: PropTypes.bool,
        min: PropTypes.any,
        max: PropTypes.any,
        step: PropTypes.any,
        autoFocus: PropTypes.bool,
        initRef: PropTypes.func,
        valueRegex: PropTypes.shape({
            test: PropTypes.func.isRequired
        }),
        preventCopyToClipboard: PropTypes.bool,
        needShow: PropTypes.bool, //показать/скрыть пароль
        commaReplace: PropTypes.bool, //показать/скрыть пароль
        requiredField: PropTypes.bool,
        requiredFieldShowAlways: PropTypes.bool, //Костыльная переменная для управления поведением астерикса отедельно от disabled, требование согласно задаче MIRA-1418 Старостиной Марии
        numberInteger: PropTypes.bool, // Отфильтровывать все, кроме цифр,
    };

    static defaultProps = {
        placeholder: '',
        disabled: false,
        type: 'normal',
        isError: false,
        touched: false,
        error: '',
        value: '',
        label: '',
        name: '',
        onKeyPress: () => {},
        onKeyDown: () => {},
        transparent: false,
        inputWidth: '100%',
        mask: '',
        autoComplete: '',
        onBlur: () => {},
        onFocus: () => {},
        onChange: () => {},
        paddingInput: '',
        autoFocus: false,
        valueRegex: null,
        preventCopyToClipboard: false,
        needShow: false,
        commaReplace: false,
        requiredField: false,
        requiredFieldShowAlways: false,
        numberInteger: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            is_empty: !props.value,
            isFocused: false,
            showPassword: false,
        };

        this.oldValue = this.props.value;
        this.inputRef = React.createRef();

        // Запрет на копирование в буфер обмена текста
        if (this.props.preventCopyToClipboard) {
            this.handlerCopy = this.handlerCopy.bind(this);
        }
    }


    /**
     * Запрет на копирование в буфер обмена текста
     * @param e
     */
    handlerCopy(e) {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.value && nextProps.value) {
            this.setState({
                is_empty: false
            });
        } else if (this.props.value && !nextProps.value && !this.state.isFocused) {
            this.setState({
                is_empty: true
            });
        }
    }

    onFocus = (e) => {
        const {onFocus} = this.props;
        this.setState({
            is_empty: false,
            isFocused: true
        });
        onFocus ? onFocus(e) : null;
    };

    onBlur = (e) => {
        const { value, onBlur } = this.props;
        if (!value || e.target.value === '') {
            this.setState({ is_empty: true });
        } else {
            this.setState({ is_empty: false });
        }
        this.setState({
            isFocused: false
        });
        onBlur ? onBlur(e) : null;
    };

    onChange = (e) => {
        const { onChange, valueRegex, maxLength} = this.props;
        let newValue = e.target.value;

        if (valueRegex && !valueRegex.test(newValue)) {
            return onChange(this.oldValue);
        }

        if (maxLength && newValue.length > maxLength) {
            newValue = newValue.substring(0, maxLength);
        }

        if(newValue[0] == 0){
            this.inputRef.value = "0";
        }

        // All Ok
        this.oldValue = newValue;
        onChange ? onChange(newValue) : null;
    };

    onClear = (e) => {
        const { onChange, onBlur} = this.props;
        this.inputRef.value = "";
        this.oldValue = "";
        onChange ? onChange("") : null;
        onBlur ? onBlur("") : null;
    };

    togglePassword = () => {
        const input = this.inputRef;
        input.style.animation = 'all 0.3s ease';
        this.setState({ showPassword: !this.state.showPassword });
        if (input.type === "password") {
            input.type = "text";
        } else {
            input.type = "password";
        }
    };

    componentDidMount() {
        this.setTooltip();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setTooltip();
    }

    setTooltip = () => {
        if (this.props.initRef){
            this.props.initRef(this.inputRef);
        }

        if (this.props.disabled) {
            tooltipChecker("text", [this.inputRef])
        }
    }

    onKeyPress = e => {
        const {numberInteger, onKeyPress} = this.props;
        if (numberInteger && e) {
            if (!String.fromCharCode(e.which).match(/[0-9]/)) {
                return e.preventDefault();
            }
        }
        onKeyPress(e);
    };

    changeNumber = (type) => {
        const target = this.inputRef,
          valueNum = parseInt(target.value),
          {max, min, onChange, onBlur} = this.props;

        if(type === "up") {
            if(!target.value) {
                target.value = 1;
            } else {
                if(target.value !== max) target.value = valueNum + 1;
            }

        } else {
            if(!target.value && min !== 0) {
                target.value = valueNum - 1
            } else {
                if(target.value !== min) target.value = valueNum - 1
            }

        }

        this.oldValue = target.value;
        onChange ? onChange(target.value) : null;
        onBlur ? onBlur() : null;
    }

    render() {
        const {
            disabled,
            isError,
            label,
            flexBasis,
            transparent,
            mainWidth,
            mainMargin,
            theme,
            paddingInput,
            inputHeight,
            type,
            maxLength,
            placeholder,
            inputBorderRadius,
            requiredField,
            requiredFieldShowAlways,
            isClearable,
            value,
            HeaderStore,
            needShow,
            id,
            onClickWrapper,
            mainFlexShrink,
        } = this.props;
        let {max, min} = this.props;

        const {isFocused} = this.state;

        max = (max && ['number'].indexOf(type) !== -1 ) ? max : null;
        const translator = new Translator(HeaderStore.locale, locales);

        const codePlaceholder = this.props.inputPlaceholder || placeholder;
        const placeholderText = codePlaceholder && lang_ru.messages[codePlaceholder] ?
            translator.getMessageById(codePlaceholder) : codePlaceholder;

        return (
            <InputWrapper mainWidth={mainWidth}
                          mainMargin={mainMargin}
                          onClick={onClickWrapper}
                          mainFlexShrink={mainFlexShrink}
                          id={id || ""}>
                {label ? <InputLabel label={label} isError={isError} isEmpty={_isEmpty( value ) || !value.trim().length}>
                    {label}
                    {
                        ((requiredField && !disabled) || requiredFieldShowAlways) &&
                        <RequiredFieldIcon/>
                    }
                </InputLabel> : null}
                <InputInner
                    isError={isError}
                    flexBasis={flexBasis}
                    disabled={disabled}
                    transparent={transparent}
                    theme={theme}
                    paddingInput={paddingInput}
                    isFocused={isFocused}
                >
                    <StyledInput
                        ref={(ref) => this.inputRef = ref}
                        {...this.props}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        onKeyPress={this.onKeyPress}
                        placeholder={placeholderText}
                        max={max}
                        maxLength={maxLength}
                        isError={isError}
                        disabled={disabled}
                        inputBorderRadius={inputBorderRadius}
                        isClearable={isClearable}
                        onCopy={(e) => {
                            if (this.props.preventCopyToClipboard) {
                                this.handlerCopy(e)
                            }
                        }}
                    />
                    <Actions>
                        {
                            !disabled && isClearable && value &&
                                <IconWrapper onClick={(e) => this.onClear(e)}>
                                    <SelectClear/>
                                </IconWrapper>
                        }
                        {
                          !disabled && type === "number" &&
                          <ChangeNumberWrapper>
                              <ChangeNumber
                                disabled={value === max }
                                onClick={() => this.changeNumber("up")}
                              >
                                  <ArrowFilled/>
                              </ChangeNumber>
                              <ChangeNumber
                                disabled={value === min || !value && min === 0}
                                type={"down"}
                                onClick={() => this.changeNumber("down")}
                              >
                                  <ArrowFilled/>
                              </ChangeNumber>
                          </ChangeNumberWrapper>
                        }
                        {
                          type === "password" && needShow &&
                          <EyeIconWrapper onClick={this.togglePassword}>
                              <Eye fill={'#A1A4B1'} close={this.state.showPassword}/>
                          </EyeIconWrapper>
                        }
                    </Actions>
                </InputInner>
            </InputWrapper>
        );
    }
}

Input.propTypes = {};

export default Input;
