import { observable, computed, autorun, action } from 'mobx';

class MedicalCasesTableStoreClass {
    @observable
    tableLinesNumber = 0
}

const MedicalCasesTableStore = new MedicalCasesTableStoreClass();

export { MedicalCasesTableStore };
