import moment from "moment";
import { Notification } from "./Notification";
import {UrlSearchParams} from "GUI_MAIN/Utils/UrlSearchParams";
import { AuthStore } from "MODEL_STORE/DataSource/Stores/AuthStore";
import { basicRequestGet } from "MODEL_STORE/DataSource/Requests/basicRequestGet";
import { basicRequestPost } from "MODEL_STORE/DataSource/Requests/basicRequestPost";
import { basicRequestPut } from "MODEL_STORE/DataSource/Requests/basicRequestPut";

/**
 * максимальное кол-во отображемых нотификаций
 */
const DEFAULT_NOTIFICATIONS_COUNT = 5;

/**
 * взаимодествие с данными
 */
export class Actions {

    /**
     * ссылка на данные
     */
    data = null;

    button_show_all_notifications_disabled = true;

    is_top_panel_visible = false;

    /**
     * data - ссылка на данные
     */
    constructor(data) {
        this.data = data;
    }

    /**
     * обновление счетчика
     * пока нет метода - делаем обычный запрос к aidbox-у
     * в дальнейшем нужно будет использовать запрос типа:
     * GET /api/mm/notifications/count?status=unread
     */
    updateCounter = async () => {
        await basicRequestGet(`/api/mm/notifications/count?status=unread&recipient=${AuthStore.currentUserData.roles.map(role => role.id).join(",")}`
        ).then(response => {
            this.data.counter = response.data.data.count;
        }).catch(error => {
            console.error("При получении кол-ва непрочитанных нотификаций произошла ошибка", error);
        });
    }

    /**
     * параметры запроса (строка)
     */
    params = (_count) => {
        const query = {
            "_count": _count,
            "_sort": "-_createdAt",
            "type": this.data.filters.type,
            "_createdAt": this.data.filters.date ? this.data.filters.date : "",
            "status": this.data.filters.unread ? "unread" : "",
            "recipient": AuthStore.currentUserData.roles.map(role => role.id).join(",")
        };
        return UrlSearchParams(query);
    }

    /**
     * загрузка нотификаций (с использованием фильтров)
     * фильтр по _createdAt начинает работает с версии healthsamurai/devbox:0.5.0-SNAPSHOT
     */
    loadNotifications = (_count = '') => {
        this.button_show_all_notifications_disabled = true;
        this.data.loading = true;
        basicRequestGet(`/api/mm/notifications?${this.params(_count)}`)
        .then(response => {
            this.data.notifications = response.data.data.filter(entry => {
                const date = moment();
                date.subtract(2, "weeks");
                return moment(entry.meta.createdAt).isAfter(date);
            }).map(entry => new Notification(entry));
        }).catch(error => {
            console.error("При получении нотификаций произошла ошибка", error);
        }).finally(() => {
            this.data.loading = false;
        });
    }

    /**
     * загрузка всех нотификаций
     */
    loadAllNotifications = () => {
        this.data.filters.reset();
        this.data.popup.hideFilters();
        this.loadNotifications();
        this.button_show_all_notifications_disabled = false;
        this.is_top_panel_visible = true;
    }

    /**
     * загрузка всех нотификаций
     */
    hideAllNotifications = () => {
        this.data.filters.reset();
        this.data.popup.hideFilters();
        this.loadNotifications(DEFAULT_NOTIFICATIONS_COUNT);
        this.button_show_all_notifications_disabled = true;
        this.is_top_panel_visible = false;
    }

    /**
     * отметить одну нотификацию как прочитанную (status = read)
     */
    readOneNotification = async (id) => {
        const notification = this.data.notifications.find(notification => notification.id == id);
        if ( ! notification ) {
            return console.warn(`Нельзя изменить статус у нотификации ${id}`);
        }
        notification.resource.status = "read";
        const response = await basicRequestPost(`/api/mm/notifications/read`, {id});
        notification.setStatus(response.data.status);
        await this.updateCounter(); // обновляем счетчик непрочитанных нотификаций
    }


    /**
     * отметить все нотификации как прочитанные
     */
    readAllNotifications = () => {
        if( ! this.data.notifications.length ) {
            return console.warn(`Нельзя отметить все нотификации как прочитанные, так как список пуст`);
        }
        basicRequestPost('/api/mm/notifications/read-all', {
            //после интеграции новой авторизации
            role_id: AuthStore.currentUserData.roles.map(role => role.id).join(",")
        })
            .then(() => {
            this.loadNotifications(DEFAULT_NOTIFICATIONS_COUNT);
            this.updateCounter(); // обновляем счетчик непрочитанных нотификаций
        }).catch(error => {
            console.error(`При изменении статусов нотификаций произошла ошибка`, error);
        });
    }

    /**
     * сброс фильтров
     */
    resetFilters = () => {
        this.data.filters.reset();
        this.data.popup.hideFilters();
        this.loadNotifications();
        this.button_show_all_notifications_disabled = false;
        this.is_top_panel_visible = true;
    }

    /**
     * применение фильтров
     */
    applyFilters = () => {
        this.data.popup.hideFilters();
        this.loadNotifications();
        this.button_show_all_notifications_disabled = false;
        this.is_top_panel_visible = true;
    }

    /**
     * отображение списка нотификаций
     */
    showNotifications = () => {
        this.data.filters.reset();
        this.data.popup.show();
        this.loadNotifications(DEFAULT_NOTIFICATIONS_COUNT);
    }

    /**
     * скрытие списка нотификаций
     */
    hideNotifications = () => {
        this.data.filters.reset();
        this.data.popup.hide();
    }

    /**
     * очистка списка нотификаций
     */
    clearNotifications = () => {
        this.data.notifications = [];
    }

}
