import {basicRequestGet} from "MODEL_STORE/DataSource/Requests/basicRequestGet";
import {basicRequestPost} from "MODEL_STORE/DataSource/Requests/basicRequestPost";
import _get from "lodash/get";
import { OrganizationStore } from 'MODEL_STORE/DataSource/Stores/OrganizationStore';

export class OrganizationsData {

  organizationStore = new OrganizationStore();

  hasConsiliumProviderType = (organization)  => !!_get(organization, "type", []).find(codeableConcept => {
      return _get(codeableConcept, "coding", []).find(coding => {
          return _get(coding, "code") == "consilium-provider"
              && (_get(coding, "system") == "http://miramedix.ru/fhir/CodeSystem/organization-type" ||
              _get(coding, "system") == "http://terminology.hl7.org/CodeSystem/organization-type");
      });
  });

  /**
   * Указан ли в организации тип - Филиал, структурное подразделение
   */
  hasDeptType = (organization)  => {
      return !!_get(organization, "type", []).find(codeableConcept => {
          return _get(codeableConcept, "coding", []).find(coding => {
              return _get(coding, "code") == "dept"
                  && (_get(coding, "system") == "http://miramedix.ru/fhir/CodeSystem/organization-type" ||
                  _get(coding, "system") == "http://terminology.hl7.org/CodeSystem/organization-type");
          });
      })
  };

  loadOrganizationsDataConsiliumProvider = (query = {}) => {
      return basicRequestPost("/Organization/_search", { "_count": "999", ...query }).then(response => {
          return response.data.total ?
              response.data.entry.map(e => this.hasConsiliumProviderType(e.resource) ? e.resource : null).filter(r => {
                if(r) return (r.id != "defaultOrganization" && r.id != "mobileOrganization");
              })
              : [];
      });
  }

  loadOrganizationsData = (query = {}) => {
      const params = { _count: "999", ...query };

      let queryArray = []
      Object.keys(params).forEach(filterName => {
          queryArray.push(`${filterName}=${params[filterName]}`)
      });
      const query = queryArray.join("&");
      return basicRequestGet(`/api/mm/organizations?${query}`).then(response => {
          return response.data.data.length ?
              response.data.data.filter(r => {
                if(r) return (r.id != "defaultOrganization" && r.id != "mobileOrganization");
              })
              : [];
      });
  }

  loadOrganizationsDataWithLevelConsilium = (consiliumLevel) => {
      return basicRequestPost("/api/mm/organization/consilium-providers-by-level", {
        "consilium-level-code": consiliumLevel
      })
      .then(response => response.data);
  }

  loadOrganizationsDataFromStore = () => {
      return this.organizationStore.search({
        'active': "true",
        "id:not": "defaultOrganization" || "mobileOrganization",
      });
  }

  loadProviderOrganizationsData = (id) => {
    return basicRequestPost("/Organization/_search", {
            "type": "prov",
            "_sort": "name",
            "_count": "999",
            "id:not": id
        }).then(response => {
            return response.data.total ?
                response.data.entry.map(e => e.resource).filter(r => {
                  if(r) return (r.id != "defaultOrganization" && r.id != "mobileOrganization");
                })
                : [];
        });
      }
}
