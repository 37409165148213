import React from "react";
import styled, {withTheme} from "styled-components";
import {inject} from "mobx-react";
import PropTypes from 'prop-types';

@withTheme
@inject('ContextualMenuStore')
export default class ContextualMenu extends React.Component {

    static propTypes = {
        closeByClickOutSide: PropTypes.bool,
    };

    static defaultProps = {
        closeByClickOutSide: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.ContextualMenuStore.side === 'left'){

            const {target, side} = this.props.ContextualMenuStore;

            let menu = document.getElementById('contextual-menu'),
                menuTop = menu.getBoundingClientRect().top,
                menuHeight = menu.offsetHeight;

            if((window.innerHeight - menuTop) < menuHeight) {
                this.setState({
                    side: 'left-top',
                    top: ((menuTop + 50) - menuHeight) + 'px',
                    bottom: 'auto',
                });

            }
        }
    }

    constructor(props){
        super(props);
        this.contextMenu = React.createRef();
        this.state = {
            side: props.ContextualMenuStore.side,
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            trianglePos: '',
            triangleCoords: [],
        }
    }

    onClick = e => {
        e.stopPropagation();
        this.props.ContextualMenuStore.isOpen = false;

        if (typeof this.props.ContextualMenuStore.onClickOutside == 'function') {
            this.props.ContextualMenuStore.onClickOutside();
        }
    };

    calculateTriangleCoords = (targetIcon, side) => {

        var posX = 0;
        var posY = 0;

        if(side === 'left'){
            posX = targetIcon.getBoundingClientRect().left - 16;
            posY = targetIcon.getBoundingClientRect().top + targetIcon.getBoundingClientRect().height / 2;
        }
        if(side === 'right'){
            posX = targetIcon.getBoundingClientRect().left + targetIcon.getBoundingClientRect().width;
            posY = targetIcon.getBoundingClientRect().top + targetIcon.getBoundingClientRect().height / 2;
        }
        if(side === 'top'){
            posX = targetIcon.getBoundingClientRect().left + targetIcon.getBoundingClientRect().width / 2 - 8;
            posY = targetIcon.getBoundingClientRect().top - 16 - targetIcon.getBoundingClientRect().height / 2;
        }
        if(side === 'bottom'){
            posX = targetIcon.getBoundingClientRect().left + targetIcon.getBoundingClientRect().width / 2 - 8;
            posY = targetIcon.getBoundingClientRect().top + targetIcon.getBoundingClientRect().height;
        }
        if(side === 'bottom-left'){
            posX = targetIcon.getBoundingClientRect().left + targetIcon.getBoundingClientRect().width / 2 - 8;
            posY = targetIcon.getBoundingClientRect().top + targetIcon.getBoundingClientRect().height;
        }

        if(side === 'right-bottom'){
            posX = targetIcon.getBoundingClientRect().left + targetIcon.getBoundingClientRect().width;
            posY = targetIcon.getBoundingClientRect().top + targetIcon.getBoundingClientRect().height / 2;
        }

        if(side === 'left-top'){
            posX = targetIcon.getBoundingClientRect().left - 16;
            posY = targetIcon.getBoundingClientRect().top + targetIcon.getBoundingClientRect().height / 2 - 8;
        }

        this.setState({
            triangleCoords: [posX, posY]
        })
    };

    componentDidMount() {
        const {coords, target, side, limit, offset} = this.props.ContextualMenuStore;

        //координаты нижней границы и центра нажатого элемента (иконки)


        let menu = document.getElementById('contextual-menu'),
            menuWidth = menu.offsetWidth,
            menuHeight = menu.offsetHeight;

        let targetCoord = target.getBoundingClientRect();

        if(side === 'left'){
            this.setState({
                left: 'auto', //targetCoord.left - menuWidth - 10 + 'px',
                right: window.innerWidth - targetCoord.left + offset[0] + 'px',
            });

            if((window.innerHeight - targetCoord.top) < menuHeight) {
                this.setState({
                    top: ((targetCoord.top + target.offsetHeight/2) - menuHeight) + offset[1] + 'px',
                    bottom: 'auto',
                });
            } else {
                if((targetCoord.top + target.offsetHeight/2) - (menuHeight/2) < 0 )
                    this.setState({
                        top: '10px',
                        bottom: 'auto',
                    });
                else
                    this.setState({
                        top: ((targetCoord.top + target.offsetHeight/2) - (menuHeight/2)) + offset[1] + 'px',
                        bottom: 'auto',
                    });
            }
        }

        if(side === 'right'){
            this.setState({
                left: targetCoord.left + target.offsetWidth + 10 + offset[0] + 'px',
                right: 'auto',
            });
            this.setState({
                top: ((targetCoord.top + target.offsetHeight/2) - (menuHeight/2)) + offset[1] + 'px',
                bottom: 'auto',
            });
        }
        if(side === 'bottom'){
            this.setState({
                left: targetCoord.left - menuWidth/2 + offset[0] + 'px',
                right: 'auto',
            });

            this.setState({
                top: targetCoord.top + 40 + offset[1] + 'px',
                bottom: 'auto',
            });
        }
        if(side === 'bottom-left'){
            this.setState({
                left: targetCoord.left - menuWidth + 18 + offset[0] + 'px',
                right: 'auto',
            });

            if(window.innerHeight - (targetCoord.top + target.offsetHeight) < menuHeight) {
                this.setState({
                    top: targetCoord.top - menuHeight - 8 - offset[1] + 'px',
                    bottom: 'auto',
                });
            } else {
                this.setState({
                    top: targetCoord.top + 40 + offset[1] + 'px',
                    bottom: 'auto',
                });
            }
        }
        if(side === 'right-bottom'){
            this.setState({
                left: targetCoord.left + target.offsetWidth + 10 + offset[0] + 'px',
                right: 'auto',
            });

            if(window.innerHeight - (targetCoord.top + target.offsetHeight) < menuHeight) {
                this.setState({
                    top: targetCoord.top - menuHeight - 8 + offset[1] + 'px',
                    bottom: 'auto',
                });
            } else {
                this.setState({
                    top: ((targetCoord.top + target.offsetHeight/2) - (menuHeight/4)) + offset[1] + 'px',
                    bottom: 'auto',
                });
            }

        }
        if(side === 'left-top'){
            this.setState({
                left: 'auto', //targetCoord.left - menuWidth - 10 + 'px',
                right: window.innerWidth - targetCoord.left + offset[0] + 'px',
            });
            this.setState({
                top: 'auto',
                bottom: document.body.offsetHeight - targetCoord.bottom - 8 + offset[1] + 'px',
            });
        }
        if(side === 'top'){
            this.setState({
                left: 'auto',
                right: window.innerWidth - targetCoord.right - targetCoord.width / 2 + 8 + offset[0] + 'px',
            });
            this.setState({
                top: 'auto',
                bottom: document.body.offsetHeight - targetCoord.top + 8 + offset[1] + 'px',
            });
        }
    }

    render() {
        const {
            isOpen,
            coords,
            contentComponent,
            side,
            limit,
            closeByClickOutSide,
            iconMenuEvent,
            pointerBackground,
        } = this.props.ContextualMenuStore;

        if(!isOpen) return null;

        return (
            <BackgroundWrapper iconMenuEvent={iconMenuEvent} pointerBackground={pointerBackground}>
                <BackgroundClose onClick={this.onClick} isOpen={isOpen} closeByClickOutSide={closeByClickOutSide}/>
                <ContextualMenuWrapper
                    coords={coords}
                    id={'contextual-menu'}
                    ref={this.contextMenu}
                    position={[this.state.left, this.state.right, this.state.bottom, this.state.top]}
                    side={side}
                    limit={limit}
                >
                    <ContentWrapper>
                        {contentComponent}
                    </ContentWrapper>
                    <WhiteTriangle
                        coords={coords}
                        triangleCoords={this.state.triangleCoords}
                        side={this.state.side}
                    />
                </ContextualMenuWrapper>
            </BackgroundWrapper>
        );
    }
}

const ContextualMenuWrapper = styled.div`
  position: fixed;
  left:  ${props => props.position[0]};
  right: ${props => props.position[1]};
  bottom: ${props => props.position[2]};
  top: ${props => props.position[3]};
  z-index: 10;
  box-shadow: 0 4px 16px 0 #0000002E;
  border-radius: 4px;
`;

const ContentWrapper = styled.div`
  //border: 1px solid ${props => props.theme.contextMenuBorder};
  border-radius: 4px;
  overflow: hidden;
`;

const WhiteTriangle = styled.div`
  position: absolute;
  display: none;
  top: ${props => setTriangleSideTop(props.side)};
  left: ${props => setTriangleSideLeft(props.side)};
  border-style: solid;
  border-width: ${props => setTrianglePosition(props.side)};
  border-color: ${props => setTriangleColor(props.side, props.theme)};
  z-index: -1;
`;

const setTrianglePosition = (side) => {
    switch (side) {
        case 'left':
            return '8px 0 8px 16px';
        case 'right':
            return '8px 16px 8px 0';
        case 'top':
            return '16px 8px 0 8px';
        case 'bottom':
            return '0 8px 16px 8px';
        case 'bottom-left':
            return '0 8px 16px 8px';
        case 'right-bottom':
            return '8px 16px 8px 0';
        case 'left-top':
            return '8px 0 8px 16px';
        default:
            return '8px 0 8px 16px';
    }
};

const setTriangleSideTop = (side) => {
    switch (side) {
        case 'left':
            // return 'calc(50% - 8px)';
            return '20px'
        case 'right':
            return 'calc(50% - 8px)';
        case 'top':
            return 'calc(100% - 8px)';
        case 'bottom':
            return '-8px';
        case 'bottom-left':
            return '-8px';
        case 'right-bottom':
            return '34px';
        case 'left-top':
            return 'calc(100% - 28px)';
    }
};

const setTriangleSideLeft = (side) => {
    switch (side) {
        case 'left':
            return 'calc(100% - 8px)';
        case 'right':
            return '-8px';
        case 'top':
            return 'calc(50% - 8px)';
        case 'bottom':
            return 'calc(50% - 8px)';
        case 'bottom-left':
            return 'calc(100% - 16px)';
        case 'right-bottom':
            return '-8px';
        case 'left-top':
            return 'calc(100% - 8px)';
    }
};

const setTriangleColor = (side, theme) => {
    switch (side) {
        case 'left':
            return  `transparent transparent transparent ${theme.whiteColor}`;
        case 'right':
            return `transparent ${theme.whiteColor} transparent transparent`;
        case 'top':
            return `${theme.whiteColor} transparent transparent transparent`;
        case 'bottom':
            return `transparent transparent ${theme.whiteColor} transparent`;
        case 'bottom-left':
            return `transparent transparent ${theme.whiteColor} transparent`;
        case 'right-bottom':
            return `transparent ${theme.whiteColor} transparent transparent`;
        case 'left-top':
            return  `transparent transparent transparent ${theme.whiteColor}`;
        default:
            return `transparent transparent transparent ${theme.whiteColor}`;
    }
};

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: ${props => props.iconMenuEvent ? '60px' : '0'};
  width: ${props => props.iconMenuEvent ? 'calc(100% - 60px)' : '100%'};
  height: 100%;
  z-index: 9999;
  pointer-events: ${props => props.pointerBackground ? "none" : "auto"};
`;

const BackgroundClose = styled.div`
  ${props => props.closeByClickOutSide &&
    ' position: fixed;' +
    ' top: 0; ' +
    ' left: 60px;' +
    ' right: 0;' +
    ' bottom: 0;' +
    ' background-color: transparent;'
};
`;
