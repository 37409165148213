import _trim from "lodash/trim";
import { observable } from "mobx";

export class SnilsFilter {

    /**
     * значение фильтра
     */
    @observable value = "";

    /**
     * иногда хранить не нужно
     */
    saveToStorage = true;

    constructor(options) {
        if (options && typeof options.saveToStorage !== "undefined") {
            this.saveToStorage = options.saveToStorage;
        }
    }

    /**
     * сброс фильтра
     */
    reset = () => {
        this.value = '';
        this.setLocalStorage();
    }

    /**
     * установка значения фильтра
     * СНИЛС должен состоять из цифр
     */
    setValue = (value = "") => {
        if(/\d/.test(value)) {
            this.value = value;
        } else {
            this.value = "";
        }
        this.setLocalStorage();
    }

    /**
     * номер без лишних символов
     */
    get number() {
        return _trim(this.value, "-_");
    }

    initLocalStorage = (localStoreFieldsKey, paramKey) => {
        if (!this.saveToStorage) {
            return;
        }
        this.localStoreFieldsKey = localStoreFieldsKey;
        this.paramKey = paramKey;
    }

    setLocalStorage = () => {
        if (!this.saveToStorage) {
            return;
        }
        let filters = localStorage.getItem(this.localStoreFieldsKey);
        if (filters) {
            filters = JSON.parse(filters);
        } else {
            filters = {};
        }
        filters[this.paramKey] = this.value;
        localStorage.setItem(this.localStoreFieldsKey, JSON.stringify(filters));
    }
}
