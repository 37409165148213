/**
 * попап для формы редактирования роли врача в организации
 *
 *
 *
 *
 */
import React from 'react';
import { observer, inject } from 'mobx-react';
import { PopupBasic } from 'GUI_MAIN/Elements/Popups/PopupBasic';
import { PractitionerRoleForm } from 'GUI_MAIN/Components/PractitionerRole/PractitionerRoleForm';
import { Translator } from "eo-locale";
import { HeaderStore } from "MODEL_STORE/DataSource/Stores/HeaderStore";
import { locales } from "Locale";

@inject('PractitionerRolePopupStore')
@observer
export class PractitionerRolePopup extends React.Component {

    /**
     *
     */
    close = () => {

       this.props.PractitionerRolePopupStore.close();
    }

    /**
     *
     */
    render() {
        const translator = new Translator(HeaderStore.locale, locales);
        return (
            <PopupBasic title={translator.translate('TT021060')} onClose={this.close} >
                <PractitionerRoleForm onSave={this.close} />
            </PopupBasic>
        );

    }



}
