import React from 'react';
import styled from 'styled-components';



const Message = styled.div`
    display: block;
    color: white;
    text-align: center;
`;
const MessageText = styled.div`
    margin-bottom: 32px;
`;



import { StdButton } from './components';
import { EOLocale, useTranslator } from 'eo-locale';
import { HeaderStore } from 'MODEL_STORE/DataSource/Stores/HeaderStore';

export function FileNotFoundPane(props) {

    const translator = useTranslator(HeaderStore.locale);

    return (
        <Message>
            <MessageText>
                <span>
                    {translator.translate('TT023680')}
                </span>
            </MessageText>
            <StdButton onClick={props.close}>
                {translator.translate('TT023690')}
            </StdButton>
        </Message>
    );


}
