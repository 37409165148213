// ........................................
// ........................................
// ...............WWWWWWWWW................
// ...........WNXK00OOOOOO0KXNW............
// .........WX0Okkkkkkkkkkkkkk0XWW.........
// .......WX0kkkkkkkkkkkkkkkkkkkOXW........
// ......WXOkkkkkkkk.....kkkkkkkkOKW.......
// .....WXOkkkkkkkkk.....kkkkkkkkkOXW......
// .....WKkkkkkkkkkk.....kkkkkkkkkk0W......
// .....W0kkkkkkkkkk.....kkkkkkkkkk0N......
// .....WKkkkkkkkkkk.....kkkkkkkkkk0W......
// ......NOkkkkkkkkkkkkkkkkkkkkkkkOXW......
// ......WXOkkkkkkkk.....kkkkkkkkOKW.......
// .......WN0kkkkkkkkkkkkkkkkkkkOXW........
// .........WX0OkkkkkkkkkkkkkO0XW..........
// ...........WNXK00OOOOO00KXNW............
// ................WWWWWWWW................
// ........................................
// ........................................
// ........................................

import React, {PureComponent} from 'react';

export default class AuthorPostIcon extends PureComponent{
    render(){
        return(


            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z" fill={this.props.fill || "#F2994A"}/>
            </svg>
        )
    }
}