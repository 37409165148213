import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class BurgerMenu extends PureComponent {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 8V6H22V8H3Z" fill="white"/>
                <path d="M3 13V11H22V13H3Z" fill="white"/>
                <path d="M3 16V18H22V16H3Z" fill="white"/>
            </svg>

        );
    }
}

BurgerMenu.propTypes = {};

export default BurgerMenu;