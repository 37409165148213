import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class WarningCircleIcon extends PureComponent {

    static propTypes = {
        fill: PropTypes.string,
    }

    static defaultProps = {
        fill: "#F2994A",
    }

    render() {

        const {fill} = this.props;

        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z" fill={fill}/>
            </svg>
        );
    }
}

WarningCircleIcon.propTypes = {};

export default WarningCircleIcon;