import React, {PureComponent} from 'react';
import styled from 'styled-components';
import BurgerMenu from "../../../icons/BurgerMenu";
import {inject, observer} from "mobx-react";


const LogoWrapper = styled.div`
  width: ${props=> props.stateIconMenu ? '50px' : '240px'};
  height: 64px;
  padding: 0 0 0 12px;
  transition: width ${props => props.theme.menuTransition};
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-start;`;

const BtnWrapper = styled.div`
  width: 24px;
  min-width: 24px;
  min-height: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 24px 0 0;

  svg{
    height: 100%;
    width: auto;
  }`;

@inject('SideMenuStore')
@inject('SidePanelsStore')
@observer
export default class HeaderLogo extends PureComponent{

    render(){
        const {showMenu, SideMenuStore} = this.props;
        const isStateOpen = !SideMenuStore.isOpen || SideMenuStore.shouldBeMinimized;

        return(
            <LogoWrapper stateIconMenu={isStateOpen}>
                {
                    showMenu &&
                    <BtnWrapper onClick={() => SideMenuStore.toggleSideMenu(!SideMenuStore.isOpen)}>
                        <BurgerMenu/>
                    </BtnWrapper>
                }
            </LogoWrapper>
        )
    }
}
