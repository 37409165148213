/**
 *
 * функционально это, похоже, повторяет base64toUtf8.js
 * но поскольку тот используется в работе с ЭЦП, не трогаю
 *
 */

export const base64toUtf = (str) => {

    // support for url-safe-encoded strings
    const stage1 = str.replace(/_/g, "/").replace(/-/g, "+");;

    // get raw decoded data
    const stage2 = atob(stage1);

    // decode UTF8
    const stage3 = decodeURIComponent(
        stage2
            .split('')
            .map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return stage3;
}