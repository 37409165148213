import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Menu from "./Menu/Menu";
import Scrollbar from "react-scrollbars-custom";
import {inject, observer} from "mobx-react";
import SideMenuSupport from "./SideMenuSupport";
import {CustomVersion} from "../../../MODEL_STORE/DataSource/Stores/CustomVersion";

const SideMenuSection = styled.div`
  overflow-x: hidden;
  width: ${props => props.isMenuShow ? '50px' : '240px'};
  min-width: ${props => props.isMenuShow ? '50px' : '240px'};
  transition: width ${props => props.theme.menuTransition}, min-width ${props => props.theme.menuTransition};
  position: relative;
  margin-top: 64px;
  height: calc(100vh - 64px);
  border-right: 1px solid #D3D4D8`;


const SideMenuWrapper = styled.div`
    width: 240px;
    min-width: 240px;
    height: calc(100vh - 64px);
    //overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    background-color: ${props => props.theme.whiteColor};
    padding-top: 16px;
`;

const SideMenuComponents = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

@inject('SideMenuStore')
@observer
export default class SideMenu extends PureComponent{

    static propTypes = {
        isMenuShow: PropTypes.bool,
    };

    state = {
        activeMenuItem: null
    }

    componentDidMount() {
        this.checkMenuShow();
        window.addEventListener("resize", this.checkMenuShow)
    }

    checkMenuShow = () => {
        if(window.innerWidth < 1359) {
            this.props.SideMenuStore.toggleSideMenu(false)
        } else {
            this.props.SideMenuStore.toggleSideMenu(true)
        }
    }

    setActiveMenuItem = (activeMenuItem) => {
        this.setState({activeMenuItem});
    }

    render(){
        const {SideMenuStore} = this.props;

        const isStateOpen = !SideMenuStore.isOpen || SideMenuStore.shouldBeMinimized;

        return(
            <SideMenuSection isMenuShow={isStateOpen}>
                <SideMenuWrapper isMenuShow={isStateOpen}>
                    <Scrollbar>
                        <SideMenuComponents>
                            <Menu isMenuShow={isStateOpen} setActiveMenuItem={this.setActiveMenuItem} activeMenuItem={this.state.activeMenuItem}/>
                        </SideMenuComponents>
                    </Scrollbar>
                </SideMenuWrapper>
            </SideMenuSection>
        )
    }
}
