/**
 * форма редактирования роли врача в организации
 *
 *
 *
 *
 */
import React, {Fragment} from 'react';
import { observer, inject } from 'mobx-react';
import _map from 'lodash.map';
import { OID } from 'MODEL_STORE/DataSource/Constants/oid';
import Select from 'react-select';
import { OrganizationStore } from 'MODEL_STORE/DataSource/Stores/OrganizationStore';
import { PractitionerRolePositionsValueSet } from "MODEL_STORE/DataSource/Stores/ValueSets/PractitionerRolePositionsValueSet";
import { PractitionerRoleSpecializationsValueSet } from "MODEL_STORE/DataSource/Stores/ValueSets/PractitionerRoleSpecializationsValueSet";
import { Translator, EOLocale } from "eo-locale";
import { HeaderStore } from "MODEL_STORE/DataSource/Stores/HeaderStore";
import { locales } from "Locale";


@inject('PractitionerRolePopupStore')
@observer
export class PractitionerRoleForm extends React.Component {

	/**
	 *
	 */
    organizationStore = new OrganizationStore();

    /**
     * справочник должностей
     */
    practitionerRolePositionsValueSet = new PractitionerRolePositionsValueSet();

    /**
     * справочник специализаций
     */
    practitionerRoleSpecializationsValueSet = new PractitionerRoleSpecializationsValueSet();

    /**
     *
     */
    constructor(props) {


        super(props);

        // говорим стору, чтобы подгрузил данные
		this.organizationStore.search({'active': "true"});

        // если организация уже выбрана, селектор не показываем, только текущее название
        this.state = {
            showOrganizationSelector : ! props.PractitionerRolePopupStore.params.role.organization
        }
    }

    /**
     *
     */
    setOrganization = (newId) => {
        let {PractitionerRolePopupStore} = this.props;
        let newOrganization = null;

        if (newId) {
            console.warn(newId);
            newOrganization = this.organizationStore.find(newId);

            if ( ! newOrganization) {
                console.warn('organization not found but supposed to exist, check this role');
                newOrganization = null;
            }
        }

        PractitionerRolePopupStore.params.role.organization = newOrganization;
    };


    /**
     * показываем выбор организации
     */
    renderOrganizationAsSelect = () => {
        const organizationReference = this.props.PractitionerRolePopupStore.params.role.organization;
        return (
            <select className="form-control"
                    onChange={ e => this.setOrganization(e.target.value) }
                    value={organizationReference ? organizationReference.id : ''}>
                <option value={ null }><EOLocale.Text id={'SS9035783'} /></option>
                {
                    this.organizationStore.data.map( o => {
                        return <option key={o.id} value={o.id}>
                            { o.name }
                        </option>
                    })
                }
            </select>
        );
    };

    /**
     * контрол для организации. Если организация уже выбрана, менять не даем
     */
    renderOrganizationAsString = () => {
        const organizationReference = this.props.PractitionerRolePopupStore.params.role.organization;
        const organization = this.organizationStore.find(organizationReference.id);
        const translator = new Translator(HeaderStore.locale, locales);
        if ( ! organization) {
            console.warn('organization not found but supposed to exist, check this role');
            return translator.translate('TT021050');
        }
        return organization.name;
    };

    /**
     *
     */
    renderPositionSelector = () => {
        const options = this.practitionerRolePositionsValueSet.options;

        const selectedCodes = this.props.PractitionerRolePopupStore.params.role.getCodeForOid(OID.POSITIONS_ROSTER)
            .map( e => e.code );

        const selectedOptions = options.filter(option => selectedCodes.includes(option.value));

        return (
            <Select
                options={options}
                value={selectedOptions}
                isMulti={true}
                onChange={this.setPosition}/>
        );
    };

    /**
     *
     */
    setPosition = (e) => {
        // var selectedId = selectMultiValue(e.target); // вариант для нативного <select>
        const selectedValues = _map(e, e => e.value); // вариант для react-select

        const selectedConcepts = this.practitionerRolePositionsValueSet.options.filter(option => {
            return selectedValues.includes(option.value);
        }).map(option => option.concept);

        this.props.PractitionerRolePopupStore.params.role.setCodeFromValueset(selectedConcepts);
    };

    /**
     *
     */
    renderSpecialtySelector = () => {
        const options = this.practitionerRoleSpecializationsValueSet.options;
        const selectedValues = this.props.PractitionerRolePopupStore.params.role.getSpecialtyForOid(OID.SPECIALTIES_ROSTER).map( e => e.code );
        const selectedOptions = options.filter(option => selectedValues.includes(option.value));
        return (
            <Select
                options={options}
                value={selectedOptions}
                isMulti={true}
                onChange={this.setSpecialty}
            />
        );
    };

    /**
     *
     */
    setSpecialty = (e) => {
        const selectedValues = _map(e, e => e.value); // вариант для react-select

        const selectedConcepts = this.practitionerRoleSpecializationsValueSet.options.filter(option => {
            return selectedValues.includes(option.value);
        }).map(option => option.concept);

        this.props.PractitionerRolePopupStore.params.role.setSpecialtyFromValueset(selectedConcepts);
    };


    /**
     *
     */
    render() {
        return (
            <Fragment>
                <div className="form-row mb-4">
                    <div className="col-3">
                        <EOLocale.Text id={'SS009005'} />
                    </div>
                    <div className="col-9">
                        { this.state.showOrganizationSelector ? this.renderOrganizationAsSelect() : this.renderOrganizationAsString() }
                    </div>
                </div>
                <div className="form-row mb-4">
                    <div className="col-3">
                        <EOLocale.Text id={'SS009040'} />
                    </div>
                    <div className="col-9">
                        { this.renderPositionSelector() }
                    </div>
                </div>
                <div className="form-row mb-4">
                    <div className="col-3">
                        <EOLocale.Text id={'SS9035932'} />
                    </div>
                    <div className="col-9">
                        { this.renderSpecialtySelector() }
                    </div>
                </div>
            </Fragment>
        );
    }
}
