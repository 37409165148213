import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme }from 'styled-components';
import {setLineHeight} from "../../Utils/setLineHeight";
import {StyledReactTooltip, overPos} from "GUI_MAIN/Elements/StyledReactTooltip/index";
import uuid from "uuid/v4";

export const MKBCodeWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   min-width: 50px;
   padding: 6px 2px;
   margin: ${props => props.margin || '0'};
   border-radius: 4px;
   font-family: ${props => props.theme.OpenSansRegular};
   font-size: ${props => props.theme.mediumFontSize};
   color: ${props => props.theme.darkMainTextColor};
   line-height: ${props => setLineHeight(props.theme.normalFontSize)};
   background-color: ${props => props.selected ? props.theme.mkbSelectedCodeBG : props.theme.mkbCodeBG};
   cursor: ${props => props.cursorPointer ? 'pointer' : 'default'};
   opacity: ${props => props.inArchive ? "0.6" : "1"};

   &:hover, &:active{
    background-color: ${props => props.theme.mkbCodeHoverActive};
   }
`;

class MKBCode extends PureComponent {
    constructor(props) {
        super(props);

        this.mkb = React.createRef()

        this.leftCoord = 0;
    }
    componentDidMount() {
        if(this.mkb !== null){
            //this.leftCoord = this.mkb.current.getBoundingClientRect().left > 640;
           // console.log(this.mkb.current.getBoundingClientRect().left > 400 + 240)
        }
    }

    render() {
        const {
            code,
            margin,
            selected,
            dataTip,
            cursorPointer,
            dataFor,
            onClick
        } = this.props;

        const id = uuid();
        return (
            <Fragment>
                <MKBCodeWrapper
                    margin={margin}
                    selected={selected}
                    data-tip={dataTip}
                    cursorPointer={cursorPointer}
                    data-for={id+dataFor}
                    onClick={onClick}
                    className={"mkb-code-chip"}
                    //data-place={this.leftCoord &&'right'}
                >
                    {code}
                </MKBCodeWrapper>
                {
                    dataTip &&
                        <div ref={this.mkb}>
                    <StyledReactTooltip
                        id={id+dataFor}
                        type="light"
                        effect={"solid"}
                        place={"bottom"}
                        className={'light light-simple max-width-400'}
                        overridePosition={overPos}
                    />
                        </div>
                }
            </Fragment>
        );
    }
}

MKBCode.propTypes = {
    code: PropTypes.string.isRequired
};

MKBCode.defaultProps = {
    dataTip: '',
    cursorPointer: false,
    onClick: () => {}
};

export default MKBCode;
