import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class RequestIcon extends PureComponent {

    render() {

        const {fill} = this.props;

        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9 13C9 12.4477 8.55228 12 8 12C7.44772 12 7 12.4477 7 13H5C5 11.8702 5.62455 10.8862 6.54724 10.3746C6.20803 10.0162 6 9.5324 6 9C6 7.89543 6.89543 7 8 7C9.10457 7 10 7.89543 10 9C10 9.5324 9.79197 10.0162 9.45276 10.3746C10.3755 10.8862 11 11.8702 11 13H9Z"
                      fill={fill}
                />
                <path d="M12.0005 9C12.0005 8.44772 12.4482 8 13.0005 8H18.0005C18.5528 8 19.0005 8.44772 19.0005 9C19.0005 9.55228 18.5528 10 18.0005 10H13.0005C12.4482 10 12.0005 9.55228 12.0005 9Z"
                      fill={fill}
                />
                <path d="M13.0005 11C12.4482 11 12.0005 11.4477 12.0005 12C12.0005 12.5523 12.4482 13 13.0005 13H18.0005C18.5528 13 19.0005 12.5523 19.0005 12C19.0005 11.4477 18.5528 11 18.0005 11H13.0005Z"
                      fill={fill}
                />
                <path d="M5 16C5 15.4477 5.44772 15 6 15H10C10.5523 15 11 15.4477 11 16C11 16.5523 10.5523 17 10 17H6C5.44772 17 5 16.5523 5 16Z"
                      fill={fill}
                />
                <path d="M13 16C13 15.4477 13.4477 15 14 15H18C18.5523 15 19 15.4477 19 16C19 16.5523 18.5523 17 18 17H14C13.4477 17 13 16.5523 13 16Z"
                      fill={fill}
                />
                <path fillRule="evenodd" clipRule="evenodd" d="M5 4C3.34315 4 2 5.34315 2 7V17C2 18.6569 3.34315 20 5 20H19C20.6569 20 22 18.6569 22 17V7C22 5.34315 20.6569 4 19 4H5ZM4 7C4 6.44772 4.44772 6 5 6H19C19.5523 6 20 6.44772 20 7V17C20 17.5523 19.5523 18 19 18H5C4.44772 18 4 17.5523 4 17V7Z"
                      fill={fill}
                />
            </svg>
        );
    }
}

RequestIcon.propTypes = {
    fill: PropTypes.string,
};

RequestIcon.defaultProps = {
    fill: '#121232',
};

export default RequestIcon;