//Галочка для чекбокса

import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class CheckedIcon extends PureComponent{
    static propTypes = {
        fill: PropTypes.string,
    }

    static defaultProps = {
        fill: "black",
    }
    render(){
        const {fill} = this.props
        return(
            <svg width="18" height="16" viewBox="0 0 18 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
                <path d="M15.885 -1.21487e-07L6 10.4936L2.115 6.38535L0 8.63057L6 15L18 2.26115L15.885 -1.21487e-07Z"/>
            </svg>


        )
    }
}
