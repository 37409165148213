import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CounterWrapper = styled.div`
  display: inline-block;
  min-width: 16px;
  height: 16px;  
  font-size: ${props => props.theme.normalFontSize};
  font-family: ${props => props.theme.OpenSansBold};
  padding: 0 2px;
  text-align: center;
  vertical-align: middle;
  border-radius: 2px;
  background-color: ${props => props.theme.counterBG};
`;

export class Counter extends PureComponent {
    static propTypes = {
        value: PropTypes.number
    }
    static defaultProps = {
        value: 0
    }
    render() {
        return (
            <CounterWrapper>
                {this.props.value}
            </CounterWrapper>
        );
    }
}
