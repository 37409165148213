import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import styled from "styled-components";
import Input from "GUI_MAIN/Elements/Input";
import Button from "GUI_MAIN/Elements/Button";
import TextArea from "GUI_MAIN/Elements/TextArea";
import { ToasterStore } from "MODEL_STORE/DataSource/Stores/ToasterStore";
import { basicRequestPost } from "MODEL_STORE/DataSource/Requests/basicRequestPost";

/**
 * 
 */
const PageWrapper = styled.div`
    padding: 10px;
`;

/**
 * 
 */
const TitleWrapper = styled.div`
    margin-bottom: 10px;
    font-size: ${props => props.theme.veryBigFontSize};
    font-family: ${props => props.theme.OpenSansRegular};
`; 

/**
 * 
 */
const RequestWrapper = styled.div`

`;

/**
 * 
 */
const ResponseWrapper = styled.div`
    > div {
        margin-top: 10px;
    }
`;

/**
 * https://jira.mmdx.ru/browse/FCSPOK-321
 * GET /api/integrations/v2/accesspoint
 */
@observer
export class AccessPoint extends React.Component {

    /**
     * значение
     */
    @observable payload = JSON.stringify({
        "resourceType": "Bundle",
        "type": "collection",
        "entry": []
    }, null, 2);

    /**
     * код ответа
     */
    @observable responseCode = "";

    /**
     * значение ответа
     */
    @observable responseData = "";

    /**
     * изменение значения
     */
    onChange = (event) => {
        this.payload = event.target.value;
    }

    /**
     * при отправке значения
     */
    onSubmit = () => {
        if ( ! this.payload.trim().length ) {
            return ToasterStore.warning("Укажите контекст запроса");
        }
        let payloadData = "";
        try {
            payloadData = JSON.parse(this.payload)
        } catch (e) {
            console.error(e);
            return ToasterStore.warning("Укажите валидный json в контексте запроса");
        }
        this.responseCode = this.responseData = "";
        return basicRequestPost("/api/integrations/v2/accesspoint", payloadData).then(response => {
            this.responseCode = response.status;
            this.responseData = JSON.stringify(response.data, null, 2);
        }).catch(error => {
            console.error(error);
            if ( error.response ) {
                this.responseCode = error.response.status;
                this.responseData = JSON.stringify(error.response.data, null, 2);
            } else {
                this.responseCode = 500;
                this.responseData = "Неизестная ошибка";
            }            
        });
    }

    /**
     * отрисовка компонента
     */
    render() {
        return (
            <PageWrapper>
                <RequestWrapper>
                    <TitleWrapper>
                        Получение адреса перехода в СОК
                    </TitleWrapper>
                    <TextArea
                        rows={20}
                        value={this.payload}
                        id={"context-payload"}
                        mainMinHeight={"300px"}
                        mainMaxHeight={500}
                        onChange={this.onChange}
                        label={"Контекст запроса (ресурс Bundle [type=collection])"}
                    />
                    <Button
                        BGcolor={"dark"}
                        margin={"10px 0"}
                        fontWeight={"normal"}
                        onClick={this.onSubmit}
                        label={"Отправить запрос"}
                    />
                </RequestWrapper>
                { !! this.responseCode && (
                    <ResponseWrapper>
                        <TitleWrapper>
                            Ответ от сервера
                        </TitleWrapper>
                        <Input
                            disabled={true}
					        label={"Код ответа"}
                            value={this.responseCode}
                            isError={this.responseCode != 200}
				        />
                        <TextArea
                            rows={3}
                            disabled={true}
                            mainMinHeight={"300px"}
                            mainMaxHeight={500}
                            id={"context-response"}
                            value={this.responseData}
                            label={"Результат ответа"}
                            isError={this.responseCode != 200}
                        />
                     </ResponseWrapper>
                ) }
            </PageWrapper>
        )
    }
}
