import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ErrorNotificationBigIcon extends PureComponent {
    render() {
        return (
            <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_15234_496)">
                    <path d="M45.0009 67.3109C45.8709 67.3109 46.6009 67.0109 47.1909 66.4259C47.4808 66.1401 47.7089 65.7978 47.8611 65.4202C48.0133 65.0427 48.0865 64.6379 48.0759 64.2309C48.0855 63.8249 48.0119 63.4211 47.8597 63.0445C47.7075 62.6679 47.4799 62.3264 47.1909 62.0409C46.9059 61.7511 46.5646 61.5226 46.188 61.3695C45.8113 61.2164 45.4074 61.1421 45.0009 61.1509C44.5949 61.1414 44.1911 61.215 43.8145 61.3672C43.4379 61.5194 43.0964 61.747 42.811 62.0359C42.522 62.3214 42.2944 62.6629 42.1422 63.0395C41.99 63.4161 41.9164 63.8199 41.9259 64.2259C41.9259 65.1009 42.221 65.8259 42.811 66.4209C43.0962 66.7102 43.4377 66.9379 43.8144 67.0901C44.191 67.2423 44.5948 67.3208 45.0009 67.3109ZM42.5009 50.7709H47.5009V20.7709H42.5009V50.7709ZM45.0159 90.0009C38.9844 90.0386 33.01 88.8318 27.466 86.4559C22.1318 84.1912 17.2797 80.9276 13.1709 76.8409C9.08144 72.7382 5.81441 67.8911 3.54595 62.5609C1.16817 57.0193 -0.0387386 51.046 0.0009479 45.0159C0.0009479 38.8009 1.18095 32.9509 3.54095 27.4659C5.80733 22.1314 9.0726 17.2793 13.1609 13.1709C17.2637 9.08143 22.1108 5.8144 27.4409 3.54594C32.9826 1.1683 38.9559 -0.0386014 44.9859 0.000941146C51.2059 0.000941146 57.056 1.18094 62.5359 3.54094C67.8705 5.80722 72.7226 9.0725 76.8309 13.1609C80.9207 17.2635 84.1877 22.1107 86.4559 27.4409C88.8337 32.9826 90.0406 38.9558 90.0009 44.9859C90.0386 51.0175 88.8318 56.9919 86.4559 62.5359C84.1913 67.8702 80.9277 72.7223 76.841 76.8309C72.7382 80.9204 67.8911 84.1875 62.561 86.4559C57.0193 88.8336 51.046 90.0405 45.0159 90.0009ZM45.0009 85.0009C56.166 85.0009 65.626 81.1259 73.3759 73.3759C81.1259 65.6259 85.0009 56.1659 85.0009 45.0009C85.0009 33.8359 81.1259 24.3759 73.3759 16.6259C65.626 8.87594 56.166 5.00094 45.0009 5.00094C33.8359 5.00094 24.3759 8.87594 16.6259 16.6259C8.87595 24.3759 5.00095 33.8359 5.00095 45.0009C5.00095 56.1659 8.87595 65.6259 16.6259 73.3759C24.3759 81.1259 33.8359 85.0009 45.0009 85.0009Z" fill="#C9D8FA"/>
                </g>
                <defs>
                    <clipPath id="clip0_15234_496">
                        <rect width="90" height="90" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        );
    }
}

ErrorNotificationBigIcon.propTypes = {};

export default ErrorNotificationBigIcon;
