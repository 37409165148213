// MMMNNNNNNNNNNNNWMMWklc::::::::::ckNMMMMM
// MWO;..........'cKMX;   ......'.  ,KMMMMM
// MWl  .::::::;. .kMK;  .dNNNNNNd. ,KMMMMM
// MWo  :KNNNNNO' .kMK,   '::ccc:'  ,KMMMMM
// MWo  ..'''''.  .kMNd,'..    ....'dNMMMMM
// MM0l::::'.  .;:dXMMWWNXk,,lOXXNNNWMMMMMM
// MMMMMMMWXOo:xWMMWWWMMMMNKNWWWMMMMMMMMMMM
// MMMMMXd;,;o0WMNx:,;okKMMNk:,,lKMMMMMMMMM
// MMMMWx.    oWMk.    .cNMO.    :NMMMMMMMM
// MMMMNo.    cXNd.    .:KNx.    ;KWMMMMMMM
// MMWO;. ...  ',. ...   ',.  ..  'dNMMMMMM
// WKo.  .:lc.    .:lc'.    .;lc,   :ONMMMM
// Xc                                'OMMMM
// WKOo. .lkkkkkkkkkkkkkkkkkkkkd'  :k0NMMMM
// MMM0' .OMMMMMMMMMMMMMMMMMMMMX: .dMMMMMMM
// MMM0'  ;llllllllllllllllllllc. .dWMMMMMM
// MMMXc..........................,OMMMMMMM
// MMMMNXKKKKKKKKKKKKKKKKKKKKKKKKKNWMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM

import React, {PureComponent} from 'react';

export default class Consilium extends PureComponent{

    static defaultProps = {
        stroke: "black",
    };

    render(){
        const {stroke} = this.props;
        return(
            <>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13 2C13 1.44772 13.4477 1 14 1H21C21.5523 1 22 1.44772 22 2V6C22 6.55228 21.5523 7 21 7H18.3028L15.5 8.86852V7H14C13.4477 7 13 6.55228 13 6V2ZM20 3H15V5H20V3Z"
                        fill={stroke}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6 10C4.89543 10 4 10.8954 4 12C4 12.4879 4.17467 12.9349 4.46489 13.282C3.71415 13.583 3.15938 14.093 2.77523 14.6533C2.47219 15.0952 2.27303 15.569 2.15255 16H2C1.44772 16 1 16.4477 1 17C1 17.5523 1.44772 18 2 18H3V22C3 22.5523 3.44772 23 4 23H20C20.5523 23 21 22.5523 21 22V18H22C22.5523 18 23 17.5523 23 17C23 16.4477 22.5523 16 22 16H21.8475C21.727 15.5689 21.5278 15.0952 21.2247 14.6532C20.8406 14.093 20.2858 13.5831 19.5351 13.2821C19.8253 12.9349 20 12.4879 20 12C20 10.8954 19.1046 10 18 10C16.8954 10 16 10.8954 16 12C16 12.4879 16.1747 12.9349 16.4649 13.282C15.8496 13.5287 15.366 13.9158 15 14.3558C14.634 13.9158 14.1504 13.5287 13.5351 13.2821C13.8253 12.9349 14 12.4879 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 12.4879 10.1747 12.9349 10.4649 13.282C9.84961 13.5287 9.36596 13.9158 8.99999 14.3558C8.63401 13.9158 8.15036 13.5288 7.5351 13.2821C7.82532 12.9349 8 12.4879 8 12C8 10.8954 7.10457 10 6 10ZM19 18V21H5V18H19ZM19.5753 15.7843C19.6238 15.855 19.6673 15.9274 19.7062 16H16.2939C16.3327 15.9274 16.3762 15.855 16.4247 15.7842C16.7143 15.3619 17.1732 15 18 15C18.8267 15 19.2857 15.3619 19.5753 15.7843ZM13.5753 15.7843C13.6238 15.855 13.6673 15.9274 13.7062 16H10.2939C10.3327 15.9274 10.3762 15.855 10.4247 15.7842C10.7143 15.3619 11.1732 15 12 15C12.8267 15 13.2857 15.3619 13.5753 15.7843ZM7.57528 15.7843C7.6238 15.855 7.66728 15.9274 7.70615 16H4.29386C4.33273 15.9274 4.37621 15.855 4.42473 15.7842C4.71431 15.3619 5.17323 15 6 15C6.82668 15 7.28566 15.3619 7.57528 15.7843Z"
                        fill={stroke}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 2C10.5523 2 11 2.44772 11 3V7C11 7.55228 10.5523 8 10 8H8.5V9.86852L5.69722 8H3C2.44772 8 2 7.55228 2 7V3C2 2.44772 2.44772 2 3 2H10ZM9 6V4H4V6H9Z"
                        fill={stroke}
                    />
                </svg>
            </>
        )
    }
}