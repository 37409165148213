import {setLineHeight} from "GUI_MAIN/Utils/setLineHeight";
import styled from "styled-components";
import styledMap from "styled-map";

const setButtonBG = (color, theme) => {
    switch (color) {
        case 'red':
            return theme.redButtonBG;
        case 'orange':
            return theme.orangeButtonColor;
        case 'green':
            return theme.greenButtonBG;
        case 'grey':
            return theme.greyButtonBG;
        case 'dark':
            return theme.darkButtonBG;
        case 'disabledBG':
            return theme.disabledButtonBG;
        case 'white':
            return theme.whiteColor;
        case 'light-blue':
            return theme.mainLightBlueColor;
        default:
            return theme.darkButtonBG;
    }
};

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => props.padding ? props.padding : '8px 15px'};
  margin: ${props => props.margin || '0'};
  width: ${props => props.round ? props.width || '181px' : props.width || ''};
  min-width: ${props => props.minWidthAuto ? 'auto' : 'auto'};
  border-radius: 4px;
  font-size: ${props => props.fontSize ? props.fontSize : props.theme.mediumFontSize};
  line-height: ${props => setLineHeight(props.theme.mediumFontSize, 1.14)};
  font-family: ${props => styledMap('fontWeight', {
    normal: `${props.theme.OpenSansRegular}`,
    default: `${props.theme.OpenSansSemiBold}`,
  })};
  letter-spacing: ${props => props.letterSpacing || '1'};
  white-space: nowrap;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  position: relative;
  user-select: none;
  min-height: 32px;
`;

const StandartButton = styled(StyledButton)`
  background: ${props => props.disabled ? props.theme.disabledButtonBG : setButtonBG(props.BGcolor, props.theme)};
  color: ${props => props.textColor ? props.textColor : props.theme.whiteColor};
  border: none;

  &:hover{
    background: ${props => props.disabled ?
                           props.theme.disabledButtonBG : 
                           `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${props.BGcolorHover ? setButtonBG(props.BGcolorHover, props.theme) : setButtonBG(props.BGcolor, props.theme)}`
    };

    &:active, &:focus{
      background: ${props => props.disabled ?
                             props.theme.disabledButtonBG : 
                             `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${props.BGcolorHover ? setButtonBG(props.BGcolorHover, props.theme) : setButtonBG(props.BGcolor, props.theme)}` 
    };
`;

const OutlinedButton = styled(StyledButton)`
  background: ${props => props.mustBeActive ? 'rgba(27, 117, 187, 0.2)' : props.theme.whiteColor};
  border-radius: 4px;
  border: 1px solid ${props => props.disabled ? props.theme.outlinedButtonBorderDisabled : props.theme.outlinedButtonBorder};
  color: ${props => props.disabled ? props.theme.outlinedButtonBorderDisabled : props.theme.outlinedButtonBorder};
  padding: ${props => props.padding ? props.padding : '7px 15px'};

  &:hover {
    background: ${props => !props.disabled && 'rgba(27, 117, 187, 0.1);'};
  };

  &:active, &:focus{
    background: ${props => !props.disabled && 'rgba(27, 117, 187, 0.2);'};
  };
`;


const IconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.size};
  height: ${props => props.size};
  border: ${props => props.iconBorder ? '1px solid #D3D4D8' : 'none'};
  cursor: pointer;
  background-color: ${props => props.BGcolor || 'transparent'};
  outline: none;
  pointer-events: ${props => {if(props.disabled) return 'none'}};
  padding: ${props => props.padding ? props.padding : ''};
  border-radius: ${props => props.borderRadius ? props.borderRadius : '0'};
  margin: ${props => props.margin ? props.margin : ''};
`;

const TextButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: ${ props => props.theme.mediumFontSize };
  color: ${ props => props.theme.mainDarkBlueColor };
  line-height: 20px;
  gap: 4px;
  ${ props => props.margin && `margin: ${ props.margin }` };
  width: fit-content;

  :hover {
    ${ props => !props.disabled && "color: #54A3E0" };

    svg * {
      ${ props => !props.disabled && "fill: #54A3E0" };
    }
  }

  svg {
      width: 20px;
      height: 20px;

      * {
        fill: ${ props => props.theme.mainDarkBlueColor };
      }
  }
`;

export { StandartButton, IconButton, OutlinedButton, TextButton};
