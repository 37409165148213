import moment from "moment";
import { getFormat } from "Locale/datetime";
import _get from "lodash/get";
import { observable } from "mobx";
import {NOTIFICATION_TYPES} from "./Filters";

/**
 * обертка над ресурсом AppNotification
 */
export class Notification {

    /**
     * ресурс AppNotification
     */
    @observable resource = null;

    /**
     * resource - ресурс AppNotification
     */
    constructor(resource) {
        this.resource = resource;
    }

    /**
     * идентификатор ресурса
     */
    get id() {
        return _get(this.resource, "id");
    }

    /**
     * статус ресурса (read/unread)
     */
    get status() {
        return _get(this.resource, "status");
    }

    /**
     * установка статуса
     */
    setStatus = (value) => {
        this.resource.status = value;
    }

    /**
     * не прочитано?
     */
    get isUnread() {
        return this.status == "unread";
    }

    /**
     * код типа
     */
    get typeCode() {
        return _get(this.resource, "type.coding.0.code");
    }

    /**
     * название типа
     */
    get typeName() {
        const type = NOTIFICATION_TYPES.find(type => type.value.split(',').indexOf(this.typeCode) > -1);
        return _get(type, "label", "")
    }

    /**
     * название типа
     */
    get code() {
        const type = NOTIFICATION_TYPES.find(type => type.value.split(',').indexOf(this.typeCode) > -1);
        return _get(type, "type", "")
    }

    /**
     * название
     */
    get title() {
        return _get(this.resource, "payload.title");
    }

    /**
     * описание
     */
    get text() {
        return _get(this.resource, "payload.text");
    }

    /**
     * название действия
     */
    get actionName() {
        return _get(this.resource, "context.action.0.display");
    }

    /**
     * переход по ссылке
     */
    get actionLink() {
        return `${window.location.origin}${_get(this.resource, "context.action.0.clients.web")}`;
    }

    /**
     * извлекаем путь из ссылки
     */
    get actionPath() {
        try {
            const url = new URL(this.actionLink);
            return url.pathname + (url.search ? url.search : "");
        } catch (e) {
            return "/";
        }
    }

    /**
     * время создания
     */
    get dateTime() {
        return moment(_get(this.resource, "meta.createdAt")).format(getFormat("DT000003"));
    }
}
