import { inject } from 'mobx-react';
import React, {PureComponent} from 'react';
import styled from 'styled-components';
import ArrowBorder from "GUI_MAIN/icons/ArrowBorder";
import MagnifyingGlass from "GUI_MAIN/icons/MagnifyingGlass";
import {Paths} from "../../../Routs/Paths";
import { Link } from 'react-router-dom';
import Button from "GUI_MAIN/Elements/Button";
import IconButton from "GUI_MAIN/Elements/IconButton";
import {resetPatientsHideDataSettings} from "GUI_MAIN/Utils/localStorageSettings";
import {
    ACCESS_RIGHT_ACTION,
    ACCESS_RIGHT_OBJECT,
    ACCESS_RIGHT_INCLUDES,
} from "MODEL_STORE/DataSource/Constants/user_access_rights";
import {ContextualMenuItem} from "../../../Elements/ContextualMenu/ContextualMenuItem";
import _get from "lodash/get";
import {StyledReactTooltip, overPos} from "GUI_MAIN/Elements/StyledReactTooltip";
import {EOLocale, Translator} from "eo-locale";
import {clearFiltersInLocalStorage} from "../clearFiltersInLocalStorage";
import {HeaderStore} from "MODEL_STORE/DataSource/Stores/HeaderStore";
import {PagePaginationStore} from "MODEL_STORE/DataSource/Stores/PagePaginationStore";
import {SearchFilterStore} from "MODEL_STORE/DataSource/Stores/SearchFilterStore";
import { PopupBasic } from 'GUI_MAIN/Elements/Popups/PopupBasic';
import { ImageScheme } from './ImageScheme';
import {locales} from "Locale";
import { removeProtocolResourcesFromLocalStorage } from "GUI_TB/Components/ProtocolsV2/removeProtocolResourcesFromLocalStorage";
import {
  TransformWrapper,
  TransformComponent,
} from "react-zoom-pan-pinch";


const UserPanelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center`;

const UserIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;

  svg{
    width: 24px;
    height: auto;
  }`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;
`;

const UserName = styled.div`
  font-family: ${props => !props.subText ? props.theme.OpenSansSemiBold : props.theme.OpenSansRegular};
  font-size: ${props => !props.smallText ? props.theme.mediumFontSize : props.theme.normalFontSize};
  color: ${props => !props.subText ? props.theme.whiteColor : "#D3D4D8"};
  max-width: ${props => !props.fullWidth ? "129px" : "100%"};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UserNameTooltip = styled.div`
  font-family: ${props => !props.subText ? props.theme.OpenSansSemiBold : props.theme.OpenSansRegular};
  font-size: ${props => !props.smallText ? props.theme.mediumFontSize : props.theme.normalFontSize};
  color: ${props => !props.subText ? props.theme.whiteColor : "#D3D4D8"};
`;

const WarningUserName = styled.span`
  color: ${props => props.theme.warningColor};
  border-bottom: 1px dashed ${props => props.theme.warningColor};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: 70px;
  padding: 20px;
  justify-content: ${props => props.position};
  display: flex;
`;

const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;

  svg{
    width: 14px;
  }
`;

const ContextMenuWrapper = styled.div`
  background-color: ${props => props.theme.whiteColor};
  min-width: 200px;
  max-width: 250px;
  z-index: 1000;
  font-size: ${props => props.theme.mediumFontSize};
  font-family: ${props => props.theme.OpenSansRegular};
  cursor: pointer;
  padding: 10px 0;
`;

const ContextMenuItemExit = styled(ContextualMenuItem)`
  color: ${props => props.theme.mainRedColor};
`;

const ContextualMenuItemHover = styled.div`
  cursor: pointer;
    &:hover{
      color: ${props => props.theme.mainDarkBlueColor};
    }
`;

const ImageWrapper = styled.div`
    cursor: all-scroll;
`;

const ImageContainer = styled.div`
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    height: 340px
    padding: 10px 2px;
`;

@inject('ContextualMenuStore')
@inject('AuthStore')
export default class UserPanel extends PureComponent{

    state={
        isContextMenuOpen: false,
        isProcessModalOpen: false,
        enlarged: false,
    }

    /**
     * виден ли аватар пользователя?
     */
    get isUserAvatarVisible() {
        const {AuthStore} = this.props;
        if (!AuthStore.currentUserData.isRoot())
            return AuthStore.currentUserData.userHasAccessRights(ACCESS_RIGHT_OBJECT.HEADER_AVATAR, ACCESS_RIGHT_ACTION.VIEW, ACCESS_RIGHT_INCLUDES.NULL);
        return true;
    }

    /**
     * видна ли панель с действиями (настройки, выход)?
     */
    get isActionPanelVisible() {
        const {AuthStore} = this.props;
        if (!AuthStore.currentUserData.isRoot())
            return AuthStore.currentUserData.userHasAccessRights(ACCESS_RIGHT_OBJECT.HEADER_ACTIONS, ACCESS_RIGHT_ACTION.VIEW, ACCESS_RIGHT_INCLUDES.NULL);
        return true;
    }

    /**
     *
     */
    doLogout = () => {
        const {ContextualMenuStore, AuthStore} = this.props;
        ContextualMenuStore.hideContextualMenu();
        AuthStore.logout();
        PagePaginationStore.clearAll();
        SearchFilterStore.clearAll();
        // сброс настроек для презентаций (скрытие/отображение имени пациента)
        resetPatientsHideDataSettings();
        clearFiltersInLocalStorage();
        removeProtocolResourcesFromLocalStorage();
    };

    /**
     *
     */
    displayName = (fullName) => {
        const {AuthStore} = this.props;
        const translator = new Translator(HeaderStore.locale, locales);
        if (AuthStore.currentUserData.person) {
            const displayName = AuthStore.currentUserData.person.displayName;
            const displayFullName = AuthStore.currentUserData.person.displayFullName;


            if (displayName) {
                if(!fullName) {
                    return displayName;
                } else {
                    return displayFullName;
                }
            }

            return (
                <WarningUserName title={translator.translate('TT020700')}>
                    <EOLocale.Text id={'TT020700'} />
                </WarningUserName>
            );
        }

        if (AuthStore.currentUserData.user) {
            return (
                <WarningUserName title={translator.translate('TT020710')}>
                    { AuthStore.currentUserData.user.userName || AuthStore.currentUserData.user.id }
                </WarningUserName>
            );
        }

        return (
            <WarningUserName title="Ты кто???">
                ???
            </WarningUserName>
        );
    };

    organizationName = () => {
        const {AuthStore} = this.props;
        if (AuthStore.currentUserData.organizations) {
            if (AuthStore.currentUserData.active_organization_id) {
                const organization = AuthStore.currentUserData.organizations.find(i => i.id === AuthStore.currentUserData.active_organization_id);
                if (organization) {
                    return organization.displayName;
                }
            }
        }

        return "";
    };

    roleName = () => {
        const {AuthStore} = this.props;
        if (AuthStore.currentUserData.roles) {
            if (AuthStore.currentUserData.active_role_id) {
                const role = AuthStore.currentUserData.roles.find(i => i.id === AuthStore.currentUserData.active_role_id);
                if (role) {
                    return _get(role, "code.0.coding.0.display") || _get(role, "code.0.text");
                }
            }
            else {
                return _get(AuthStore.currentUserData.roles, "0.code.0.coding.0.display", "") ||
                       _get(AuthStore.currentUserData.roles, "0.code.0.text", "");
            }
        }

        return "";
    };

    changeAuthRole = () => {
        const {ContextualMenuStore, AuthStore} = this.props;
        ContextualMenuStore.hideContextualMenu();
        this.setState({isContextMenuOpen: false});
        AuthStore.openPageChangeAuthRole();
    };

    onChangeLang = lang => {
        const {ContextualMenuStore} = this.props;
        HeaderStore.setLocale(lang);
        ContextualMenuStore.hideContextualMenu();
        this.setState({isContextMenuOpen: false});
    };

    showProcessModal = () => {
        this.setState({isProcessModalOpen: true});
    }

    contextMenu = (e) => {
        const {ContextualMenuStore, AuthStore} = this.props;
        if(this.timer)
            clearTimeout(this.timer);

        this.setState({isContextMenuOpen: true});

        ContextualMenuStore.showContextualMenu(
            true,
                    e,
                    <div onMouseOver={()=> clearTimeout(this.timer)}
                         onMouseOut={ () =>
                             this.timer = setTimeout(( ) => {
                                ContextualMenuStore.hideContextualMenu();
                                this.setState({isContextMenuOpen: false})
                            }, 500)}>
                        <ContextMenuWrapper>
                            <ContextualMenuItem onClick={() => window.open("https://www.gosuslugi.ru/pgu/eds", '_blank')} key={8}>
                                <ContextualMenuItemHover  key={81}>
                                    <EOLocale.Text id={"SS9040780"}/>
                                </ContextualMenuItemHover>
                            </ContextualMenuItem>
                            {
                                !AuthStore.currentUserData.isRoot() &&
                                <ContextualMenuItem onClick={this.changeAuthRole} key={3}>
                                    <EOLocale.Text id={"SS902316"}/>
                                </ContextualMenuItem>
                            }
                            <ContextualMenuItem onClick={this.showProcessModal} key={2}>
                                Регламент консилиума
                            </ContextualMenuItem>
                            <ContextMenuItemExit onClick={this.doLogout} key={4}>
                                <EOLocale.Text id={"SS001032"}/>
                            </ContextMenuItemExit>
                        </ContextMenuWrapper>
                    </div>
                    ,
               'bottom-left',
                0,
    false
        );

        this.timer = setTimeout(( ) => {
            ContextualMenuStore.hideContextualMenu();
            this.setState({isContextMenuOpen: false})
        }, 2000)
    }

    /**
     *
     */
    render(){

        return(
            <UserPanelWrapper>
                {
                    this.state.isProcessModalOpen &&
                    <PopupBasic
                      padding={'0 0 100px 0'}
                      title={'Регламент проведения Онлайн-консилиума по профилю "Онкология"'}
                      onClose={() => {
                          this.setState({isProcessModalOpen: false});
                          this.setState({enlarged: false};
                      }}
                      overflowHidden={true}
                    >
                        <ButtonWrapper position={'center'}>
                            <Button
                                label={''}
                                addIcon={<MagnifyingGlass fill={'#ffffff'}/>}
                                iconPlacement={'center'}
                                onClick={() => {
                                    this.setState({enlarged: !this.state.enlarged})
                                }}
                                width={'100px'}
                            />
                        </ButtonWrapper>
                        <ImageContainer>
                        {
                            this.state.enlarged ?
                            <ImageWrapper>
                                <TransformWrapper
                                  initialScale={4}
                                  initialPositionX={-1350}
                                  initialPositionY={-450}
                                >
                                    <TransformComponent>
                                      <ImageScheme width={'795px'} height={'310px'}/>
                                    </TransformComponent>
                                </TransformWrapper>
                            </ImageWrapper>
                            :
                            <ImageScheme width={'795px'} height={'310px'}/>
                        }
                        </ImageContainer>
                    </PopupBasic>
                }
                <UserInfo data-tip={""} data-for={"user-info-tooltip"}>
                    <UserName>{ this.displayName() }</UserName>
                    <UserName subText smallText>{ this.organizationName() }</UserName>
                    <UserName subText smallText>{ this.roleName() }</UserName>
                </UserInfo>
                <StyledReactTooltip
                    id={"user-info-tooltip"}
                    className={'light light-simple tooltip-menu user-info_tooltip'}
                    type="light"
                    place='bottom'
                    effect="solid"
                    overridePosition={overPos}
                >
                    <UserInfo>
                        <UserNameTooltip fullWidth smallText>{ this.displayName(true) }</UserNameTooltip>
                        <UserNameTooltip fullWidth subText smallText>{ this.organizationName() }</UserNameTooltip>
                        <UserNameTooltip fullWidth subText smallText>{ this.roleName() }</UserNameTooltip>
                    </UserInfo>
                </StyledReactTooltip>
                { this.isActionPanelVisible &&
                    <IconWrapper onClick={this.contextMenu}>
                        <ArrowBorder color={'white'} rotateArrow={this.state.isContextMenuOpen ? 180 : 0}/>
                    </IconWrapper>
                }
            </UserPanelWrapper>
        )
    }
}
