// ..WWNWW..........................WNNWW..
// WNXXKXNWW......................WWNXKKXNW
// K0OxxkOKXNW..................WNXKOkxxO0K
// OOxdoodkOKXNW..............WNXKOkdoodxOO
// 0OkdoooodkOKXNW..........WNXKOkdoooodkO0
// NXKOxdoooodxOKXNW......WNXKOxdoooodxOKXN
// .WNXKOxdoooodkOKXWWWWWWXKOkdoooodxOKXNW.
// ...WNXKOkdoooodkOKXXXXKOkdoooodkOKXNW...
// .....WNXKOxdooooxkkkkkkxoooodxOKXNW.....
// .......WNX0OxdoooooooooooodxO0XNW.......
// .........WNXKOxdoolllloodxOKXNW.........
// ...........WNX0OxdoooodxO0XNW...........
// .............WNX0OkkkkO0XNW.............
// ..............WNX00OO00XNW..............


import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${props => props.rotateArrow ? 'rotate(' + props.rotateArrow + 'deg)' : 'rotate(0)'};
  width: 100%;
  
  svg{
    width: 100%;
  }
`;


export default class ArrowBorder extends PureComponent{

    static propTypes={
        rotateArrow: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        color: PropTypes.string,
    }

    static defaultProps={
        color: 'black',
    }

    render(){

        const {rotateArrow, color} = this.props;

        return(
            <ArrowWrapper rotateArrow={rotateArrow}>
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.59 0.589844L6 5.16984L1.41 0.589844L0 1.99984L6 7.99984L12 1.99984L10.59 0.589844Z" fill={color}/>
                </svg>
            </ArrowWrapper>
        )
    }
}

