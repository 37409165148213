import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {setLineHeight} from "GUI_MAIN/Utils/setLineHeight";

const day = '.react-datepicker__day';
const prefix = '.react-datepicker';

export const SingleCalendarContainer = styled.div`
  position: relative;
  display: flex;
  padding: 16px 32px;

  .react-datepicker{
    font-size: ${(props) => props.theme.normalFontSize};
    color: ${(props) => props.theme.darkMainTextColor};
    font-family: ${(props) => props.theme.OpenSansRegular};

    &-wrapper{
      width: 100%;
    }

    &-popper{
      left: auto;
      right: 0;
    }

    &__input-container{
      width: 100%;
      height: 100%;
    }

    &__header{
      background-color: ${props => props.theme.whiteColor};
      padding-top: 0;
      border-bottom: none;

      &__dropdown {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        justify-content: center;
        padding: 0 13px;
      }
    }

    &__month,
    &__year {

      &-dropdown {
        width: 100%;
        left: 0;
        top: 40px;
        border-radius: 4px;
        border: 1px solid #ffffff;
        background-color: #ffffff;
        box-shadow: 0px 3px 18px 0px #00000024;

        & + div {
          border: 1px solid #1B75BB;
        }
      }

      &-read-view {
        visibility: visible !important;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        font-family: ${props => props.theme.OpenSansSemiBold};
        color: ${props => props.theme.darkMainTextColor};
        font-size: ${props => props.theme.mediumFontSize};
        padding: 7px 8px 7px 10px;
        border-radius: 4px;
        line-height: 1.3;
        text-transform: capitalize;

        &--down-arrow{
          top: 8px;
          margin-left: 0;
          width: 12px;
          height: 7px;
          border: 0;
          opacity: .6;

          &:hover {
            opacity: .8;
          }

          &:after,
          &:before {
            content: '';
            position: absolute;
            background-color: #999999;
            width: 7px;
            height: 2px;
          }

          &:after{
            transform: rotate(40deg);
            left: 0;
            top: 0;
          }

          &:before {
            transform: rotate(-40deg);
            right: 0;
            top: 0;
            bottom: 0;
            border: 0;
            z-index: 1;
            left: unset;
          }
        }
      }
    }

    &__month{
      margin: 0;

      &-dropdown-container {
        height: 36px;
        width: 120px;
        margin: 0;
        position: relative;

        select {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          flex-direction: row-reverse;
          font-family: ${props => props.theme.OpenSansRegular};
          color: ${props => props.theme.darkMainTextColor};
          font-size: ${props => props.theme.mediumFontSize};
          padding: 0 8px 0 10px;
          border: 1px solid #A1A4B1;
          border-radius: 4px;
          outline: none;

          option {
            font-family: ${props => props.theme.OpenSansRegular};
            color: ${props => props.theme.darkMainTextColor};
            font-size: ${props => props.theme.mediumFontSize};
            height: 32px;

            &:hover {
              background-color: ${props => props.theme.mainHoverBlock} !important;
            }

            &:checked {
              background-color: ${props => props.theme.mainActiveBlock} !important;
            }
          }
        }
      }



      &-option {
        text-align: left;
        line-height: 20px;
        padding: 8px 10px;
        font-family: ${props => props.theme.OpenSansRegular};
        color: ${props => props.theme.darkMainTextColor};
        font-size: ${props => props.theme.mediumFontSize};
        text-transform: capitalize;

        &:hover {
          background-color: ${props => props.theme.mainHoverBlock};
          font-family: ${props => props.theme.OpenSansSemiBold};
        }

        &--selected_month {
          background-color: ${props => props.theme.mainActiveBlock} !important;
          font-family: ${props => props.theme.OpenSansSemiBold};

          span {
            display: none;
          }
        }
      }

      &--selecting-range {
        .react-datepicker__day--in-range {
          &.react-datepicker__day--range-start,
          &.react-datepicker__day--range-end{
            color: #ffffff;
          }
        }
      }
    }

    &__month-container{
      margin-right: 12px;

      &:last-child{
        margin-right: 0;
      }

      &:nth-child(2n){
        ${prefix}__current-month{
          text-align: left;
        }
      }

      .current-day {
        font-style: italic;
        font-family: ${(props) => props.theme.OpenSansBold} !important;
      }
    }

    &__current-month{
      margin-top: 7px;
      padding-bottom: 28px;
      font-family: ${(props) => props.theme.OpenSansRegular};
      font-weight: normal;
      text-transform: capitalize;
      font-size: ${(props) => props.theme.normalFontSize};
      display: none;
    }

    &__year {

      &-dropdown-container {
        height: 36px;
        width: 71px;
        margin: 0;
        position: relative;

        select {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          flex-direction: row-reverse;
          font-family: ${props => props.theme.OpenSansRegular};
          color: ${props => props.theme.darkMainTextColor};
          font-size: ${props => props.theme.mediumFontSize};
          padding: 0 8px 0 10px;
          border: 1px solid #A1A4B1;
          border-radius: 4px;
          outline: none;

          option {
            font-family: ${props => props.theme.OpenSansRegular};
            color: ${props => props.theme.darkMainTextColor};
            font-size: ${props => props.theme.mediumFontSize};
            height: 32px;

            &:hover{
              background-color: ${props => props.theme.mainHoverBlock} !important;
            }

            &:checked {
              background-color: ${props => props.theme.mainActiveBlock} !important;
            }
          }
        }
      }

      &-dropdown {

        &--scrollable {
          height: 300px;

          &::-webkit-scrollbar-track {
            background-color: #ffffff;
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 3px;
          }

          &::-webkit-scrollbar {
            width: 6px;
          }
        }
      }

      &-option {
        text-align: left;
        line-height: 20px;
        padding: 8px 10px;
        font-family: ${props => props.theme.OpenSansRegular};
        color: ${props => props.theme.darkMainTextColor};
        font-size: ${props => props.theme.mediumFontSize};

        &:hover {
          background-color: ${props => props.theme.mainHoverBlock};
          font-family: ${props => props.theme.OpenSansSemiBold};
        }

        &--selected_year {
          background-color: ${props => props.theme.mainActiveBlock} !important;
          font-family: ${props => props.theme.OpenSansSemiBold};

          span {
            display: none;
          }
        }

        &:last-of-type{
          display: none;
        }
      }
    }

    &__week{
      display: flex;
      gap: 0;
      line-height: 1;
      margin-bottom: 2px;
    }

    &__day{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin: 0;
      padding: 3px 4px;
      border-radius: 0;
      line-height: ${props => setLineHeight(props.theme.normalFontSize, 1.45)};
      font-size: ${props => props.theme.normalFontSize};
      color: ${(props) => props.theme.darkMainTextColor};
      font-family: ${(props) => props.theme.OpenSansRegular};
      outline: none;

      &:hover{
        background-color: #E7F5FD;
        color: #232735 !important;
      }

      &-name{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        gap: 2px;
        line-height: ${props => setLineHeight(props.theme.normalFontSize, 1.45)};
        text-transform: lowercase;
        font-size: ${(props) => props.theme.normalFontSize};
      }

      &--outside-month{
        color: ${props => props.theme.outsideMonthColor};

        &:hover{
          color: ${props => props.theme.outsideMonthColor};
        }
      }

      &--today{
        font-family: ${(props) => props.theme.OpenSansRegular} !important;
        font-weight: normal;
        line-height: ${props => setLineHeight(props.theme.normalFontSize, 1.45)};
        border-radius: 4px;
        border: 1px solid ${props => props.theme.datepickerPopperBorder};
          // background-color: ${props => props.theme.datePickerMenuItemBGHover};
        //color: #1B75BB !important;

        &:hover{
          color: ${props => props.theme.whiteColor};
          font-weight: normal;
          border-radius: 4px;
          border: 1px solid #E7F5FD;
        }
      }

      &--in-range{
        background-color: ${props => props.theme.datePickerMenuItemBGHover};
        color: ${(props) => props.theme.datePickerNormalFontColor};

        &:hover{
          color: ${props => props.theme.whiteColor};
        }
      }

      &--range-start{
        border-radius: 10px 0 0 10px !important;
        background-color: ${props => props.theme.periodEdgeDaysBG} !important;
        color: ${props => props.theme.whiteColor};

        &:hover{
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          color: ${props => props.theme.whiteColor} !important;
        }
      }

      &--range-start.react-datepicker__day--in-range.react-datepicker__day--outside-month{
        background-color: #1B75BB !important;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
      }

      &--range-start.react-datepicker__day--in-range{
        color:  ${props => props.theme.whiteColor} !important;
      }

      &--range-end {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: ${props => props.theme.periodEdgeDaysBG} !important;
        color: ${props => props.theme.whiteColor} !important;

        &:hover{
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          color: ${props => props.theme.whiteColor};
        }
      }

      &--keyboard-selected.react-datepicker__day--range-end.react-datepicker__day--in-range.react-datepicker__day--today.current-day{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &--keyboard-selected.react-datepicker__day--in-range{
        color: #505565;
        background-color: #C1DEF3;
      }

      &--selected{
        outline: none !important;
        color: #ffffff !important;
      }

      &--keyboard-selected.react-datepicker__day--today{
        outline: none;
        color: #ffffff !important;
      }

      &--disabled {
        opacity: .3;
        pointer-events: none;
      }

      &--selected {
        border-radius: 3px;
      }

      &.current-day {
        font-style: normal;
      }
    }

    &__day-names{
      display: flex;
      justify-content: space-between;
      font-family: ${(props) => props.theme.OpenSansSemiBold};
      text-transform: uppercase;
    }
    
    &__day-name{
      gap: 0;
      margin: 0;
    }

    &__navigation{
      width: 12px;
      height: 7px;
      outline: none;
      offcet: none;
      top: 28px;

      &--next{
        display: flex;
        justify-content: center;
        align-items: center;
        right: 32px;
        left: auto;
        border-left-color: transparent;
        border-radius: 2px;
        border-width: 0;
        color: transparent;
        opacity: .6;
        transform: rotate(270deg);

        &:hover{
          border-left-color: transparent;
        }

        &:before{
          position: absolute;
          display: block;
          content: '';
          transform: rotate(-40deg);
          right: 0;
          top: 2px;
          bottom: 0;
          border: 0;
          z-index: 1;
          left: unset;
          background-color: #999999;
          width: 7px;
          height: 2px;
        }

        &:after{
          position: absolute;
          display: block;
          content: '';
          transform: rotate(40deg);
          left: 0;
          top: 2px;
          width: 7px;
          height: 2px;
          background-color: #999999;
        }
      }

      &--previous{
        display: flex;
        justify-content: center;
        align-items: center;
        left: auto;
        border-left-color: transparent;
        border-radius: 2px;
        border-width: 0;
        color: transparent;
        opacity: .6;
        transform: rotate(90deg);

        &:hover{
          border-right-color: transparent;
        }

        &:before{
          position: absolute;
          display: block;
          content: '';
          transform: rotate(-40deg);
          right: 0;
          top: 2px;
          bottom: 0;
          border: 0;
          z-index: 1;
          left: unset;
          background-color: #999999;
          width: 7px;
          height: 2px;
        }

        &:after{
          position: absolute;
          display: block;
          content: '';
          transform: rotate(40deg);
          left: 0;
          top: 2px;
          width: 7px;
          height: 2px;
          background-color: #999999;
        }
      }
    }
  }

  ${day}--in-range${day}--outside-month{
    color: ${props => props.theme.outsideMonthColor};
    border-radius: 0 !important;


    &:hover{
      color: ${props => props.theme.whiteColor};
    }
  }

  ${day}--today${day}--range-start{
    &:hover{
      border-radius: 10px 0 0 10px;
    }
  }

  ${day}--in-range${day}--today{
    border-radius: 0;
    vertical-align: top;
  }

  ${day}--in-range${day}--today${day}--range-start${day}--outside-month{
    background-color: ${props => props.theme.datePickerMenuItemBGHover} !important;
  }

  ${day}--in-range${day}--today${day}--range-end{
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  ${day}--in-range${day}--outside-month{
    background-color: ${props => props.theme.datePickerMenuItemBGHover} !important;
  }

  ${day}--range-end${day}--in-range${day}--outside-month{
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    background-color: ${props => props.theme.periodEdgeDaysBG} !important;
    color: ${props => props.theme.whiteColor} !important;
  }

  ${day}--range-start${day}--range-end${day}--in-range{
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
`;
