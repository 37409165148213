import React from 'react';



export function BadFilePane() {


    return (
        <span>Bad image</span>
    );


}
