import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class SelectClear extends PureComponent{
    static propTypes = {
        stroke: PropTypes.string,
    }
    static defaultProps = {
        stroke: "#A1A4B1"
    }
    render(){
        const {stroke} = this.props
        return(
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
              <path d="M16.5 5.20857L15.2914 4L10.5 8.79143L5.70857 4L4.5 5.20857L9.29143 10L4.5 14.7914L5.70857 16L10.5 11.2086L15.2914 16L16.5 14.7914L11.7086 10L16.5 5.20857Z" fill={stroke}/>
          </svg>
        )
    }
}
