import React from 'react';
import styled from "styled-components";
import {AuthWrapper} from "src/Auth/Components/AuthWrapper";
import Input from "src/GUI_MAIN/Elements/Input";
import Button from "src/GUI_MAIN/Elements/Button";
import _get from "lodash/get";
import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {SnilsInput} from "../../Elements/ResourceControls/MaskedInput";
import {ToasterStore} from "MODEL_STORE/DataSource/Stores/ToasterStore";
import {SnilsValidation} from "MODEL_STORE/DataSource/Validation/SnilsValidation";
import {EMAIL_REGEX} from "MODEL_STORE/DataSource/Constants/regex";
import {Bundle} from "MODEL_STORE/DataSource/Bundle";
import {CustomVersion} from "../../../MODEL_STORE/DataSource/Stores/CustomVersion";
import { SNILS_IDENTIFIER_SYSTEM } from "MODEL_STORE/DataSource/Constants/systems";
import { Translator } from '@eo-locale/core';
import { HeaderStore } from 'MODEL_STORE/DataSource/Stores/HeaderStore';
import {locales} from "Locale";

const Info = styled.div`
  margin-bottom: 16px;
`;

const InfoText = styled.p`
  margin-bottom: 0;
  line-height: 1.2;
  font-family: ${props => props.theme.OpenSansRegular};
  font-size: ${props => !props.sub ? props.theme.bigFontSize : props.theme.footNoteFontSize};
  color: ${props => !props.sub ? props.theme.darkMainTextColor : props.theme.lightenMainTextColor};
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: ${props => props.justifyContent};
`;

const FillPageContainer = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    z-index: 99999;
    left: 0;
`;

@withRouter
@inject('AuthStore')
@observer
export class ConfirmSnilsOrEmail extends React.Component{
    @observable snils = "";
    @observable email = "";
    @observable validation = {
        snilsError: false,
        emailError: false,
    };

    componentDidMount() {
        const {AuthStore} = this.props;
        const identifiers = _get(AuthStore.currentUserData.person, ['identifier'], []);
        this.snils = _get(identifiers.find(identifier => identifier.system == SNILS_IDENTIFIER_SYSTEM), 'value', "");

        const activeRole = AuthStore.currentUserData.roles
            .find(i => i.id === AuthStore.currentUserData.active_role_id);
        const telecom = _get(activeRole, '_data.telecom', []);
        this.email = telecom.find(i => i.system === "email", "");
    }

    saveEmail = bundle => {
        const {AuthStore} = this.props;

        const translator = new Translator(HeaderStore.locale, locales);

        const activeRole = AuthStore.currentUserData.roles
            .find(i => i.id === AuthStore.currentUserData.active_role_id);
        const telecom = _get(activeRole, '_data.telecom', []);
        const email = telecom.find(i => i.system === "email");
        if (!!!email) {
            if (!!this.email) {
                this.checkValidation("emailError");
                if (!this.validation.emailError) {
                    const activeRole = AuthStore.currentUserData.roles
                        .find(i => i.id === AuthStore.currentUserData.active_role_id);
                    if (activeRole) {
                        activeRole._arrayAdd('telecom', {system: "email", value: this.email});
                        bundle.add(activeRole);
                    } else {
                        ToasterStore.error(translator.translate('TT010530'));
                        return true;
                    }
                } else
                    return true;
            }
        }
    };

    saveSnils = bundle => {
        const {AuthStore} = this.props;
        const identifiers = _get(AuthStore.currentUserData.person, ['identifier'], []);
        const snils = _get(identifiers.find(identifier => identifier.system == SNILS_IDENTIFIER_SYSTEM), ['value']);
        if (!!!snils) {
            if (this.snils) {
                this.checkValidation("snilsError");
                if (!this.validation.snilsError) {
                    AuthStore.currentUserData.person._arrayAdd("identifier",
                        {
                            system: SNILS_IDENTIFIER_SYSTEM,
                            value: this.snils,
                            use: "official"
                        });
                    bundle.add(AuthStore.currentUserData.person);
                } else
                    return true;
            }
        }
    };

    onClick = () => {
        const {onClose} = this.props;
        const bundle = new Bundle();

        let isErrorEmail = this.saveEmail(bundle);
        if (isErrorEmail)
            return;

        let isErrorSnils = this.saveSnils(bundle);
        if (isErrorSnils)
            return;

        if (!bundle.data.length) {
            onClose(() => this.redirectOnContextPopup());
        }
        else {
            bundle.flush()
                .then(() => {
                    onClose(() => this.redirectOnContextPopup());
                })
        }
    };

    redirectOnContextPopup = () => {
        this.props.history.push(Paths.dashboard.path());
    };

    checkValidation = (validationError) => {
        const validationMessage = this[validationError];
        if(validationMessage) {
            ToasterStore.error(validationMessage);
            this.validation[validationError] = true;
        } else {
            this.validation[validationError] = false;
        }
    };

    resetValidation = (validationError) => {
        this.validation[validationError] = false;
    };

    get snilsError() {
        const snilsValidation = new SnilsValidation(this.snils);
        if( snilsValidation.error ) {
            return snilsValidation.error;
        }
    }

    get emailError() {
        const translator = new Translator(HeaderStore.locale, locales);
        if ( ! EMAIL_REGEX.test(this.email) ) {
            return translator.translate('TT010540');
        }
    }

    render() {
        const {AuthStore} = this.props;
        let context = localStorage.getItem('emiasContext');
        let fio, organization, position;
        if (context) {
            context = JSON.parse(context);
            const lastName = _get(context, "userContext.fullName.lastName", "");
            const firstName = _get(context, "userContext.fullName.firstName", "");
            const secondName = _get(context, "userContext.fullName.secondName", "");
            fio = `${lastName} ${firstName} ${secondName}`;
            organization = _get(context, "organization", "");
            position = _get(context, "position", "");
        }
        else {
            fio = _get(AuthStore.currentUserData, "person.displayFullName", "");
            position = _get(AuthStore.currentUserData, "activeRole.code.0.text", "");
            organization = _get(AuthStore.currentUserData, "organizations.0.name", "");
        }

        const identifiers = _get(AuthStore.currentUserData.person, ['identifier'], []);
        const snils = _get(identifiers.find(identifier => identifier.system == SNILS_IDENTIFIER_SYSTEM), ['value']);

        const activeRole = AuthStore.currentUserData.roles
            .find(i => i.id === AuthStore.currentUserData.active_role_id);
        const telecom = _get(activeRole, '_data.telecom', []);
        const email = telecom.find(i => i.system === "email");

        const translator = new Translator(HeaderStore.locale, locales);

        return (
            <FillPageContainer>
                <AuthWrapper logoMargin={"30px"}>
                    <Info>
                        <InfoText>{fio}</InfoText>
                        <InfoText sub>{organization}</InfoText>
                        <InfoText sub>{position}</InfoText>
                    </Info>
                    {
                        !snils &&
                        <SnilsInput
                            label={translator.translate('TT018910')}
                            mainWidth="100%"
                            inputHeight="40px"
                            value={this.snils}
                            onChange={value => this.snils = value}
                            className={'form-control-sm'}
                            isError={this.validation.snilsError}
                            onFocus={() => this.resetValidation("snilsError")}
                        />
                    }
                    {
                        !email &&
                        <Input
                            type="email"
                            label={"Email"}
                            value={this.email}
                            onChange={value => this.email = value}
                            isError={this.validation.emailError}
                            onFocus={() => this.resetValidation("emailError")}
                            mainMargin={"16px 0 0 0"}/>
                    }

                    <ButtonWrapper justifyContent={'flex-end'}>
                        <Button
                            onClick={this.onClick}
                            label={translator.translate('TT018990')}
                            minWidthAuto
                            width={"116px"}
                        />
                    </ButtonWrapper>
                </AuthWrapper>
            </FillPageContainer>
        )
    }
}
