import styled from "styled-components";
import React from "react";
import Button from "../Elements/Button";

const ErrorBoundaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  padding: 24px;
`;

const ErrorHeader = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 14px;
  color: #000;
`;

const Message = styled.p`
  font-size: 4px;
  margin-bottom: 14px;
  color: #000;
`;

export const errorBoundary = (headerText = "", messageText = "", buttonText= "", code = "", component = "") => (WrappedComponent) => {
    return class ErrorBoundaryComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = { hasError: false };
        }

        static getDerivedStateFromError(error) {
            // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
            return { hasError: true };
        }

        componentDidCatch(error, errorInfo) {
            //ToDo: Сдлеать отправку логов
            //logErrorToMyService(error, errorInfo);
        }

        render() {
            headerText = headerText ? headerText : 'Ошибка';
            messageText = messageText ? messageText : 'Ой, что-то пошло не так. Повторите попытку позже.';
            buttonText = buttonText ? buttonText : "ОК";
            const onClickButtonClose = this.props.onClose ? this.props.onClose : null;
            if (this.state.hasError) {
                return (
                    <ErrorBoundaryWrapper>
                        <ErrorHeader>{headerText}</ErrorHeader>
                        <Message>{messageText}</Message>
                        {
                            onClickButtonClose &&
                            <Button
                                onClick={onClickButtonClose}
                                label={buttonText}
                                BGcolor={'dark'}
                                minWidthAuto
                                width="100px"
                                padding="16px 20px"
                            />
                        }
                    </ErrorBoundaryWrapper>
                )
            }

            return <WrappedComponent {...this.props} />;
        }
    }
}
