// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMXkdONMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMWKo'   ,cxXMMMMMMMMMMMMMMMMM
// MMMMMMMMMMW0l. .;c,   'oKWMMMMMMMMMMMMMM
// MMMMMMMMNk:. .cONMNko:. .cONMMMMMMMMMMMM
// MMMMMWXd,  'oKWMMMMMMW0l. .;xXMMMMMMMMMM
// MMMW0l. .;xXMMMMMMMMMMMWKd,  'oKMMMMMMMM
// MMMK,  :ONMMMMMMMMMMMMMMMMNk;..lNMMMMMMM
// MMMO. '0MMMMMMMMMMMMMMMMMMMMWKOXMMMMMMMM
// MMMO. '0MMMMWKkxxxxxxkKWMMMMW0kXMMMMMMMM
// MMMO. '0MMMM0,        :XMMMMO' :XMMMMMMM
// MMMO. '0MMMMO. .okl.  ,KMMMMk. ,KMMMMMMM
// MMMO. '0MMMMO. '0MO.  ,KMMMMk. ,KMMMMMMM
// MMMO. '0MMMMXo;dNMk.  ,KMMMMk. ,KMMMMMMM
// MMMO. .d000000O000o.  .x0000o. ,KMMMMMMM
// MMM0'   .. .......      ....   ;XMMMMMMM
// MMMW0dddddddddddddddddddddddddx0WMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM

import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class Home extends PureComponent{

    static propTypes={
      stroke: PropTypes.string,
    };

    static defaultProps ={
        stroke: 'black',
    };

    render(){
        const {stroke} = this.props;
        return(
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.3415 2.24742C11.7185 1.91753 12.2815 1.91753 12.6585 2.24742L20.6585 9.24742C21.0741 9.61111 21.1163 10.2429 20.7526 10.6585C20.3889 11.0741 19.7571 11.1163 19.3415 10.7526L12 4.32877L5 10.4538V20H13V15H11V17.5C11 18.0523 10.5523 18.5 10 18.5C9.44772 18.5 9 18.0523 9 17.5V14C9 13.4477 9.44772 13 10 13H14C14.5523 13 15 13.4477 15 14V20H19V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V10C3 9.71164 3.12448 9.43731 3.3415 9.24742L11.3415 2.24742Z"
                    fill={stroke}
                />
            </svg>
        )
    }
}