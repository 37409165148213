import { observable } from "mobx";

export class TextFilter {

    /**
     * значение фильтра
     */
    @observable value = "";

    /**
     * иногда хранить не нужно
     */
    saveToStorage = true;

    constructor(options) {
        if (options && typeof options.saveToStorage !== "undefined") {
            this.saveToStorage = options.saveToStorage;
        }
    }

    /**
     * сброс фильтра
     */
    reset = () => {
        this.value = '';
        this.setLocalStorage();
    }

    /**
     * установка значения фильтра
     */
    setValue = (value = "") => {
        this.value = value.trim().length ? value : "";
        this.setLocalStorage();
    }

    initLocalStorage = (localStoreFieldsKey, paramKey) => {
        if (!this.saveToStorage) {
            return;
        }
        this.localStoreFieldsKey = localStoreFieldsKey;
        this.paramKey = paramKey;
    }

    setLocalStorage = () => {
        if (!this.saveToStorage) {
            return;
        }
        if (this.localStoreFieldsKey) {
            let filters = localStorage.getItem(this.localStoreFieldsKey);
            if (filters) {
                filters = JSON.parse(filters);
            } else {
                filters = {};
            }
            filters[this.paramKey] = this.value;
            localStorage.setItem(this.localStoreFieldsKey, JSON.stringify(filters));
        }
    }
}
