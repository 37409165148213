import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class ParticipantIcon extends PureComponent {
    static propTypes = {
        fill: PropTypes.string,
    }

    static defaultProps = {
        fill: '#232735',
    }

    render(){
        const {fill} = this.props;

        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.42865 5.5714L4.42863 7.28372L4.42857 7.78571C4.42857 9.05537 4.87166 10.3857 5.5869 11.4111C6.29217 12.4222 7.37719 13.2857 8.71428 13.2857C10.0514 13.2857 11.1364 12.4222 11.8417 11.4111C12.5569 10.3857 13 9.05537 13 7.78571V5.57143C13 4.15127 11.8487 3 10.4286 3H7.00007C5.57993 3 4.42866 4.15125 4.42865 5.5714ZM6.14293 5.57142C6.14294 5.09804 6.52669 4.71429 7.00007 4.71429H10.4286C10.902 4.71429 11.2857 5.09804 11.2857 5.57143V6.42857H6.14292L6.14293 5.57142ZM6.15923 8.14286H11.2693C11.1974 8.94097 10.8925 9.77538 10.4356 10.4304C9.9 11.1983 9.27074 11.5714 8.71428 11.5714C8.15783 11.5714 7.52857 11.1983 6.99293 10.4304C6.53604 9.77538 6.23118 8.94097 6.15923 8.14286Z"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M5.9989 13.6674L5.63575 13.1227L5.01466 13.3297C3.58673 13.8057 2.5452 14.5524 1.88041 15.6154C1.22653 16.661 1 17.9134 1 19.2857V19.8155L1.47382 20.0524L1.85714 19.2857C1.47382 20.0524 1.47473 20.0528 1.47473 20.0528L1.47569 20.0533L1.47772 20.0543L1.48226 20.0565L1.49324 20.0618C1.50141 20.0657 1.5113 20.0704 1.52296 20.0757C1.54628 20.0862 1.57665 20.0995 1.61449 20.1151C1.69017 20.1463 1.7957 20.1866 1.9343 20.2328C2.21149 20.3252 2.62105 20.4412 3.18904 20.5548C4.3245 20.7819 6.09602 21 8.71428 21C11.3326 21 13.1041 20.7819 14.2395 20.5548C14.8075 20.4412 15.2171 20.3252 15.4943 20.2328C15.6329 20.1866 15.7384 20.1463 15.8141 20.1151C15.8519 20.0995 15.8823 20.0862 15.9056 20.0757C15.9173 20.0704 15.9272 20.0657 15.9353 20.0618L15.9463 20.0565L15.9508 20.0543L15.9529 20.0533L15.9538 20.0528C15.9538 20.0528 15.9548 20.0524 15.5714 19.2857L15.9548 20.0524L16.4286 19.8155V19.2857C16.4286 17.9134 16.202 16.661 15.5482 15.6154C14.8834 14.5524 13.8418 13.8057 12.4139 13.3297L11.7928 13.1227L11.4297 13.6674C10.7752 14.6491 9.80239 15 8.71428 15C7.62618 15 6.65335 14.6491 5.9989 13.6674ZM3.52524 18.8738C3.19253 18.8072 2.931 18.7417 2.73273 18.6853C2.79117 17.7792 2.98902 17.0758 3.33388 16.5244C3.66937 15.9879 4.18601 15.5285 4.99872 15.1702C6.03446 16.3279 7.41837 16.7143 8.71428 16.7143C10.0102 16.7143 11.3941 16.3279 12.4298 15.1701C13.2426 15.5285 13.7592 15.9879 14.0947 16.5244C14.4395 17.0758 14.6374 17.7792 14.6958 18.6852C14.4976 18.7417 14.236 18.8072 13.9033 18.8738C12.8959 19.0753 11.2389 19.2857 8.71428 19.2857C6.1897 19.2857 4.53264 19.0753 3.52524 18.8738Z"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M14.4445 4.85712L14.4445 6.0938L14.4444 6.45635C14.4444 7.37332 14.7604 8.33415 15.2703 9.07472C15.7731 9.80496 16.5467 10.4286 17.5 10.4286C18.4533 10.4286 19.2269 9.80496 19.7297 9.07472C20.2396 8.33415 20.5556 7.37332 20.5556 6.45635V4.85714C20.5556 3.83147 19.7347 3 18.7222 3H16.2778C15.2653 3 14.4445 3.83146 14.4445 4.85712ZM15.6667 4.85714C15.6667 4.51525 15.9403 4.2381 16.2778 4.2381H18.7222C19.0597 4.2381 19.3333 4.51525 19.3333 4.85714V5.47619H15.6667L15.6667 4.85714ZM15.6783 6.71429H19.3217C19.2704 7.2907 19.053 7.89333 18.7273 8.3664C18.3454 8.921 17.8967 9.19048 17.5 9.19048C17.1033 9.19048 16.6546 8.921 16.2727 8.3664C15.947 7.89333 15.7296 7.2907 15.6783 6.71429Z"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M15.564 10.7042L15.3051 10.3108L14.8623 10.4603C13.8442 10.8041 13.1017 11.3434 12.6277 12.1111C12.1615 12.8663 12 13.7708 12 14.7619V15.1445L12.3378 15.3156L12.6111 14.7619C12.3378 15.3156 12.3385 15.3159 12.3385 15.3159L12.3391 15.3163L12.3406 15.317L12.3438 15.3186L12.3517 15.3224C12.3575 15.3252 12.3645 15.3286 12.3729 15.3324C12.3895 15.3401 12.4111 15.3497 12.4381 15.3609C12.4921 15.3834 12.5673 15.4125 12.6661 15.4459C12.8637 15.5126 13.1557 15.5964 13.5607 15.6785C14.3702 15.8425 15.6333 16 17.5 16C19.3667 16 20.6298 15.8425 21.4393 15.6785C21.8443 15.5964 22.1363 15.5126 22.3339 15.4459C22.4327 15.4125 22.5079 15.3834 22.5619 15.3609C22.5889 15.3497 22.6105 15.3401 22.6271 15.3324C22.6355 15.3286 22.6425 15.3252 22.6483 15.3224L22.6562 15.3186L22.6594 15.317L22.6609 15.3163L22.6615 15.3159C22.6615 15.3159 22.6622 15.3156 22.3889 14.7619L22.6622 15.3156L23 15.1445V14.7619C23 13.7708 22.8385 12.8663 22.3723 12.1111C21.8983 11.3434 21.1558 10.8041 20.1377 10.4603L19.6949 10.3108L19.436 10.7042C18.9694 11.4132 18.2758 11.6667 17.5 11.6667C16.7242 11.6667 16.0306 11.4132 15.564 10.7042ZM13.8004 14.4644C13.5632 14.4163 13.3767 14.369 13.2354 14.3282C13.277 13.6739 13.4181 13.1659 13.664 12.7676C13.9032 12.3802 14.2715 12.0484 14.8509 11.7896C15.5894 12.6257 16.5761 12.9048 17.5 12.9048C18.4239 12.9048 19.4106 12.6257 20.1491 11.7896C20.7285 12.0484 21.0968 12.3802 21.336 12.7676C21.5819 13.1659 21.723 13.6739 21.7646 14.3282C21.6233 14.369 21.4368 14.4163 21.1996 14.4644C20.4814 14.6099 19.2999 14.7619 17.5 14.7619C15.7001 14.7619 14.5186 14.6099 13.8004 14.4644Z" />
            </svg>
        );
    }
}

ParticipantIcon.propTypes = {};

export default ParticipantIcon;
