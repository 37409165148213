import _get from "lodash/get";
import { observable } from "mobx";
import { Practitioner } from "MODEL_STORE/FHIR/Resources/Practitioner";
import { basicRequestGet } from "MODEL_STORE/DataSource/Requests/basicRequestGet";

/**
 * количество загружаемых ресурсов сотрудников
 */
const PRACTITIONERS_COUNT = 999;

export class PractitionerFilter {

    /**
     * ресурсы сотрудников
     */
    @observable resources = [];

    /**
     * идентификатор выбранного сотрудника
     */
    @observable value = "";

    /**
     * при создании объекта
     * загружаем ресурсы сотрудников
     */
    constructor() {
        this.loadResources();
    }

    /**
     * сброс фильтра
     */
    reset = () => {
        this.value = '';
        this.setLocalStorage();
    }

    get query(){
        const _query = {
            "_sort": "name",
            "limit": PRACTITIONERS_COUNT,
            "_elements": [
                "id",
                "name"
            ].join(",")
        }
        let queryArray = []
        Object.keys(_query).forEach(filterName => {
            queryArray.push(`${filterName}=${_query[filterName]}`)
        });
        return queryArray.join("&")
    }

    /**
     * загрузка ресурсов сотрудников
     * нужны только идентификаторы
     * и имена сотрудников
     * отсортированные по именам
     */
    loadResources = () => {
        basicRequestGet(`/api/mm/practitioners?${this.query}`).then(response => {
            this.resources = response.data.data.map(entry => new Practitioner(entry));
        }).catch(error => {
            console.error("При загрузке ресурсов сотрудников произошла ошибка", error);
        });
    }

    /**
     * выбранное значение лечащего врача пациента для React-Select
     */
    get option() {
        return this.options.find(option => option.value == this.value)  || "";
    }

    /**
     * возможные значения лечащего врача пациента для React-Select
     */
    get options() {
        return this.resources.map(resource => ({
            value: resource.id,
            label: resource.displayName
        }));
    }

    /**
     * установка лечащего врача пациента
     */
    setOption = (selected) => {
        this.value = _get(selected, "value", "");
        this.setLocalStorage();
    }

    initLocalStorage = (localStoreFieldsKey, paramKey) => {
        this.localStoreFieldsKey = localStoreFieldsKey;
        this.paramKey = paramKey;
    }

    setLocalStorage = () => {
        if (this.localStoreFieldsKey) {
            let filters = localStorage.getItem(this.localStoreFieldsKey);
            if (filters) {
                filters = JSON.parse(filters);
            } else {
                filters = {};
            }
            filters[this.paramKey] = this.value;
            localStorage.setItem(this.localStoreFieldsKey, JSON.stringify(filters));
        }
    }
}
