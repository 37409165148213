export const CALENDAR = {
    weekdays: [
        {
            value: 0,
            name: 'Воскресенье',
            shortName: 'Вс',
        },
        {
            value: 1,
            name: 'Понедельник',
            shortName: 'Пн',
        },
        {
            value: 2,
            name: 'Вторник',
            shortName: 'Вт',
        },
        {
            value: 3,
            name: 'Среда',
            shortName: 'Ср',
        },
        {
            value: 4,
            name: 'Четверг',
            shortName: 'Чт',
        },
        {
            value: 5,
            name: 'Пятница',
            shortName: 'Пт',
        },
        {
            value: 6,
            name: 'Суббота',
            shortName: 'Сб',
        },
    ],
    months: [
        {
            value: 0,
            name: 'Январь',
        },
        {
            value: 1,
            name: 'Февраль',
        },
        {
            value: 2,
            name: 'Март',
        },
        {
            value: 3,
            name: 'Апрель',
        },
        {
            value: 4,
            name: 'Май',
        },
        {
            value: 5,
            name: 'Июнь',
        },
        {
            value: 6,
            name: 'Июль',
        },
        {
            value: 7,
            name: 'Август',
        },
        {
            value: 8,
            name: 'Сентябрь',
        },
        {
            value: 9,
            name: 'Октябрь',
        },
        {
            value: 10,
            name: 'Ноябрь',
        },
        {
            value: 11,
            name: 'Декабрь',
        },
    ],
    years: [
        {
            value: '1',
            name: '1994',
        },
        {
            value: '2',
            name: '1995',
        },
        {
            value: '3',
            name: '1996',
        },
        {
            value: '4',
            name: '1997',
        },
        {
            value: '5',
            name: '1998',
        },
        {
            value: '6',
            name: '1999',
        },
        {
            value: '7',
            name: '2000',
        },
        {
            value: '8',
            name: '2001',
        },
    ]
};
