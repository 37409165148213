import { observable } from 'mobx';

class SidePanelsStoreClass {
    /*
    * Для отслеживания открыты/закрыты ли выезжающие панели с "Прошедший консилиум"
    * и с "Документы пациента"*/

    @observable
    patientDocumentsOpen = false
    @observable
    finishedConsiliumOpen = false
}

const SidePanelsStore = new SidePanelsStoreClass();

export {SidePanelsStore}
