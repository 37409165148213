import {createGlobalStyle} from 'styled-components';

export function fontFace(name, fontWeight = 'normal', fontStyle = 'normal'){
    return`
        @font-face{
            font-family: "${name}";
            src: url(${require('../../Fonts/fonts/' + name + '.ttf')});
            src: url(${require('../../Fonts/fonts/' + name + '.woff' )}) format ("woff"),
                 url(${require('../../Fonts/fonts/' + name + '.woff2')}) format ("woff2");
            font-style: ${fontStyle};
            font-weight: ${fontWeight};
        }
    `;
}

export const GlobalStyle = createGlobalStyle`
  ${fontFace('opensans')}
  ${fontFace('opensansbold')}
  ${fontFace('opensanssemibold')}
`
