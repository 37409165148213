import ReactTooltip from "react-tooltip";

function checker(arr, dataFor, tooltipText) {
  arr.forEach(target => {
    if(target.current) {
      target = target.current;
    }

    if(target.dataset.tip !== target.innerHTML || !target.dataset.tip) {
      checkerData(target, dataFor, tooltipText)
    }
  })
}

function checkerTarget(ref, tooltipText, tooltipTarget) {

  if(ref && tooltipTarget){

    let target = tooltipTarget.current ? tooltipTarget.current : tooltipTarget;
    ref = ref.current ? ref.current : ref;

    if(target.dataset.tip !== tooltipText || !target.dataset.tip) {
      checkerData(ref, null, tooltipText, target)
    }
  }

}

function checkerClass(target) {
  const arrCollection = document.getElementsByClassName(target),
      targetArr = Array.from(arrCollection);
  targetArr.forEach(target => {
    if(target.dataset.tip !== target.innerHTML || !target.dataset.tip) {
      checkerData(target)
    }
  })
}

function checkerField(ref, dataFor) {

  let target;

  if(ref[0]) {
    target = ref[0].querySelector('.styledReactSelect__single-value');
  } else {
    target = ref.querySelector('.styledReactSelect__single-value');
  }

  const secondTarget = target && target.querySelector('div');

  if(secondTarget) {
    checkerData(secondTarget, dataFor)
  } else {
    if(target) {
      checkerData(target, dataFor)
    }
  }
}

function checkerData(target, dataFor, tooltipText, tooltipTarget) {
  if(target.offsetHeight < (target.scrollHeight - 10) || target.offsetWidth < target.scrollWidth) {
    if(tooltipTarget) {
      target = tooltipTarget;
    }

    target.setAttribute("data-tip", "");
    if(dataFor) {
      target.setAttribute("data-for", dataFor);
    } else {
      target.setAttribute("data-for", "float-tooltip");
      if(tooltipText) {
        target.dataset.tip = tooltipText
      } else {
        target.dataset.tip = target.value || target.innerHTML;
      }
    }
  } else {

    if(tooltipTarget) {
      target = tooltipTarget;
    }

    target.setAttribute("data-tip", "");
    target.setAttribute("data-for", "");
  }

  ReactTooltip.rebuild();
}

function checkerFormat(target, optionsTooltipFormat) {
    const arrCollection = document.getElementsByClassName(target),
        targetArr = Array.from(arrCollection);
    targetArr.forEach(target => {
        if(target.dataset.tip) {
            checkerData(target, null, optionsTooltipFormat(target.dataset.tip))
        }
    })
}

export function tooltipChecker(type, ref, dataFor, tooltipText, tooltipTarget, optionsTooltipFormat) {

  switch (type) {
    case "class":
      return checkerClass(ref, dataFor);
    case "text":
      return checker(ref, dataFor, tooltipText);
    case "target":
      return checkerTarget(ref, tooltipText, tooltipTarget);
    case "select":
      return checkerField(ref, dataFor)
    case "format":
      return checkerFormat(ref, optionsTooltipFormat)

  }
}
