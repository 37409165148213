import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class RarIcon extends PureComponent{

    static propTypes = {
        stroke: PropTypes.string,
    }

    static defaultProps = {
        stroke: '#F0F1F3',
    }

    render(){
        const {stroke} = this.props;
        return(
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M3 2.05C3 1.4701 3.44772 1 4 1H15C15.2652 1 15.5196 1.11062 15.7071 1.30754L20.7071 6.55754C20.8946 6.75445 21 7.02152 21 7.3V20.95C21 21.5299 20.5523 22 20 22H4C3.44772 22 3 21.5299 3 20.95V2.05ZM5 3.1V19.9H19V8.35H15C14.4477 8.35 14 7.8799 14 7.3V3.1H5ZM16 4.58492L17.5858 6.25H16V4.58492Z" fill="#121232"/>
              <path d="M10.8252 16.209V17H7.7373V16.209H10.8252ZM10.8047 13.2617L8.24707 17H7.50293V16.458L10.0898 12.7344H10.8047V13.2617ZM10.4736 12.7344V13.5283H7.48828V12.7344H10.4736ZM12.3867 12.7344V17H11.3613V12.7344H12.3867ZM14.8125 15.5527H13.752V14.7588H14.8125C14.957 14.7588 15.0742 14.7344 15.1641 14.6855C15.2539 14.6367 15.3193 14.5693 15.3604 14.4834C15.4033 14.3955 15.4248 14.2969 15.4248 14.1875C15.4248 14.0703 15.4033 13.9619 15.3604 13.8623C15.3193 13.7627 15.2539 13.6826 15.1641 13.6221C15.0742 13.5596 14.957 13.5283 14.8125 13.5283H14.1387V17H13.1104V12.7344H14.8125C15.1543 12.7344 15.4482 12.7969 15.6943 12.9219C15.9424 13.0469 16.1328 13.2188 16.2656 13.4375C16.3984 13.6543 16.4648 13.9023 16.4648 14.1816C16.4648 14.457 16.3984 14.6973 16.2656 14.9023C16.1328 15.1074 15.9424 15.2676 15.6943 15.3828C15.4482 15.4961 15.1543 15.5527 14.8125 15.5527Z" fill="#E79925"/>
          </svg>
        )
    }
}
