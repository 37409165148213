import React from "react";
import { Paths } from "Paths";
import { Redirect } from "react-router-dom";

/**
 * https://jira.mmdx.ru/browse/FCSPOK-321
 * GET /integrations/context/[:id]
 */
export class ContextPage extends React.Component {

    // тут когда-то была целая страница, поэтому называется ContextPage
    // теперь всё немного усохло
    componentDidMount() {
        const contextId = this.props.match.params["id"];
        localStorage.setItem("INTEGRATION_CONTEXT_ID", contextId);
    }

    render() {
        return <Redirect to={Paths.dashboard.path() + "/start"}/>;
    }
}
