import {LOCAL_STORE_FILTER_KEY as FILTER_KEY_SERVICEREQUEST} from "GUI_TB/Components/ServiceRequest/List/Data/Filters";
import {LOCAL_STORE_FILTER_KEY as FILTER_KEY_APPOINTMENTS} from "GUI_TB/Components/Appointment/List/Data/Filters";
import {LOCAL_STORE_FILTER_KEY as FILTER_KEY_PATIENTS} from "GUI_MAIN/Components/Patient/List/Data/Filters";
import {LOCAL_STORE_FILTER_KEY as FILTER_KEY_CARE_TEAMS} from "GUI_TB/Components/CareTeam/IndexPage/Data/Filters";
import {LOCAL_STORE_FILTER_KEY as FILTER_KEY_PROTOCOLS} from "GUI_TB/Components/ProtocolsV2/List/Data/Filters";
import {LOCAL_STORE_FILTER_KEY as FILTER_KEY_MY_SERVICEREQUESTS} from "GUI_TB/Components/Dashboard/index";

export const clearFiltersInLocalStorage = () => {
    localStorage.removeItem(FILTER_KEY_SERVICEREQUEST);
    localStorage.removeItem(FILTER_KEY_APPOINTMENTS);
    localStorage.removeItem(FILTER_KEY_PATIENTS);
    localStorage.removeItem(FILTER_KEY_CARE_TEAMS);
    localStorage.removeItem(FILTER_KEY_PROTOCOLS);
    localStorage.removeItem(FILTER_KEY_MY_SERVICEREQUESTS);
};
