import React, {PureComponent} from 'react';
import PropTypes from "prop-types";

export default class Protocol extends PureComponent{

    static propTypes = {
        stroke: PropTypes.string,
    };

    static defaultProps = {
        stroke: "black",
    };

    render(){
        const {stroke} = this.props;
        return(
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M7.5 8C6.94772 8 6.5 8.44772 6.5 9C6.5 9.55228 6.94772 10 7.5 10H12.5C13.0523 10 13.5 9.55228 13.5 9C13.5 8.44772 13.0523 8 12.5 8H7.5Z"
                      fill={stroke}
                />
                <path d="M6.5 12C6.5 11.4477 6.94772 11 7.5 11H12.5C13.0523 11 13.5 11.4477 13.5 12C13.5 12.5523 13.0523 13 12.5 13H7.5C6.94772 13 6.5 12.5523 6.5 12Z"
                      fill={stroke}
                />
                <path d="M7.5 14C6.94772 14 6.5 14.4477 6.5 15C6.5 15.5523 6.94772 16 7.5 16H12.5C13.0523 16 13.5 15.5523 13.5 15C13.5 14.4477 13.0523 14 12.5 14H7.5Z"
                      fill={stroke}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 2C3.44772 2 3 2.44772 3 3V18C3 18.5523 3.44772 19 4 19H5V22C5 22.5523 5.44772 23 6 23H19C19.5523 23 20 22.5523 20 22V7.5C20 6.94772 19.5523 6.5 19 6.5H17V6C17 5.73478 16.8946 5.48043 16.7071 5.29289L13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2H4ZM7 21V19H16C16.5523 19 17 18.5523 17 18V8.5H18V21H7ZM12 6V4H5V17H15V7H13C12.4477 7 12 6.55228 12 6Z"
                    fill={stroke}
                />
            </svg>
        )
    }
}
