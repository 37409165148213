import { Popup } from "./Popup";
import { observable } from "mobx";
import { Actions } from "./Actions";
import { Filters } from "./Filters";

/**
 * данные для нотификаций
 */
export class Data {

    /**
     * счетчик непрочитанных нотификаций
     */
    @observable counter = 0;

    /**
     * попап со списком нотификаций и фильтрами
     */
    @observable popup = new Popup();

    /**
     * загружаются ли данные?
     */
    @observable loading = false;

    /**
     * массив ресурсов нотификаций
     */
    @observable notifications = [];

    /**
     * фильтры по нотификациям
     */
    @observable filters = new Filters();

    /**
     * взаимодествие с данными
     */
    @observable actions = new Actions(this);

    /**
     * при инициализации обновляем счетчик
     * и загружаем список нотификаций
     */
    constructor() {
        this.actions.updateCounter();
    }

}
