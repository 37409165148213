import { observable } from "mobx";

/**
 * попап со списком нотификаций и фильтрами
 */
export class Popup {

    /**
     * отображать ли попап?
     */
    @observable isVisible = false;

    /**
     * отображать ли форму с фильтрами?
     */
    @observable filtersIsVisible = false;

    /**
     * отобразить попап с нотификациями
     */
    show = () => {
        this.isVisible = true;
    }

    /**
     * скрыть попап с нотификациями
     */
    hide = () => {
        this.isVisible = false;
        this.filtersIsVisible = false;
    }

    /**
     * отобразить попап с фильтрами
     */
    showFilters = () => {
        this.filtersIsVisible = true;
    }

    /**
     * скрыть попап с фильтрами
     */
    hideFilters = () => {
        this.filtersIsVisible = false;
    }

    /**
     * отобразить/скрыть попап с фильтрами
     */
    toggleFilters = () => {
        this.filtersIsVisible = ! this.filtersIsVisible
    }

}
