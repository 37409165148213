import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class ArrowFilled extends PureComponent{

    static propTypes = {
        fill: PropTypes.string,
    }

    static defaultProps = {
        fill: '#A1A4B1',
    }

    render(){
        const {fill} = this.props;
        return(
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
              <path d="M4 10.5938L8 6.59375L12 10.5938H4Z" fill={fill}/>
          </svg>
        )
    }
}