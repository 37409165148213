import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class FilterSimpleIcon extends PureComponent {
    static propTypes = {
        stroke: PropTypes.string,
    }

    static defaultProps = {
        stroke: '#A1A4B1',
    }

    render() {
        const {stroke} = this.props;
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 12L5 6H19L14 12V17L10 19V12Z" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        );
    }
}

FilterSimpleIcon.propTypes = {};

export default FilterSimpleIcon;