import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class Patient extends PureComponent{

    static propTypes ={
        stroke: PropTypes.string,
    }

    static defaultProps ={
        stroke: 'black',
    }

    render(){
        const {stroke} = this.props;
        return(
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8H15C15.5523 8 16 7.55228 16 7C16 6.44772 15.5523 6 15 6H12Z" fill={stroke}/>
                <path d="M11 10C11 9.44772 11.4477 9 12 9H15C15.5523 9 16 9.44772 16 10C16 10.5523 15.5523 11 15 11H12C11.4477 11 11 10.5523 11 10Z" fill={stroke}/>
                <path d="M12 12C11.4477 12 11 12.4477 11 13C11 13.5523 11.4477 14 12 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H12Z" fill={stroke}/>
                <path d="M4 12.9997C4.00037 11.9288 5.16172 11 7 11C8.83782 11 10 11.9287 10 13C10 13.5523 9.55228 14 9 14H5C4.73472 14 4.48032 13.8946 4.29277 13.707C4.10523 13.5194 3.99991 13.2649 4 12.9997Z" fill={stroke}/>
                <path d="M7 6C5.89543 6 5 6.89543 5 8C5 9.10457 5.89543 10 7 10C8.10457 10 9 9.10457 9 8C9 6.89543 8.10457 6 7 6Z" fill={stroke}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M2 3C1.44772 3 1 3.44772 1 4V16C1 16.5523 1.44772 17 2 17H3V19C3 19.5523 3.44772 20 4 20H21C21.5523 20 22 19.5523 22 19V6C22 5.44772 21.5523 5 21 5H19V4C19 3.44772 18.5523 3 18 3H2ZM5 18V17H18C18.5523 17 19 16.5523 19 16V7H20V18H5ZM3 5H17V15H3V5Z" fill={stroke}/>
            </svg>
        )
    }
}