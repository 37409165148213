import React from 'react';
import {BorderButton} from "./components";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {observer} from "mobx-react";
import ZoomIcon from "GUI_MAIN/icons/ZoomIcon";
import styled from "styled-components";
import { observable } from 'mobx';
import { Range } from 'react-range';

const ImageWrapper = styled.div`
  left: 0;
  position: absolute;
  top: 48px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  right: 0;
  margin: auto;
`;

const ControlWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 10;
`;

const Control = styled.div`
  position: absolute;
  z-index: 100;
  background-color: #000000;
  left: -111px;
  padding: 14px 11px;
  width: 270px;
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const SliderWrapper = styled.div`
  width: 200px;
  margin: 0 6px;
  flex-shrink: 0;
  position: relative;
`;

const ControlIcon = styled.div`
  height: 27px;
  width: 18px;
  svg {
    width: 18px;
    height: 18px;
    fill: #ffffff;
  }
`;

@observer
export class ImageViewer extends React.PureComponent {

    step = 20;
    @observable sliderValue = this.step;
    @observable defaultHeight = null;
    @observable activeControl = false;
    @observable fillWidth = 100;
    width = 'auto'
    @observable height = '100%'
    changeTimeout;

    constructor(props){
        super(props)

        // Ссылка на изображение
        this.imgRef = React.createRef()
    }

    componentDidMount() {
        this.defaultHeight = this.imgRef.current.clientHeight;
        this.height = this.defaultHeight + this.step;
    }

    get minHeight() {
        return -(this.defaultHeight * 90/100);
    }

    /**
     * Обработчик увеличения
     */
    handleZoomIn= () => {
        const newHeight = this.height + this.step;
        const sliderValue = newHeight - this.defaultHeight;
        if (this.defaultHeight >= this.sliderValue && sliderValue <= this.defaultHeight) {
            this.height = newHeight;
            this.sliderValue = sliderValue;
        }
    }

    /**
     * Обработчик уменьшения
     */
    handleZoomOut = () => {
        const newHeight = this.height - this.step;
        const sliderValue = newHeight - this.defaultHeight;
        if (this.minHeight < this.sliderValue ) {
            this.height = newHeight;
            this.sliderValue = sliderValue;
        }
    }

    get trackBackground() {
        const progress = ((this.sliderValue - this.minHeight) / (this.defaultHeight - this.minHeight)) * 100;
        if (progress >= 50) {
            return `linear-gradient(to right, #ccc 0%, #ccc 50%, #548BF4 50%, #548BF4 ${progress}%, #ccc ${progress}%, #ccc 100%)`;
        }
        return `linear-gradient(to right, #ccc 0%, #ccc ${progress}%, #548BF4 ${progress}%, #548BF4 50%, #ccc 50%, #ccc 100%)`;
    }

    /**
     * Обработчик трека
     */
    renderTrack = ({ props, children }) => {
        return (
            <div
                {...props}
                style={{
                    ...props.style,
                    height: '3px',
                    width: '100%',
                    background: this.trackBackground,
                }}
            >
                {children}
            </div>
        )
    }

    /**
     * Внешний вид ползунка
     */
    renderThumb = ({ props }) => {
        return (
            <div
                {...props}
                style={{
                    ...props.style,
                    height         : '20px',
                    width          : '8px',
                    backgroundColor: '#1B75BB',
                    // display: 'inline-block',
                    borderRadius: '30px',
                }}
            />
        )
    }

    onChangeTrack = (values) => {
        this.sliderValue = values[0];
        clearTimeout(this.changeTimeout);
        this.changeTimeout = setTimeout(() => this.height = this.defaultHeight + this.sliderValue);
    }

    get sliderValues() {
        // Текущее значение Range строго не должно быть вне диапазона min-max
        if (this.sliderValue > this.defaultHeight)
            return [this.defaultHeight];
        if (this.sliderValue < this.minHeight)
            return [this.minHeight];
        return [this.sliderValue];
    }

    render() {
        return (
            <>
                <ControlWrapper>
                    {
                        this.defaultHeight &&
                        <BorderButton
                            active={this.activeControl}
                            onClick={() => this.activeControl = !this.activeControl}
                        >
                            <ZoomIcon/>
                        </BorderButton>
                    }
                    {
                        this.activeControl &&
                        <Control>
                            <ControlIcon onClick={this.handleZoomOut}><RemoveIcon/></ControlIcon>
                            <SliderWrapper>
                                {
                                    <Range
                                        step={this.step}
                                        min={this.minHeight}
                                        max={this.defaultHeight}
                                        values={this.sliderValues}
                                        onChange={this.onChangeTrack}
                                        renderTrack={this.renderTrack}
                                        renderThumb={this.renderThumb}
                                    />
                                }
                            </SliderWrapper>
                            <ControlIcon onClick={this.handleZoomIn}><AddIcon/></ControlIcon>
                        </Control>
                    }
                </ControlWrapper>
                <ImageWrapper>
                    <img style={{ width: this.width, height: this.height}} ref={this.imgRef} src={this.props.url} alt="" />
                </ImageWrapper>
            </>
        );
    }
}
