import styled from 'styled-components';

const setTextColor = (color, theme) => {
  switch (color) {
    case 'red':
      return theme.mainRedColor;
    case 'grey':
      return theme.subLabelTextColor;
    default:
      return theme.darkMainTextColor
  }
}

export const ContextualMenuItem = styled.div`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.OpenSansRegular};
  font-size: ${props => props.theme.mediumFontSize};
  color: ${props => props.disabled ? setTextColor('grey', props.theme) : setTextColor(props.color, props.theme)};
  line-height: 20px;
  padding: 3px 10px;
  
  ${props => props.isActive && `background-color: ${props.theme.contextualMenuItemActiveBG}`}
  
  a{
    color: ${props => props.disabled ? setTextColor('grey', props.theme) : setTextColor(props.color, props.theme)};
  }
  
  &:hover{
    background-color: ${props => !props.disabled && props.theme.contextualMenuItemHoverBG};
    
    a{
      text-decoration: none;
    }
  }
  
  &:active, &:focus{
    background-color: ${props => !props.disabled && props.theme.contextualMenuItemActiveBG};
    
    a{
      text-decoration: none;
    }
  }
`;
