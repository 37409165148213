/**
 *
 */

import React from 'react';
import { observer, inject } from 'mobx-react';
import './toaster.sass';
import { TOAST_TYPES } from 'MODEL_STORE/DataSource/Stores/ToasterStore';
import WarningCircleIcon from "../../icons/WarningCircleIcon";
import CloseCross from "../../icons/CloseCross";
import { base64toUtf }  from "GUI_MAIN/Utils/base64toUtf";

@inject('ToasterStore')
@observer
export class Toaster extends React.Component {

    constructor(props) {
        super(props);

        this.toast = React.createRef();
        this.addFromQuery();
    }

    addFromQuery = () => {

        const levelMap = {
            "info": TOAST_TYPES.INFO,
            "warn": TOAST_TYPES.WARNING,
            "warning": TOAST_TYPES.WARNING,
            "err": TOAST_TYPES.ERROR,
            "error": TOAST_TYPES.ERROR,
        };

        const params = new URLSearchParams(window.location.search);
        const detailsInfoEncoded = params.get("details");

        if (detailsInfoEncoded) {
            try {
                const data = JSON.parse(base64toUtf(detailsInfoEncoded));

                if (data["toast"]) {
                    const toastInfo = data["toast"];
                    if (toastInfo["level"] && toastInfo["message"]) {
                        this.props.ToasterStore.addToast(
                            toastInfo["message"],
                            {
                                type: levelMap[toastInfo["level"]],
                                autoClose: false,
                            }
                        );
                    }
                }

                const locationTarget =  window.location.pathname + (params.length ? "?" + params.toString() : "")
                window.history.replaceState(null, '', locationTarget);

            } catch {
                console.warn('error decoding query param "details"');
            }

        }

    }

    /**
     *
     */
    static TOAST_CLASSES = {
        [TOAST_TYPES.INFO]    : 'alert-success',
        [TOAST_TYPES.WARNING] : 'alert-warning',
        [TOAST_TYPES.ERROR]   : 'alert-danger',
    };

    /**
     *
     */
    getToastClass = (toast) => {
        return (
            (Toaster.TOAST_CLASSES[toast.type] || '') +
            (toast.flash ? ' alert-flash' : '')
        );
    }

    /**
     *
     */
    toastDismiss = (id) => {
        return this.props.ToasterStore.removeToast(id);
    }

    /**
     * Событие наведения мышки
     */
    onMouseOver = toast => {
        if (toast.timer) {
            toast.timer.pause();
        }
    };

    /**
     * Событие отвода мышки
     */
    onMouseLeave = toast => {
        if (toast.timer) {
            toast.timer.resume();
        }
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.changePosition);
    }

    componentDidMount() {
        this.changePosition();
        window.addEventListener("resize", this.changePosition);
    }

    changePosition = () => {
        if(document.documentElement.clientWidth > 1511) {
            const rightWidth = (document.documentElement.clientWidth - 1511) / 2;
            this.toast.current.style.right = rightWidth + 24 + "px";
        }
        else this.toast.current.style.right = 24 + "px";
    }

    /**
     *
     */
    render() {
        const toasts = this.props.ToasterStore.data;
        return (
            <div ref={this.toast} className="toaster-container">
                <div className="toaster-container_inner">
                    { Object.keys(toasts).map( key => {

                        const toast = toasts[key];
                        return (
                            <div key={key}
                                 className={'alert alert-dismissible ' + this.getToastClass(toast)}
                                 onMouseOver={() => this.onMouseOver(toast)}
                                 onMouseLeave={() => this.onMouseLeave(toast)}>
                                <div className="toast-body_wrapper">
                                    <div className="toast-body">
                                        <div className={'circle-icon circle-icon_' + this.getToastClass(toast)}>
                                            <WarningCircleIcon/>
                                        </div>
                                        <span style={{whiteSpace: "pre-wrap"}}>{ toast.message }</span>
                                        <button type="button"
                                                className={"close close_" + this.getToastClass(toast)}
                                                data-dismiss="alert"
                                                aria-label="Close"
                                                onClick={ e => this.toastDismiss(key) }
                                        >
                                            <CloseCross/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}
