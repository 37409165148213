import React from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import styled from "styled-components";

const NotificationsNumber = styled.div`
    position: absolute;
    top: -3px;
    right: -9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 14px;
    height: 14px;
    background-color: ${props =>props.theme.mainRedColor};
    border-radius: 7px;
    padding-top: 1px;
    padding-right: 1px;
`;

const TextNumber = styled.div`
    display: flex;
    line-height: 12px;
    font-size: ${props => props.theme.minFontSize};
    font-family: ${props => props.theme.OpenSansRegular};
    color: ${props => props.theme.whiteColor};
`;

@observer
export class NotificationsCount extends React.PureComponent {

    /**
     * необходимые свойства
     */
    static propTypes = {
        number: PropTypes.number.isRequired
    }

    /**
     * отрисовка компонента
     * отображаем счетчик только в том случае,
     * если есть непрочитанные сообщения
     */
    render() {
        if( ! this.props.number ) {
            return null;
        }
        return (
            <NotificationsNumber>
                <TextNumber>
                    { this.props.number }
                </TextNumber>
            </NotificationsNumber>
        )
    }
}
