import React, {PureComponent} from 'react';
import styled from 'styled-components';
import ReactTooltip from "react-tooltip";
import {Translator} from "eo-locale";
import {HeaderStore} from "MODEL_STORE/DataSource/Stores/HeaderStore";
import {observer} from 'mobx-react';
import {locales} from "Locale";

const IconWrapperExclamation = styled.span`
  color: ${props => props.theme.mainRedColor};
  cursor: default;
  margin-left: 4px;
`;

@observer
class RequiredFieldIcon extends PureComponent{

    componentDidMount() {
        ReactTooltip.rebuild();
    }

    render() {
        const translator = new Translator(HeaderStore.locale, locales);
        let {msg} = this.props;
        if (!msg) {
            msg = translator.getMessageById("SS009048")
        }

        return (
            <IconWrapperExclamation data-tip={msg}>
                *
            </IconWrapperExclamation>
        )
    }
}

export default RequiredFieldIcon;
