import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import styled from 'styled-components';

import { Viewer } from './Viewer';

import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import MinimizeIcon from '@material-ui/icons/Minimize';

const Overlay = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.87);
    z-index: 99999;
    outline: none;
    opacity: 1;
    transition: left 0.3s, bottom 0.3s, width 0.3s, height 0.3s, opacity 0.3s;

    &.minimized {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        left: 8px;
        bottom: 8px;
        width: 420px;
        height: auto;
        opacity: 0.7;
        background-color: transparent !important;

        & > div{
          top: auto !important;
        }
    }

`;

const Content = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 56px 0px 8px 0px;
    display: flex;
    align-items: center;
    justify-content: center;

`;

import { BorderButtons } from './components';
import { BorderButton } from './components';
import { EOLocale, useTranslator } from 'eo-locale';
import { HeaderStore } from 'MODEL_STORE/DataSource/Stores/HeaderStore';

const ButtonAddDocument = styled(BorderButton)``;
const ButtonMinimize = styled(BorderButton)``;
const ButtonMaximize = styled(BorderButton)``;
// const ButtonClose = styled(BorderButton)``;
const ButtonCancel = styled(BorderButton)`
    font-size: 14px;
    flex-basis: 70px;
`;
const ButtonClose = styled(BorderButton)`
    font-size: 32px;
`;

const MinimizedText = styled.div`
    flex: 100% 1 1;
    min-height: 48px;

    color: white;
    font-size: 14px;
    overflow: hidden;
    padding-left: 8px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const DocumentViewerFunc = ({ DocumentViewerStore }) => {

    const {
        minimized
    } = DocumentViewerStore;

    const [ addDocumentMode, changeAddDocumentMode ] = useState(false);
    const [ readyForRender, changeReadyForRender ] = useState(true);

    const translator = useTranslator(HeaderStore.locale);

    const refOverlay = useRef(null);

    /**
     * better keyboard works
     */
    useEffect(
        () => { refOverlay.current && refOverlay.current.focus(); }
    );

    useEffect(
        () => maximize(),
        [ DocumentViewerStore.version ]
    );

    /**
     *
     */
    const addDocument = () => {
        changeAddDocumentMode(true);
        minimize();
    }

    /**
     *
     */
    const cancelAdd = () => {
        changeAddDocumentMode(false);
        maximize();
    }

    /**
     *
     */
    const deleteElement = (index) => {
        DocumentViewerStore.remove(index);
    }

    /**
     *
     */
    const minimize = () => {
        DocumentViewerStore.minimize();
        changeReadyForRender(false);
    }

    /**
     *
     */
    const maximize = () => {
        DocumentViewerStore.maximize();
        changeAddDocumentMode(false);

        setTimeout(function() {
            changeReadyForRender(true);
        }, 400);
    }

    /**
     *
     */
    const changeOrder = (index, delta) => {
        const newIndex = index + delta;
        DocumentViewerStore.swap(index, newIndex);
    }

    /**
     *
     */
    const close = () => {
        DocumentViewerStore.clear();
    }

    /**
     *
     */
    const handleKeyboardInput = (e) => {
        if (e.key == 'Escape') {
            close();
        }
    }

    /**
     *
     */
    const renderMinimizedText = () => {

        if (addDocumentMode) {
            return <b><EOLocale.Text id="SS9035867" /></b>;
        }

        return DocumentViewerStore.files.map( (e, index) => (
            <div key={index}>
                { e.title }
            </div>
        ));
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////

    if (DocumentViewerStore.files.length == 0) {
        return null;
    }

    return (
        <Overlay className={minimized ? 'minimized' : ''} tabIndex="0" autoFocus onKeyUp={ handleKeyboardInput } ref={refOverlay}>

            { readyForRender &&
                <Content>
                    { DocumentViewerStore.files.map( (file, index) => {
                        return (
                            <Viewer
                                file={file}
                                key={file.url}
                                elementKey={index}
                                length={DocumentViewerStore.files.length}
                                deleteElement={() => deleteElement(index)}
                                changeOrderLeft={ DocumentViewerStore.files.length > 1 ? () => changeOrder(index, -1) : null }
                                changeOrderRight={ DocumentViewerStore.files.length > 1 ? () => changeOrder(index, 1) : null }
                                canRemove={DocumentViewerStore.files.length > 1 }
                                version={DocumentViewerStore.version}
                                minimize={minimize}
                                close={close}
                            />
                        );
                    })}
                </Content>
            }

            <BorderButtons minimized={minimized}>

                { minimized &&
                    <MinimizedText onClick={cancelAdd}>
                        { renderMinimizedText() }
                    </MinimizedText>
                }

                { ! minimized &&
                    <ButtonAddDocument
                        onClick={addDocument} title={translator.translate('SS9036330')}
                    >
                        <AddPhotoAlternateOutlinedIcon />
                    </ButtonAddDocument>
                }

                {/*{ minimized && addDocumentMode &&*/}
                {/*    <ButtonCancel onClick={cancelAdd}>*/}
                {/*        <EOLocale.Text id="SS9035868" />*/}
                {/*    </ButtonCancel>*/}
                {/*}*/}

                { minimized && ! addDocumentMode &&
                    <ButtonMaximize onClick={maximize} title={translator.translate('SS9035873')}>
                        <ZoomOutMapIcon />
                    </ButtonMaximize>
                }

                { ! minimized &&
                    <ButtonMinimize onClick={minimize} title={translator.translate('SS9035870')}>
                        <MinimizeIcon />
                    </ButtonMinimize>
                }
                { ! minimized &&
                    <ButtonClose onClick={close} title={translator.translate('SS9035869')} isToggleButton>
                        <CloseOutlinedIcon />
                    </ButtonClose>
                }
            </BorderButtons>

        </Overlay>

    );



}

const o = observer(DocumentViewerFunc)
const i = inject('DocumentViewerStore')(o);

export { i as DocumentViewer }
