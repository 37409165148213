import { observable, action } from 'mobx';

class SideMenuStoreClass {

    @observable
    isOpen = true

    // иногда наличие некоторых предметов на экране требует, чтобы меню было свернуто
    // например MIRA-440 (панель с документами открыта => меню свернуть)
    @observable
    shouldBeMinimized = false

    @action
    toggleSideMenu(isOpen) {
        this.isOpen = isOpen
    }

    @action
    toggleShouldBeMinimized(shouldBeMinimized) {
        this.shouldBeMinimized = shouldBeMinimized
    }
}

const SideMenuStore = new SideMenuStoreClass();

export {SideMenuStore};
