import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {setLineHeight} from "GUI_MAIN/Utils/setLineHeight";
import CompletedIcon from "../../icons/CompletedIcon";
import {overPos, StyledReactTooltip} from "../StyledReactTooltip"
import uuid from "uuid/v4";
import { Translator } from 'eo-locale';
import { HeaderStore } from 'MODEL_STORE/DataSource/Stores/HeaderStore';
import {locales} from "Locale";
import { observer } from 'mobx-react';

const setStatusFontColor = (status, theme) => {
    switch (status) {
        case 'created':
            return theme.statusCreatedFont;
        case 'deleted':
            return theme.statusDeletedFont;
        case 'assigned':
        case 'active':
            return theme.statusAssignedFont;
        case 'canceled':
            return theme.statusCanceledFont;
        case 'stopped':
            return theme.statusStoppedFont;
        case 'break':
            return theme.statusBreakFont;
        case 'finished':
            return theme.statusFinishedFont;
        case 'error':
            return theme.statusErrorFont;
        case 'matching':
            return theme.statusMatchingFont;
        default:
            return theme.statusCreatedFont;
    }
};

const setStatusBG = (status, theme) => {
    switch (status) {
        case 'created':
            return theme.statusCreatedBG;
        case 'deleted':
            return theme.statusDeletedBG;
        case 'assigned':
        case 'active':
            return theme.statusAssignedBG;
        case 'canceled':
            return theme.statusCanceledBG;
        case 'stopped':
            return theme.statusStoppedBG;
        case 'break':
            return theme.statusBreakBG;
        case 'finished':
            return theme.statusFinishedBG;
        case 'error':
            return theme.statusErrorBG;
        case 'matching':
            return theme.statusMatchingBG;
        default:
            return theme.statusCreatedBG;
    }
};

const Label = styled.p`
    font-size: ${props => props.theme.bigFontSize};
    line-height: ${props => setLineHeight(props.theme.bigFontSize, 1.4)};
    color: ${props => props.theme.patientInfoLabelColor};
    font-family: ${props => props.theme.OpenSansBold};
    margin-bottom: 0;
`;


const StatusWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: ${props => props.mainMargin || "0"};
`;

const StatusMessage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${props => props.theme.mediumFontSize};
    font-family: ${props => props.theme.OpenSansRegular};
    width: ${props => props.mainWidth || '141px'};
    min-width: 130px;
    height: 28px;
    border-radius: 4px;
    margin-left: ${props => props.notNeedMarginLeft ? "0" : "8px"};
    color: ${props => setStatusFontColor(props.status, props.theme)};
    background-color: ${props => setStatusBG(props.status, props.theme)};
    padding: ${props => props.mainPadding || "0"};
    white-space: nowrap;
    //${props => props.theme.statusBlockBG};
`;

const FinishedIconWrapper = styled.div`
  display: ${props => props.display};
  justify-content: center;
  align-items: center;
  width: 13px;
  height: 13px;
  margin-right: 4px;

  svg{
    width: 100%;
    height: 100%;
  }
`;

@observer
class Status extends PureComponent {
    static propTypes= {
        status: PropTypes.string.isRequired,
        statusText: PropTypes.string,
        comment: PropTypes.string,
    };

    renderStatus = (status, statusText) => {
        const translator = new Translator(HeaderStore.locale, locales);
        switch (status) {
            case 'unknown':
                return statusText ? statusText :  translator.translate('SS904190');
            case 'created':
                return statusText ? statusText :  translator.translate('SS904191');
            case 'deleted':
                return statusText ? statusText : translator.translate('SS904192');
            case 'assigned':
                return statusText ? statusText : translator.translate('SS904193');
            case 'active':
                return statusText ? statusText : translator.translate('SS904194');
            case 'canceled':
                return statusText ? statusText : translator.translate('SS904195');
            case 'stopped':
                return statusText ? statusText : translator.translate('SS904196');
            case 'break':
                return statusText ? statusText : translator.translate('SS904197');
            case 'finished':
                return statusText ? statusText : translator.translate('SS904198');
            case 'error':
                return statusText ? statusText : translator.translate('SS904199');
            case 'matching':
                return statusText ? statusText : translator.translate('SS904200')
        }
        return statusText || translator.translate('TT021370'); // заглушка (видимо не перевели)
    };

    render() {
        const {status, statusText, notNeedMarginLeft, mainMargin, dataTip, dataFor, mainWidth, mainPadding} = this.props;
        const id = dataFor + uuid();
        return (
            <>
                <StatusWrapper mainMargin={mainMargin} data-tip={dataTip} data-for={id}>
                    <StatusMessage status={status} notNeedMarginLeft={notNeedMarginLeft} mainWidth={mainWidth} mainPadding={mainPadding}>
                        <FinishedIconWrapper display={status === 'finished' ? "flex" : "none"}>
                            <CompletedIcon/>
                        </FinishedIconWrapper>
                        {this.renderStatus(status, statusText)}
                    </StatusMessage>
                </StatusWrapper>
                {
                    dataTip &&
                    <StyledReactTooltip
                        id={id}
                        type="light"
                        className={'light light-simple max-width-400'}
                        effect={"solid"}
                        place={"bottom"}
                        overridePosition={overPos}
                    />
                }
            </>
        );
    }
}

Status.propTypes = {};

export default Status;
