import React, { PureComponent } from "react";
import moment from "moment";
import ArrowBorder from "../../../icons/ArrowBorder";
import styled from "styled-components";
import IconButton from "../../IconButton";
import { CALENDAR } from "./consts";
import NativeSelect from "./NativeSelect";

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: center;

  .datepicker-custom-dropdown {
    .styledReactSelect__control {
      border-color: ${(props) => props.theme.whiteColor};
      min-height: 36px !important;

      &:hover {
        border-color: ${(props) => props.theme.whiteColor} !important;
        background-color: ${(props) =>
          props.theme.datePickerDayHoverBG} !important;
      }

      &--is-focused {
        border-radius: 4px !important;
      }
    }

    .styledReactSelect__single-value {
      font-family: ${(props) => props.theme.OpenSansSemiBold};
    }

    .styledReactSelect__dropdown-indicator {
      padding-top: 7px !important;
    }

    .styledReactSelect__option--is-selected {
      font-family: ${(props) => props.theme.OpenSansSemiBold};
    }

    .styledReactSelect__indicator {
      color: ${(props) => props.theme.controlBorderColor};
    }
  }
`;

class DatepickerCustomHeader extends PureComponent {

  get monthOption() {
    const { date } = this.props;

    if (date) {
      const option = CALENDAR.months.find(
        (i) => i.value === moment(date).month()
      );
      if (option) {
        return option;
      }
    }

    return undefined;
  }
  get yearsOptions() {
    let minYear = 1900;
    let maxYear = 2100;

    if (this.props.minDate && this.props.maxDate) {
      if ((typeof this.props.minDate === "string" && typeof this.props.maxDate === "string") ||
      (!this.props.minDate.year && !this.props.maxDate.year)
      ) {
        minYear = moment(this.props.minDate).year();
        maxYear = moment(this.props.maxDate).year();
      } else {
        minYear = this.props.minDate.year();
        maxYear = this.props.maxDate.year();
      }
    }

    const options = [];
    for (let i = minYear; i <= maxYear; i++) {
      options.push({
        name: i,
        value: i,
      });
    }
    return options;
  };

  get yearOption() {
    const { date } = this.props;

    if (date) {
      const option = this.yearsOptions.find(
        (i) => i.value === moment(date).year()
      );
      if (option) {
        return option;
      }
    }

    return undefined;
  }

  render() {
    const {
      changeYear,
      changeMonth,
      decreaseMonth,
      increaseMonth,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
    } = this.props;

    return (
      <HeaderWrapper>
        <IconButton
          icon={<ArrowBorder rotateArrow={90} />}
          onClick={!prevMonthButtonDisabled ? decreaseMonth : undefined}
        />
        <ControlsWrapper>
          <NativeSelect
            value={this.monthOption}
            onChange={(option) => changeMonth(option.value)}
            options={CALENDAR.months}
            selectWidth={100}
          />
          <NativeSelect
            value={this.yearOption}
            options={this.yearsOptions}
            onChange={(option) => changeYear(option.value)}
            selectWidth={70}
          />
        </ControlsWrapper>
        <IconButton
          icon={<ArrowBorder rotateArrow={270} />}
          onClick={!nextMonthButtonDisabled ? increaseMonth : undefined}
        />
      </HeaderWrapper>
    );
  }
}

DatepickerCustomHeader.propTypes = {};

export default DatepickerCustomHeader;
