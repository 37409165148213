import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class BigConsilium extends PureComponent {
    render() {
        const {stroke} = this.props;
        return (
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.6666 45.334H53.3333M10.6666 45.334V58.6673H53.3333V45.334M10.6666 45.334H5.33325M53.3333 45.334H58.6666" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M24 45.334H8C8.00034 43.334 9.59951 37.334 16 37.334C22.4001 37.334 24 43.334 24 45.334Z" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <ellipse cx="15.9999" cy="32.0007" rx="2.66667" ry="2.66667" stroke={stroke} strokeWidth="2"/>
                <path d="M40 45.334H24C24.0003 43.334 25.5995 37.334 32 37.334C38.4001 37.334 40 43.334 40 45.334Z" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <ellipse cx="31.9999" cy="32.0007" rx="2.66667" ry="2.66667" stroke={stroke} strokeWidth="2"/>
                <path d="M56 45.334H40C40.0003 43.334 41.5995 37.334 48 37.334C54.4001 37.334 56 43.334 56 45.334Z" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <ellipse cx="47.9999" cy="32.0007" rx="2.66667" ry="2.66667" stroke={stroke} strokeWidth="2"/>
                <path d="M37.3333 5.33398V4.33398C36.781 4.33398 36.3333 4.7817 36.3333 5.33398H37.3333ZM55.9999 5.33398H56.9999C56.9999 4.7817 56.5522 4.33398 55.9999 4.33398V5.33398ZM55.9999 16.0006V17.0006C56.5522 17.0006 56.9999 16.5529 56.9999 16.0006H55.9999ZM37.3333 16.0006H36.3333C36.3333 16.5529 36.781 17.0006 37.3333 17.0006V16.0006ZM43.9999 18.6673H42.9999V20.5358L44.5546 19.4994L43.9999 18.6673ZM37.3333 6.33398H55.9999V4.33398H37.3333V6.33398ZM54.9999 5.33398V16.0006H56.9999V5.33398H54.9999ZM38.3333 16.0006V5.33398H36.3333V16.0006H38.3333ZM43.9999 15.0006H37.3333V17.0006H43.9999V15.0006ZM42.9999 16.0006V18.6673H44.9999V16.0006H42.9999ZM55.9999 15.0006H47.9999V17.0006H55.9999V15.0006ZM47.9999 15.0006H43.9999V17.0006H47.9999V15.0006ZM44.5546 19.4994L48.5546 16.8327L47.4452 15.1686L43.4452 17.8353L44.5546 19.4994Z" fill={stroke}/>
                <path d="M26.6667 8V7C27.219 7 27.6667 7.44772 27.6667 8H26.6667ZM8.00008 8H7.00008C7.00008 7.44772 7.4478 7 8.00008 7V8ZM8.00008 18.6667V19.6667C7.4478 19.6667 7.00008 19.219 7.00008 18.6667H8.00008ZM26.6667 18.6667H27.6667C27.6667 19.219 27.219 19.6667 26.6667 19.6667V18.6667ZM20.0001 21.3333H21.0001V23.2019L19.4454 22.1654L20.0001 21.3333ZM26.6667 9H8.00008V7H26.6667V9ZM9.00008 8V18.6667H7.00008V8H9.00008ZM25.6667 18.6667V8H27.6667V18.6667H25.6667ZM20.0001 17.6667H26.6667V19.6667H20.0001V17.6667ZM21.0001 18.6667V21.3333H19.0001V18.6667H21.0001ZM8.00008 17.6667H16.0001V19.6667H8.00008V17.6667ZM16.0001 17.6667H20.0001V19.6667H16.0001V17.6667ZM19.4454 22.1654L15.4454 19.4987L16.5548 17.8346L20.5548 20.5013L19.4454 22.1654Z" fill={stroke}/>
            </svg>

        );
    }
}

BigConsilium.propTypes = {
    stroke: PropTypes.string,
};
BigConsilium.defaultProps = {
    stroke: '#A1A4B1',
}

export default BigConsilium;