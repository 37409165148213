import React, {PureComponent} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LabelWrapperHover = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  background-color: transparent;
  width: 32px;
  height: 32px;
  border-radius: 4px;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  min-height: 32px;
  padding-left: 6px;

  &:hover{
      ${LabelWrapperHover}{
        background-color: rgba(27, 117, 187, 0.1);
      }
  }
`;

const RadioButtonInput = styled.input`
  opacity: 0;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;

  &:checked + label:after {
    border: 6px solid ${props => props.disabled ? props.theme.checkedColorDisabled : props.theme.checkedColorBlue};
  }
`;

const setLabelColor = (theme, {isError, disabled}) => {
    if(disabled) return theme.checkedColorDisabled;

    if(isError) return theme.mainRedColor;

    return theme.CheckedLabelColorDefault;
};

const setBorderColor = (theme, {isError, disabled}) => {
    if(disabled) return theme.checkedColorDisabled;

    if(isError) return theme.mainRedColor;

    return theme.checkedColorBlue;
};

const Label = styled.label`
  position: relative;
  padding-left: 28px;
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.mediumFontSize};
  color: ${(props) => setLabelColor(props.theme,{isError: props.isError, disabled: props.disabled})};
  font-family: ${(props) => props.theme.OpenSansRegular};
  cursor: pointer;
  margin-bottom: 0;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${(props) => setBorderColor(props.theme, {isError: props.isError, disabled: props.disabled})};
    box-sizing: border-box;
    transition: border .3s ease;
  }
`;

class RadioButton extends PureComponent{
    static propTypes = {
        label: PropTypes.string,
        name: PropTypes.string,
        id: PropTypes.string,
        checked: PropTypes.bool,
        disabled: PropTypes.bool,
        isError: PropTypes.bool,
    };

    static defaultProps = {
        checked: false,
        disabled: false,
        isError: false,
    };

    render() {
        const { label, name, checked, id, onClick, disabled, isError} = this.props;

        return(
            <RadioButtonWrapper onClick={onClick} disabled={disabled}>
                {!disabled && <LabelWrapperHover/>}
                <RadioButtonInput type="radio"
                                  id={id}
                                  name={name}
                                  checked={checked}
                                  onChange={() => {}}
                                  disabled={disabled}/>
                <Label htmlFor={id} disabled={disabled} isError={isError}>{label}</Label>
            </RadioButtonWrapper>
        )
    }
}

export default RadioButton;
