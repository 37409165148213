import React from 'react';
import { config } from 'config';
import Logger from "src/logger";
import { useContext } from 'react';
import styled from 'styled-components';
import DicomIcon from "GUI_MAIN/icons/DicomIcon";
import {EOLocale} from "eo-locale";
import { ToasterStore } from "MODEL_STORE/DataSource/Stores/ToasterStore";
import { basicRequestGet } from 'MODEL_STORE/DataSource/Requests/basicRequestGet';
import { backendRequestPost } from 'MODEL_STORE/DataSource/Requests/backendRequestPost';
import { PatientServiceRequestDocumentContext } from "Contexts/PatientServiceRequestDocumentContext";

const Container = styled.div`
    background-color: white;
    border: 0px white solid;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;
const DownloadBox = styled.a`
    font-family: ${props => props.theme.OpenSansRegular};
    font-size: 11px;
    cursor: pointer;

  svg {
    width: 40px;
    height: 40px;
  }
`
const DownloadLink = styled.div`
    margin-top: 12px;
`

export function DicomViewer({ url }) {
    const context = useContext(PatientServiceRequestDocumentContext);
    const onClick = () => {
        basicRequestGet(url)
            .then(response => {
                const studyInstanceUID = response.data.identifier.find(identifier => identifier.system == "urn:dicom:uid").value
                backendRequestPost('/api/mm/viewer-url', {id: studyInstanceUID}).then(response => {
                    window.open(response.data.data.url);
                    if ( context && context.document && context.serviceRequest ) {
                        Logger.showDicomDocument({
                            "dicomId": url.split('/')[2],
                            "objectId": context.document.id,
                            "regId": context.serviceRequest.number.value,
                        });
                    }
                }).catch(error => {
                    console.log(error);
                    ToasterStore.error("Не удалось открыть документ");
                })
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <Container>
            <DownloadBox onClick={onClick} target="_blank">
                <DicomIcon />
            </DownloadBox>
        </Container>
    );


}
