import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class BigPatient extends PureComponent {
    render() {
        const {stroke} = this.props;
        return (
            <svg width="50" height="35" viewBox="0 0 50 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="48" height="32.3333" rx="2" stroke={stroke} strokeWidth="2"/>
                <path d="M19.6667 22.332V22.332C19.6667 19.3865 17.2789 16.9987 14.3334 16.9987V16.9987C11.3879 16.9987 9.00008 19.3865 9.00008 22.332V22.332" stroke={stroke} strokeWidth="2"/>
                <circle cx="14.3333" cy="11.6654" r="4.33333" stroke={stroke} strokeWidth="2"/>
                <path d="M25.6667 11H41.0001" stroke={stroke} strokeWidth="2" strokeLinecap="round"/>
                <path d="M25.6667 16.332H41.0001" stroke={stroke} strokeWidth="2" strokeLinecap="round"/>
                <path d="M25.6667 21.332H41.0001" stroke={stroke} strokeWidth="2" strokeLinecap="round"/>
                <path d="M8.66675 27.332H41.0001" stroke={stroke} strokeWidth="2" strokeLinecap="round"/>
            </svg>
        );
    }
}

BigPatient.propTypes = {
    stroke: PropTypes.string,
};

BigPatient.defaultProps = {
    stroke: '#A1A4B1',
}

export default BigPatient;