import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class IconFileXlsx extends PureComponent{

    static propTypes = {
        fill: PropTypes.string,
    }

    static defaultProps = {
        fill: '#ffffff',
    }

    render(){
        const {fill} = this.props;
        return(
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.0013 5H6.66797V35H10.0013H15.0013H33.3346V17.5V13.3333M25.0013 5L33.3346 13.3333M25.0013 5V13.3333H33.3346" stroke="#121232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13.5169 24.1719L14.2835 22.7168H15.9925L14.6009 25.29L16.0609 28H14.3421L13.512 26.4375L12.6966 28H10.9681L12.4329 25.29L11.0462 22.7168H12.7747L13.5169 24.1719ZM18.2581 28H16.6077V20.5H18.2581V28ZM22.0423 26.5156C22.0423 26.3757 21.9691 26.2633 21.8226 26.1787C21.6761 26.0941 21.3978 26.0062 20.9876 25.915C20.5775 25.8239 20.2389 25.7051 19.972 25.5586C19.7051 25.4089 19.5016 25.2282 19.3617 25.0166C19.2217 24.805 19.1517 24.5625 19.1517 24.2891C19.1517 23.804 19.3519 23.4053 19.7523 23.0928C20.1527 22.777 20.6768 22.6191 21.3245 22.6191C22.0212 22.6191 22.5811 22.777 23.0042 23.0928C23.4274 23.4085 23.639 23.8236 23.639 24.3379H21.9886C21.9886 23.9147 21.7656 23.7031 21.3197 23.7031C21.1471 23.7031 21.0023 23.752 20.8851 23.8496C20.7679 23.944 20.7093 24.0628 20.7093 24.2061C20.7093 24.3525 20.7809 24.4714 20.9242 24.5625C21.0674 24.6536 21.2952 24.7285 21.6077 24.7871C21.9235 24.8457 22.2002 24.9157 22.4378 24.9971C23.2321 25.2705 23.6292 25.7604 23.6292 26.4668C23.6292 26.9486 23.4144 27.3408 22.9847 27.6436C22.5583 27.9463 22.0049 28.0977 21.3245 28.0977C20.8721 28.0977 20.4684 28.0163 20.1136 27.8535C19.7588 27.6908 19.4821 27.4694 19.2835 27.1895C19.085 26.9095 18.9857 26.6149 18.9857 26.3057H20.5238C20.5303 26.5498 20.6117 26.7288 20.7679 26.8428C20.9242 26.9535 21.1243 27.0088 21.3685 27.0088C21.5931 27.0088 21.7607 26.9632 21.8714 26.8721C21.9854 26.7809 22.0423 26.6621 22.0423 26.5156ZM26.5443 24.1719L27.3109 22.7168H29.0199L27.6283 25.29L29.0882 28H27.3695L26.5394 26.4375L25.724 28H23.9954L25.4603 25.29L24.0736 22.7168H25.8021L26.5443 24.1719Z" fill="black"/>
            </svg>
        )
    }
}
