import { config } from 'config';

/**
 *
 */
export function basicAuthHeaders() {
    return {
        'Authorization' : 'Basic ' + btoa(config.get('AIDBOX_CLIENT_ID') + ':' + config.get('AIDBOX_CLIENT_SECRET'))
    };
}
