import UTIF from "utif";
import React from "react";
import { observable } from "mobx";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import styled from "styled-components";
import { BorderButton } from "./components";
import AddIcon from "@material-ui/icons/Add";
import ZoomIcon from "GUI_MAIN/icons/ZoomIcon";
import RemoveIcon from "@material-ui/icons/Remove";
import { Range } from 'react-range';

/**
 * 
 */
const TiffWrapper = styled.div`
    left: 0;
    position: absolute;
    top: 48px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    right: 0;
    margin: auto;
`;

/**
 * 
 */
const ControlWrapper = styled.div`
    position: absolute;
    top: 0;
    z-index: 10;
`;

/**
 * 
 */
const ZoomControl = styled.div`
    position: absolute;
    z-index: 100;
    background-color: #000000;
    left: -111px;
    padding: 14px 11px;
    width: 270px;
    margin-top: 10px;
    display: flex;
    align-items: center;
`;

const ZoomControlInIcon = styled.div`
    height: 27px;
    width: 18px;
    cursor: pointer;
    margin-left: 6px;
    svg {
        width: 18px;
        height: 18px;
        fill: #ffffff;
    }
`;

const ZoomControlOutIcon = styled.div`
    height: 27px;
    width: 18px;
    cursor: pointer;
    margin-right: 6px;
    svg {
        width: 18px;
        height: 18px;
        fill: #ffffff;
    }
`;

/**
 * отображение image/tiff
 */
@observer
export class TiffViewer extends React.Component {

    /**
     * необходимые свойства
     */
    static propTypes = {
        url: PropTypes.string.isRequired
    }

    /**
     * значения зума
     */
    @observable zoom = {
        isVisible: false,
        step: 1,
        min: 10,
        max: 110,
        value: 60,
    }

    /**
     * ссылка на элемент
     */
    canvas = React.createRef();

    /**
     * 1. получаем данные
     * 2. конвертируем
     * 3. рисуем холст
     */
    renderCanvas = () => {
        fetch(this.props.url).then(resp => resp.arrayBuffer()).then(buffer => {
            let ifd = UTIF.decode(buffer)[0];
            UTIF.decodeImage(buffer, ifd);
            const rgba = UTIF.toRGBA8(ifd);
            this.canvas.current.width = ifd.width;
            this.canvas.current.height = ifd.height;
            const context = this.canvas.current.getContext("2d");
            const image = context.createImageData(ifd.width, ifd.height);
            image.data.set(rgba);
            context.putImageData(image, 0, 0);
            this.onZoomChange(this.zoom.value);
        })
    }

    /**
     * при монтировании компонента
     */
    componentDidMount() {
        this.renderCanvas();
    }

    /**
     * при обновлении компонента
     */
    componentDidUpdate(prevProps) {
        if ( prevProps.url != this.props.url ) {
            this.renderCanvas();
        }
    }

    /**
     * при клике на иконку зума
     */
    onZoomIconClick = () => {
        this.zoom.isVisible = ! this.zoom.isVisible;
    }

    /**
     * изменение зума
     */
    onZoomChange = (value) => {
        this.zoom.value = value;
        if ( this.canvas.current ) {
            this.canvas.current.style.transform = `scale(${this.zoom.value / this.zoom.max})`;
        }
    }

    /**
     * увеличение масштаба
     */
    onZoomIncrease = () => {
        if ( this.zoom.value < this.zoom.max ) {
            this.onZoomChange(this.zoom.value + this.zoom.step);
        }
    }

    /**
     * уменьшение масштаба
     */
    onZoomDecrease = () => {
        const zoom = this.zoom.value - this.zoom.step;
        if ( this.zoom.value > this.zoom.step && zoom >= this.zoom.min ) {
            this.onZoomChange(zoom);
        }
    }

    get trackBackgroundValue() {
        return this.zoom.value - this.zoom.min;
    }

    get trackBackground() {
        if (this.trackBackgroundValue >= 50) {
            return `linear-gradient(to right, #ccc 0%, #ccc 50%, #548BF4 50%, #548BF4 ${this.trackBackgroundValue}%, #ccc ${this.trackBackgroundValue}%, #ccc 100%)`;
        }
        return `linear-gradient(to right, #ccc 0%, #ccc ${this.trackBackgroundValue}%, #548BF4 ${this.trackBackgroundValue}%, #548BF4 50%, #ccc 50%, #ccc 100%)`;
    }

    /**
     * Обработчик трека
     */
    renderTrack = ({ props, children }) => {
        return (
            <div
                {...props}
                style={{
                    ...props.style,
                    height: '3px',
                    width: '100%',
                    background: this.trackBackground,
                }}
            >
                {children}
            </div>
        )
    }

    /**
     * Внешний вид ползунка
     */
    renderThumb = ({ props }) => {
        return (
            <div
                {...props}
                style={{
                    ...props.style,
                    height         : '20px',
                    width          : '8px',
                    backgroundColor: '#1B75BB',
                    // display: 'inline-block',
                    borderRadius: '30px',
                }}
            />
        )
    }

    /**
     * отрисовка
     *
     */
    render() {
        return (
            <React.Fragment>
                <ControlWrapper>
                    <BorderButton
                        children={(<ZoomIcon/>)}
                        active={this.zoom.isVisible}
                        onClick={this.onZoomIconClick}
                    />
                    { this.zoom.isVisible && (
                        <ZoomControl>
                            <ZoomControlOutIcon
                                children={(<RemoveIcon/>)}
                                onClick={this.onZoomDecrease}
                            />
                            {
                                <Range
                                    step={this.zoom.step}
                                    min={this.zoom.min}
                                    max={this.zoom.max}
                                    values={[this.zoom.value]}
                                    onChange={(values) => this.onZoomChange(values[0])}
                                    renderTrack={this.renderTrack}
                                    renderThumb={this.renderThumb}
                                />
                            }
                            <ZoomControlInIcon
                                children={(<AddIcon/>)}
                                onClick={this.onZoomIncrease}
                            />
                        </ZoomControl>
                    ) }
                </ControlWrapper>
                <TiffWrapper>
                    <canvas ref={this.canvas}/>
                </TiffWrapper>
            </React.Fragment>
        )
    }
}
