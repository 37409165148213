// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMWWWWNNNNNNWWWMMMMMMMMMMMMM
// MMMMMMMMMMMWWNXXXXXXXXXXXXXNNWMMMMMMMMMM
// MMMMMMMMMWNXXXXXXXXXXXXXXXXXXXNWMMMMMMMM
// MMMMMMMMWNXXXXXXXXXXXXXXXXXXXXXXWMMMMMMM
// MMMMMMMWNXXXXXXNNXXXXXXXNNNXXXXXXNMMMMMM
// MMMMMMWNXXXXXXXNWWNXXXNWWNXXXXXXXXWMMMMM
// MMMMMMWXXXXXXXXXXNWWWWWNXXXXXXXXXXWMMMMM
// MMMMMMWXXXXXXXXXXNWWWWWNXXXXXXXXXXNMMMMM
// MMMMMMWNXXXXXXXNWWNXXXNWWNXXXXXXXXWMMMMM
// MMMMMMMWXXXXXXXNNXXXXXXXNNNXXXXXXNMMMMMM
// MMMMMMMMWXXXXXXXXXXXXXXXXXXXXXXXNMMMMMMM
// MMMMMMMMMWNXXXXXXXXXXXXXXXXXXXNWMMMMMMMM
// MMMMMMMMMMMWNNXXXXXXXXXXXXXXNWMMMMMMMMMM
// MMMMMMMMMMMMMMWWNNNNNNNNNWWWMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM


import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class Close extends PureComponent{

    static propTypes = {
        fill: PropTypes.string,
        stroke: PropTypes.string,
        opacity: PropTypes.string,
    };

    static defaultProps = {
        fill: "#3E465F",
        stroke: "white",
        opacity: '0.15'
    };

    render(){
        const {fill, stroke, opacity} = this.props;
        return(

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity={opacity} cx="12" cy="12" r="8" fill={fill}/>
                <path d="M9 9L12 12M15 15L12 12M12 12L9 15M12 12L15 9" stroke={stroke} strokeWidth="2" strokeLinecap="round"/>
            </svg>

        )
    }
}
