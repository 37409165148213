import React, {PureComponent} from 'react';
import styled, {keyframes, css} from 'styled-components';
import {EOLocale, Translator} from "eo-locale";
import {locales} from "../../../Locale";
import {lang_ru} from "../../../Locale/languages/ru";
import {HeaderStore} from "MODEL_STORE/DataSource/Stores/HeaderStore";
import PropTypes from "prop-types";

const setSize = (size) => {
    switch (size) {
        case "small":
            return "1.5rem";
        default:
            return "3rem"
    }
};

const setPadding = (padding) => {
    switch (padding) {
        case "small":
            return "0";
        default:
            return "48px 24px"
    }
};

const preloaderBorderSize = ".1rem",
      preloaderSpinDuration = "10s",
      preloaderSpinDurationFast = "2s";

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const PreloaderWrapper = styled.div`
  width: 100%;
  ${props => {if(props.fullScreen) return  'height: 100%'}};
  padding: ${props => props.padding ? props.padding : setPadding(props.mainPadding)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${props => props.direction};
  background-color: ${props => props.BGcolor || "transparent"};
  margin-top: ${props => props.marginTop ? props.marginTop : '0'};

  ${props => {
      if (props.overlayCenter)
        return 'position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: #c1def373; z-index: 1000; height: 100%;'}}
`;

const PreloaderInlineWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const PreloaderBlock = styled.div`
  width: ${props => setSize(props.size)};
  height: ${props => setSize(props.size)};
  border: ${preloaderBorderSize} solid ${props => props.preloaderColor ? props.preloaderColor : props.theme.darkMainTextColor};
  border-radius: 100%;
  position: relative;
  ${props => props.direction === 'column' && 'margin-bottom: 10px'};
  ${props => props.direction === 'row' && 'margin-right: 5px'};

  :after, :before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -.1rem 0 0 -.1rem;
    border: ${preloaderBorderSize} solid ${props => props.preloaderColor ? props.preloaderColor : props.theme.darkMainTextColor};
    border-radius: .6rem;
    transform-origin: .1rem center;
  }

  :before {
    width: 30%;
    background-color: ${props => props.preloaderColor ? props.preloaderColor : props.theme.darkMainTextColor};
    animation: ${rotate} ${preloaderSpinDuration} linear infinite;
  }

  :after {
    width: 40%;
    background-color: ${props => props.preloaderColor ? props.preloaderColor : props.theme.darkMainTextColor};
    animation: ${rotate} ${preloaderSpinDurationFast} linear infinite;
  }

`;

const Text = styled.span`
  font-family: ${props => props.theme.OpenSansSemiBold};
  line-height: 20px;
  font-size: ${props => props.theme.bigFontSize};
  color: ${props => props.preloaderColor ? props.preloaderColor : props.theme.darkMainTextColor};
  text-align: center;
  //max-width: 20%;
`;

class Preloader extends PureComponent{
    static propTypes = {
        direction: PropTypes.string,
    };

    static defaultProps = {
        direction: 'column',
    };

    render() {
        const {
            text,
            BGcolor,
            size,
            fullScreen,
            customMarginTop,
            overlayCenter,
            inline,
            padding,
            direction, //row | column
            preloaderColor
        } = this.props;
        let Wrapper = PreloaderWrapper;
        if (inline) {
            Wrapper = PreloaderInlineWrapper;
        }
        const translator = new Translator(HeaderStore.locale, locales);

        const placeholderText =  lang_ru.messages["SS000008"] ?
            translator.getMessageById("SS000008") : "";
        return (
            <Wrapper
                marginTop={customMarginTop}
                mainPadding={size}
                BGcolor={BGcolor}
                fullScreen={fullScreen}
                overlayCenter={overlayCenter}
                padding={padding}
                direction={direction}
            >
                <PreloaderBlock size={size} direction={direction} preloaderColor={preloaderColor}/>
                <Text preloaderColor={preloaderColor}>{text || placeholderText}</Text>
            </Wrapper>
        )
    }
}

export default Preloader;
