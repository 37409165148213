import React from 'react';
import styled, { withTheme } from 'styled-components';
import CheckedIcon from 'GUI_MAIN/icons/CheckedIcon';
import _get from 'lodash/get';
import PropTypes from "prop-types";
import CircleCheckedIcon from "../../icons/CircleCheckedIcon";
import {StyledReactTooltip} from "../StyledReactTooltip";
import RequiredFieldIcon from "../RequiredFieldIcon";
import { EOLocale } from 'eo-locale';

const LabelWrapperHover = styled.div`
  position: absolute;
  left: 0;
  top: ${ props => props.centered ? "50%" : "10px" };
  transform: translateY(-50%);
  display: flex;
  background-color: transparent;
  width: 32px;
  height: 32px;
  border-radius: 4px;
`;

const IconWrapper = styled.div`
    width: ${props => setBorderBoxSize(props.size)};
    height: ${props => setBorderBoxSize(props.size)};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 7px;
    top: ${ props => props.centered ? "50%" : "10px" };
    transform: translateY(-50%);
    transition: all .3s ease;

    svg {
        width: ${props => props.size === 'small' ? '8px' : '12px'} !important;
        height: ${props => props.size === 'small' ? '8px' : '10px'} !important;
    }

    &:hover{
        background-color: ${props => !props.disabled && 'rgba(27, 117, 187, 0.1)'};
    }
`;

const CheckboxWrapper = styled.div`
    ${ props => props.mainWidth && `width: ${ props.mainWidth }` };
    position: relative;
    display: flex;
    align-items: center;
    margin: ${props => props.mainMargin || "0"};
    padding-left: 7px;
    visibility: ${({visible=true}) => !visible && 'hidden'};
    cursor: ${props => props.disabled ? "default" : "pointer"};
    ${props => props.themeType === "blue" && "width: 20px; height: 20px"};

    &:hover{
      ${LabelWrapperHover}{
        background-color: ${props => !props.disabled && !props.themeType && 'rgba(135, 138, 195, 0.1)'};
      }
      ${IconWrapper}{
        background-color: ${props => props.disabled && 'unset'};
      }
    }
`;

const setLabelColor = (theme, {checked, isError, disabled, isDarkenBG}) => {
    if(disabled) return theme.checkedColorDisabled;

    if(isError) return theme.mainRedColor;

    if(isDarkenBG) return "#ffffff"

    return theme.CheckedLabelColorDefault;
};

const setBorderColor = (theme, {isError, disabled, isDarkenBG, useDarkStyle, themeType}) => {
    if(themeType === 'blue') return '#1B75BB';

    if(useDarkStyle) return theme.checkedColorDefault

    if(disabled) return theme.checkedColorDisabled;

    if(isError) return theme.mainRedColor;

    if(isDarkenBG) return "#ffffff";

    return theme.mainDarkBlueColor;
};

const setBorderBoxSize = (size) => {
    if(size === 'small') return '12px';
    if(size === 'big') return '20px';

    return '18px';
};

const LabelWrapper = styled.label`
    display: flex;
    height: 100%;
    padding-left: 29px;
    font-size: ${(props) => props.labelFontSize || props.theme.mediumFontSize};
    color: ${(props) => setLabelColor(props.theme,{checked: props.checked, isError: props.isError, disabled: props.disabled, isDarkenBG: props.isDarkenBG})};
    font-family: ${(props) => props.theme.OpenSansRegular};
    cursor: inherit;
    margin-bottom: 0;
    position: relative;
    ${(props) => (!props.label ? 'height: 8px' : '')};

    :before {
        content: '';
        position: absolute;
        border: 1.4px solid ${(props) => setBorderColor(props.theme, {isError: props.isError, disabled: props.disabled, isDarkenBG: props.isDarkenBG, useDarkStyle: props.useDarkStyle, themeType: props.themeType})};
        width: ${props => setBorderBoxSize(props.size)};
        height: ${props => setBorderBoxSize(props.size)};
        left: 0;
        top: ${ props => props.centered ? "50%" : "10px" };
        transform: translateY(-50%);
        border-radius: ${props => props.themeType ? '50%' : '2px'};
        ${props => props.disabled && 'opacity: 0.5'};
    }
`;

const setBorderCheckedColor = (theme, {checked, isError, disabled, isDarkenBG, useDarkStyle, themeType}) => {
    if(themeType === 'blue') return '#54A3E0';

    if(useDarkStyle) return theme.checkedColorDefault

    if(disabled && !checked) {
         return theme.checkedColorDisabled;
    }

    if(disabled && checked) {
        return "#BDBDBD";
    }

    if(isError) return theme.mainRedColor;

    if(isDarkenBG) return "#ffffff";

    return theme.mainDarkBlueColor;
};

const CheckboxInput = styled.input`
    width: ${props => setBorderBoxSize(props.size)};
    height: ${props => setBorderBoxSize(props.size)};
    position: absolute;
    display: block;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;

    :checked + label:before {
        border: 1.4px solid ${(props) => setBorderCheckedColor(props.theme, {checked: props.checked, isError: props.isError, disabled: props.disabled, isDarkenBG: props.isDarkenBG, useDarkStyle: props.useDarkStyle, themeType: props.themeType})};
        background-color: ${ props => !props.disabled ? props.theme.mainDarkBlueColor : "#BDBDBD"};
    }
`;

const IconWrapperBlue = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 6px;
  top: 16px;
  transform: translateY(-50%);
  border-radius: 50%;
  ${props => props.checked && "background-color: #54A3E0"};

  svg{
    width: 17px;
    height: 9px;
    opacity: ${props => props.checked ? "1" : "0"};
  }
`;


@withTheme
export class StyledCheckbox extends React.Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        value: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        id: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        visible: PropTypes.bool,
        isError: PropTypes.bool,
    };

    static defaultProps = {
        onChange: () => {},
        disabled: false,
        visible: true,
        isError: false,
        value: false
    };

    toggleChecked = () => {
        const {disabled, onChange} = this.props;
        if (disabled) {
            return;
        }
        onChange(!this.props.value);
    };

    render() {
        const {
            id,
            label,
            labelFontSize,
            theme,
            disabled,
            mainWidth,
            mainMargin,
            visible,
            size,
            isError,
            isDarkenBG,
            useDarkStyle,
            themeType,
            requiredField,
            tooltip,
            centered
        } = this.props;

        const isChecked = !!this.props.value;

        return (
            <CheckboxWrapper
                mainWidth={mainWidth}
                isCheckedAndDisabled={isChecked && disabled}
                disabled={disabled}
                mainMargin={mainMargin}
                visible={visible}
                themeType={themeType}
                data-tip
                data-for={'default-tooltip-'+id}
            >
                <LabelWrapperHover centered={centered}/>
                <CheckboxInput
                    id={id}
                    type="checkbox"
                    onChange={this.toggleChecked}
                    checked={isChecked}
                    disabled={disabled}
                    size={size}
                    isDarkenBG={isDarkenBG}
                    useDarkStyle={useDarkStyle}
                    themeType={themeType}
                />
                <LabelWrapper
                    label={label} htmlFor={id}
                    disabled={disabled}
                    labelFontSize={labelFontSize}
                    size={size}
                    isError={isError}
                    isDarkenBG={isDarkenBG}
                    useDarkStyle={useDarkStyle}
                    themeType={themeType}
                    checked={isChecked}
                    centered={centered}
                >
                    {label}
                    {
                        requiredField && !disabled &&
                        <RequiredFieldIcon/>
                    }
                </LabelWrapper>
                {
                    themeType === 'blue' &&
                    <IconWrapperBlue
                        onClick={this.toggleChecked}
                        checked={isChecked}
                    >
                        <CircleCheckedIcon fill={isChecked ? '#ffffff' : '#D3D4D8'}/>
                    </IconWrapperBlue>
                }
                {
                    isChecked && themeType !== 'blue' && (
                    <IconWrapper
                        onClick={this.toggleChecked}
                        size={size}
                        isDarkenBG
                        centered={centered}
                    >
                        <CheckedIcon
                            fill={disabled && !isChecked ? theme.checkedColorDisabled : isDarkenBG ? "#ffffff" : useDarkStyle ? theme.checkedColorDefault : "#ffffff"}/>
                    </IconWrapper>
                    )
                }
                {
                    tooltip &&
                    <StyledReactTooltip
                        id={'default-tooltip-'+id}
                        className={'type-light light light-simple shadow'}
                    >
                        {tooltip}
                    </StyledReactTooltip>
                }
            </CheckboxWrapper>
        );
    }
}
