import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class CompletedIcon extends PureComponent {
    static defaultProps = {
        fill: "#95A5A6"
    }

    render() {
        const {fill} = this.props
        return (
            <svg width="14" height="14" viewBox="0 0 14 14" fill={fill} xmlns="http://www.w3.org/2000/svg">
                <path d="M7 0.333313C3.32 0.333313 0.333334 3.31998 0.333334 6.99998C0.333334 10.68 3.32 13.6666 7 13.6666C10.68 13.6666 13.6667 10.68 13.6667 6.99998C13.6667 3.31998 10.68 0.333313 7 0.333313ZM7 12.3333C4.06 12.3333 1.66667 9.93998 1.66667 6.99998C1.66667 4.05998 4.06 1.66665 7 1.66665C9.94 1.66665 12.3333 4.05998 12.3333 6.99998C12.3333 9.93998 9.94 12.3333 7 12.3333Z"/>
                <path d="M10.06 4.05328L5.66667 8.44662L3.94 6.72662L3 7.66662L5.66667 10.3333L11 4.99995L10.06 4.05328Z"/>
            </svg>
        );
    }
}

CompletedIcon.propTypes = {
    fill: PropTypes.string,
};

export default CompletedIcon;