// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMKockWMMMMMMMMMMMMNxcxNMMMMMMMMM
// MMMMMMN0kc. ,xkkkkkkkkkkkko. .oOKWMMMMMM
// MMMMMK:. .   ..............   . .oNMMMMM
// MMMMMx. ;OOOOOOOOOOOOOOOOOOOOOx' '0MMMMM
// MMMMMx. cXNNNNNNNNNNNNNNNNNNNNK; '0MMMMM
// MMMMMx. .'''''''''''''''''''''.  '0MMMMM
// MMMMMx. .clllllllllllllllllcll:. '0MMMMM
// MMMMMx. lWMMMMMMMMMMMMMMMMMMMMX; '0MMMMM
// MMMMMx. lWMMMMMMMMMMMMMMMMMMMMX; '0MMMMM
// MMMMMx. lWMMMMMMMMMMMMMMMMMMMMX; '0MMMMM
// MMMMMx. lWMMMMMMMMMMMMMMMMMMMMX; '0MMMMM
// MMMMMx. lWMMMMMMMMMMMMMMMMMMMMX; '0MMMMM
// MMMMMx. lWMMMMMMMMMMMMMMMMMMMMX; '0MMMMM
// MMMMMx. :0KKKKKKKKKKKKKKKKKKKKO, '0MMMMM
// MMMMM0,  ......................  cXMMMMM
// MMMMMMXkdddddddddddddddddddddddxONMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM


import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class Calendar extends PureComponent{
    static propTypes = {
        fill: PropTypes.string,
    };

    static defaultProps = {
        fill: "#BDBDBD",
    };
    render(){
        const {fill} = this.props;
        return(
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.25 8.25V9.75H6.75V8.25H5.25Z" fill={fill}/>
                <path d="M5.25 12.75V11.25H6.75V12.75H5.25Z" fill={fill}/>
                <path d="M9.75 8.25H8.25V9.75H9.75V8.25Z" fill={fill}/>
                <path d="M8.25 11.25H9.75V12.75H8.25V11.25Z" fill={fill}/>
                <path d="M11.25 8.25V9.75H12.75V8.25H11.25Z" fill={fill}/>
                <path d="M11.25 12.75V11.25H12.75V12.75H11.25Z" fill={fill}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M14.25 2.25H13.5V0.75H12V2.25H6V0.75H4.5V2.25H3.75C2.9175 2.25 2.25 2.925 2.25 3.75V14.25C2.25 15.075 2.9175 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V3.75C15.75 2.925 15.075 2.25 14.25 2.25ZM14.25 14.25H3.75V6.75H14.25V14.25ZM14.25 5.25H3.75V3.75H14.25V5.25Z" fill={fill}/>
            </svg>

        )
    }
}