// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMWXOxdoooodxOKNMMMMMMMMMMMMM
// MMMMMMMMMMNOollodxkOOkxdollokXWMMMMMMMMM
// MMMMMMMWKdclx0NMMMWWWWMMMWKklcoOWMMMMMMM
// MMMMMW0l:o0WMMMMXxllolo0WMMMWKdcckNMMMMM
// MMMMWd'lKWMMMMMNl;kXX0:;KMMMMMMNd'lXMMMM
// MMMMWOc:xXWMMMMNo,dOOx;cKMMMMMNkc:kNMMMM
// MMMMMMNOl:o0WMMMNOooooxXMMMWKdccxXMMMMMM
// MMMMMMMMWKdclokKNWMMMMWWXOdlcoONMMMMMMMM
// MMMMMMMMMMMN0xolloooooollodONMMMMMMMMMMM
// MMMMMMMMMMMMMMMWNK0000KXWMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM


import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class Eye extends PureComponent{

    static propTypes = {
        fill: PropTypes.string,
    }

    static defaultProps = {
        fill: '#F0F1F3',
    }

    render(){
        const {fill, close} = this.props;
        if (!close)
            return (
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.44446 8.30072C2.15367 7.94413 1.63755 7.89809 1.29168 8.19789C0.945808 8.49769 0.901154 9.02981 1.19194 9.3864C1.43792 9.68804 1.8229 10.1375 2.32213 10.648L1.23964 11.7641C0.92012 12.0935 0.92012 12.6276 1.23964 12.957C1.55916 13.2864 2.0772 13.2864 2.39672 12.957L3.53454 11.7839C3.79261 12.0045 4.06607 12.2251 4.35336 12.4402C4.7682 12.7508 5.21836 13.0549 5.69843 13.3325L4.79843 14.8789C4.56595 15.2784 4.69159 15.7966 5.07906 16.0363C5.46654 16.276 5.96911 16.1464 6.2016 15.7469L7.18808 14.0518C7.82097 14.2967 8.48855 14.4766 9.1818 14.558V16.1565C9.1818 16.6223 9.54811 17 9.99998 17C10.4518 17 10.8182 16.6223 10.8182 16.1565V14.5582C11.512 14.477 12.1798 14.2975 12.8127 14.0531L13.7984 15.7469C14.0309 16.1464 14.5335 16.2759 14.9209 16.0362C15.3084 15.7965 15.4341 15.2784 15.2016 14.8789L14.3028 13.3345C14.7839 13.0567 15.2348 12.7522 15.6503 12.441C15.937 12.2262 16.2098 12.0061 16.4673 11.7859L17.6032 12.957C17.9228 13.2864 18.4408 13.2864 18.7603 12.957C19.0798 12.6276 19.0798 12.0935 18.7603 11.7641L17.6792 10.6494C18.1782 10.1386 18.5628 9.68869 18.8086 9.38659C19.099 9.02968 19.0538 8.49762 18.7077 8.19819C18.3615 7.89877 17.8454 7.94537 17.555 8.30228C17.0842 8.88088 16.0531 10.054 14.6885 11.0761C13.3136 12.1059 11.6843 12.9193 10 12.9193C8.32012 12.9193 6.69125 12.1057 5.31497 11.0752C3.94926 10.0525 2.916 8.87898 2.44446 8.30072Z"
                  fill={fill}/>
              </svg>
            );
        else
          return (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M2.90754 9.99898C3.46115 10.6458 4.29541 11.5283 5.31496 12.3232C6.69125 13.3962 8.32012 14.2433 10 14.2433C11.6843 14.2433 13.3136 13.3964 14.6885 12.3241C15.7071 11.5297 16.5398 10.6477 17.0925 10.001C16.5388 9.35406 15.7044 8.47152 14.6848 7.67659C13.3086 6.60362 11.6798 5.75665 10 5.75665C8.31565 5.75665 6.68638 6.60359 5.31147 7.67588C4.29291 8.47026 3.46017 9.35222 2.90754 9.99898ZM4.34968 6.25469C5.85315 5.08214 7.82126 4 10 4C12.1748 4 14.1426 5.08279 15.6464 6.25523C17.1608 7.4359 18.2911 8.77504 18.8081 9.43527C19.0638 9.76175 19.064 10.2382 18.8086 10.565C18.2924 11.2255 17.1641 12.5647 15.6503 13.7453C14.1469 14.9178 12.1787 16 10 16C7.82507 16 5.85719 14.917 4.35335 13.7445C2.83894 12.5638 1.7087 11.2246 1.19192 10.5648C0.936238 10.2383 0.936001 9.76181 1.19136 9.43504C1.70759 8.77445 2.8359 7.43529 4.34968 6.25469Z"
                    fill={fill}/>
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M7.5453 10.1482C7.5453 8.69456 8.6457 7.51327 9.99984 7.51327C11.354 7.51327 12.4544 8.69456 12.4544 10.1482C12.4544 11.6019 11.354 12.7832 9.99984 12.7832C8.6457 12.7832 7.5453 11.6019 7.5453 10.1482ZM9.99984 9.26992C9.54944 9.26992 9.18166 9.66473 9.18166 10.1482C9.18166 10.6318 9.54944 11.0266 9.99984 11.0266C10.4502 11.0266 10.818 10.6318 10.818 10.1482C10.818 9.66473 10.4502 9.26992 9.99984 9.26992Z"
                      fill={fill}/>
              </svg>
            );
    }
}