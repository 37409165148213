import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { OfficialWrapper } from "./OfficialWrapper";
import ErrorNotificationBigIcon from "GUI_MAIN/icons/ErrorNotificationBigIcon";

const Row = styled.div`
  a{
    padding: 0 4px;
    color: #1D63B6;
  }

  a:first-child{
    font-family: ${props => props.theme.OpenSansSemiBold};
  }
`;

/**
 *
 */
const ErrorNotificationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 642px;
    height: fit-content;
    margin-top: 80px;
    padding: 80px 37px 40px 37px;
    text-align: center;
    color: #333333;
    line-height: 24px;
    background-color: ${props => props.theme.whiteColor};
`;

/**
 *
 */
const ErrorNotificationIcon = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${props => props.theme.OpenSansBold};
    font-size: ${props => props.theme.twentyFontSize};
    gap: 4px;
`;

const ErrorNotificationIconText = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0 30px 0;
`;

/**
 *
 */
const ErrorNotificationText = styled.div`
    font-family: ${props => props.theme.OpenSansRegular};
    font-weight: 400;
    font-size: ${props => props.theme.bigFontSize};
    color: #7B7D8A;
`;

/**
 * https://jira.mmdx.ru/browse/FCSPOK-834
 * Для покрытия требований указанных в ЧТЗ и прохождения ПМИ в рамках
 * Федерального проекта необходимо реализовать уведомление для аварийного режима работы системы.
 */
export class Maintenance extends React.Component {

    /**
     * необходимые свойства
     */
    static propTypes = {
        message: PropTypes.string.isRequired,
    }

    /**
     * отрисовка
     */
    render() {
        return (
            <OfficialWrapper>
                <ErrorNotificationWrapper>
                    <ErrorNotificationIcon>
                        <ErrorNotificationBigIcon/>
                        <ErrorNotificationIconText>
                            <Row>Система временно недоступна</Row>
                            <Row>Проводятся технические работы</Row>
                        </ErrorNotificationIconText>
                    </ErrorNotificationIcon>
                    <ErrorNotificationText>
                        { this.props.message }
                    </ErrorNotificationText>
                </ErrorNotificationWrapper>
            </OfficialWrapper>
        );
    }
}
