/*
...............WXOxdxOXW................
..............WNKOxddkXW................
...........WNNXK0kxxxk0KXNNWW...........
........WNXK0OOkkkkkkkkkOO0KXNW.........
......WWX0OOkkkkkkkkkkkkkkkOOKXNW.......
.....WNK0OkkkkkkkkkkkkkkkkkkkO0KNW......
.....NK0kkkkkkkkkkkkkkkkkkkkkkO0KNW.....
....WX0Okkkkkkkkkkkkkkkkkkkkkkkk0XW.....
...WNK0OkkkkkkkkkkkkkkkkkkkkkkkkOKW.....
...WNK0OkkkkkkkkkkkkkkkkkkkkkkkkOKW.....
...WNK0kkkkkkkkkkkkkkkkkkkkkkkkkOKW.....
...WNK0kkkkkkkkkkkkkkkkkkkkkkkkkOKN.....
...WNKOkkkkkkkkkkkkkkkkkkkkkkkkkOKNW....
...WX0OkkkkkkkkkkkkkkkkkkkkkkkkkO0XNW...
..WNKOkkkkkkkkkkkkkkkkkkkkkkkkkkkO0XW...
.WXKOkkkkkkkkkkkkkkkkkkkkkkkkkkkkkO0XNW.
XK0OkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkO0KNW
OOkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkOKX
OOOkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkO0K
XKKK0KK0KK0kxddddddddddddxxkO00K0000KKXN
WWWWWWWWWNXOdolllllllllllodkKNWWWWWWWWW.
..........WKkollcccccccclokKNW..........
...........NXOxoolllllodxOXW............
............WX0kdolllldk0NW.............
*/

import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class Bell extends PureComponent{
    static propTypes = {
        fill: PropTypes.string,
    }

    static defaultProps = {
        fill: '#ffffff',
    }

    render(){
        const {fill} = this.props;
        return(
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill={fill} d="M15.321 18.6674C15.321 20.5084 13.8287 22.0007 11.9877 22.0007C10.1468 22.0007 8.6543 20.5084 8.6543 18.6674C8.6543 16.8264 10.1468 15.334 11.9877 15.334C13.8287 15.334 15.321 16.8264 15.321 18.6674Z"/>
                <path fill={fill} d="M11.9877 5.33328C11.5277 5.33328 11.1543 4.96005 11.1543 4.5V2.83328C11.1543 2.37338 11.5277 2 11.9877 2C12.4476 2 12.821 2.37338 12.821 2.83328V4.5C12.821 4.96005 12.4476 5.33328 11.9877 5.33328Z"/>
                <path fill={fill} d="M19.8036 16.0926C18.5435 15.0276 17.821 13.4718 17.821 11.8226V9.4993C17.821 6.28259 15.2035 3.66602 11.9877 3.66602C8.77179 3.66602 6.1543 6.28259 6.1543 9.4993V11.8226C6.1543 13.4718 5.43179 15.0285 4.1644 16.1001C3.84015 16.3767 3.6543 16.7809 3.6543 17.2076C3.6543 18.0119 4.30844 18.666 5.11273 18.666H18.8627C19.6669 18.666 20.321 18.0119 20.321 17.2076C20.321 16.7809 20.1352 16.3767 19.8036 16.0926Z"/>
            </svg>

        )
    }
}

