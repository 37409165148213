import React from "react";
import moment from "moment";
import _get from "lodash/get";
import { observable } from "mobx";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Paths } from "GUI_TB/Routs/Paths";
import Table from "GUI_MAIN/Elements/Table";
import { getFormat } from "Locale/datetime";
import { translate } from "Locale/translate";
import Status from "GUI_MAIN/Elements/Status";
import { withRouter } from "react-router-dom";
import NoDataMessage from "GUI_MAIN/Elements/NoDataMessage";
import { ServiceRequest } from "MODEL_STORE/MIRAMEDIX/Models/ServiceRequest";
import { AppointmentSlot } from "MODEL_STORE/MIRAMEDIX/Models/AppointmentSlot";
import { PatientCondition } from "MODEL_STORE/MIRAMEDIX/Models/PatientCondition";
import { basicRequestPost } from "MODEL_STORE/DataSource/Requests/basicRequestPost";

/**
 *
 */
const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

/**
 *
 */
const Row = styled.div`
    display: flex;
    margin-bottom: ${props => props.marginBottom || "0"};
`;

/**
 *
 */
const Title = styled.div`
    font-size: ${props => props.theme.bigFontSize};
    font-family: ${props => props.theme.OpenSansSemiBold};
    color: ${props => props.theme.darkMainTextColor};
`;

/**
 *
 */
const ProtocolLink = styled.div`
    font-size: ${props => props.theme.mediumFontSize};
    font-family: ${props => props.theme.OpenSansRegular};
    a{
        color: ${props => props.theme.darkMainTextColor};

        &:hover{
        color: ${props => props.theme.mainDarkBlueColor};
        }
    }
`;

/**
 * ранее созданные заявки
 * https://jira.mmdx.ru/browse/FCSPOK-321
 */
@withRouter
@observer
export class PatientServiceRequests extends React.PureComponent {

    /**
     * необходимые свойства
     */
    static propTypes = {
        contextId: PropTypes.string.isRequired
    }

    /**
     * флаг поиска ранее созданных заявок
     */
    @observable isLoading = false;

    /**
     * флаг ошибки поиска ранее созданных заявок
     */
    @observable isFailure = false;

    /**
     * список заявок
     */
    @observable collection = [];

    /**
     * при монтировании компонента
     */
    componentDidMount() {
        this.isLoading = true;
        this.isFailure = false;
        basicRequestPost("/api/integrations/v2/servicerequest/search", {
            "context_id": this.props.contextId
        }).then(response => {
            console.log(response.data);
            this.collection = response.data;
        }).catch(error => {
            console.error(error);
            this.isFailure = true;
        }).finally(() => {
            this.isLoading = false;
        });
    }

    /**
     * шаблон таблицы
     */
    get tableTemplate() {
        return [
            {
                flex: "0 0 90px",
                justify: "flex-start",
                title: "Дата",
                field: "date"
            },
            {
                flex: "0 0 90px",
                justify: "flex-start",
                title: "Диагноз",
                field: "diagnosis"
            },
            {
                flex: "1 1",
                justify: "flex-start",
                title: "Место проведения",
                field: "location"
            },
            {
                flex: "0 0  220px",
                justify: "flex-start",
                title: "Статус",
                field: "status"
            },
            {
                flex: "0 0 220px",
                justify: "flex-start",
                title: "Протокол",
                field: "protocol",
            }
        ];
    }

    /**
     * рисуем статус заявки
     */
    statusCell = (requestStatus) => {
        return (
            <Status
                mainWidth={"195px"}
                notNeedMarginLeft={true}
                status={requestStatus.value}
                statusText={translate(requestStatus.code)}
            />
        )
    }

    /**
     * рисуем ячейку протокола
     */
    protocolCell = (protocol = null) => {
        if ( ! protocol ) {
            return "-";
        }
        const protocolNumber = _get(protocol, ["identifier", "value"]);
        const onClick = (event) => {
            event.preventDefault();
            event.stopPropagation();
            this.props.history.push(Paths.protocol.edit.path(protocol.id))
        }
        return (
            <ProtocolLink className={"ignoreEvents"}>
                <Link to={Paths.protocol.edit.path(protocol.id)} onClick={onClick}>
                    { moment(protocol.date).format(getFormat("DT000001")) }
                    { protocolNumber ? ` №${protocolNumber}` : "" }
                </Link>
            </ProtocolLink>
        )
    }

    /**
     * данные для таблицы
     */
    get tableData() {
        return this.collection.map(entry => {
            const slot = new AppointmentSlot(entry.slot);
            const request = new ServiceRequest(entry.request);
            const condition = new PatientCondition(entry.condition);
            return {
                "rowTemplate": {
                    "click": () => this.props.history.push(Paths.servicerequest.edit.path(request.id)),
                    "date": slot.start.date,
                    "diagnosis": condition.mkb10.code,
                    "location": entry.organization ? entry.organization.name : "",
                    "status": this.statusCell(request.status),
                    "protocol": this.protocolCell(entry.protocol)
                }
            }
        })
    }

    /**
     * отрисовка компонента
     */
    render() {
        if ( this.isLoading ) {
            return (
                <NoDataMessage
                    message={"Поиск ранее созданных заявок..."}
                />
            )
        }
        if ( this.isFailure ) {
            return (
                <NoDataMessage
                    type={"error"}
                    message={"При поиске ранее созданных заявок произошла ошибка"}
                />
            )
        }
        return (
            <Column>
                <Row marginBottom={"10px"}>
                    <Title>Выберите ранее созданную заявку</Title>
                </Row>
                <Row marginBottom={"40px"}>
                    <Table
                        headerPadding={"0"}
                        data={this.tableData}
                        headerBG={"lightGray"}
                        cellPadding={"8px 10px"}
                        heightBodyContent={"160px"}
                        heightTableContent={"160px"}
                        cellHeaderPadding={"8px 10px"}
                        tableTemplate={this.tableTemplate}
                    />
                </Row>
            </Column>
        );
    }
}
