import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class CircleCheckedIcon extends PureComponent {
    static propTypes = {
        fill: PropTypes.string,
    }
    static defaultProps = {
        fill: "#D3D4D8"
    }

    render() {
        const {fill} = this.props

        return (
            <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 3.5L3 5.5L7.5 1" stroke={fill} strokeWidth="2" strokeLinecap="round"/>
            </svg>

        );
    }
}

CircleCheckedIcon.propTypes = {};

export default CircleCheckedIcon;
